import { Field, LinkField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import PhoneField from 'components/helpers/Form/PhoneField';
import TextField from 'components/helpers/Form/TextField';
import { Formik, Field as FormikField, Form as FormikForm, FormikHelpers } from 'formik';
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { Tokens } from 'ordercloud-javascript-sdk';
import { useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { apiRequest } from 'src/utils/apiWrapper';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import apiConfig from 'src/utils/apiConfig';
import ComponentContext from 'lib/context/ComponentContext';
import { useOcSelector } from 'src/redux/ocStore';
import {
  ErrorCodes,
  FulfillmentType,
  currentPath,
  errorMessages,
  formErrorFlags,
  statusMessage,
} from 'components/helpers/Constants';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { RegistrationOrderConfirmationTV } from './RegistrationOrderConfirmationTV';
import { RegisterFormVariant } from './RegisterFormVariant';
import { formatPhoneForSaving } from 'lib/utils/string-utils';
import Loader from 'components/Loader/Loader';
import { useRouter } from 'next/router';
import { triggerSubmitEvent } from 'src/helpers/formHelper';
import { useRealPathName } from 'lib/utils/use-real-pathname';
export type RegisterFormProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          consentMessage: Field<string>;
          submitButtonText: Field<string>;
          successMessage: Field<string>;
          successRedirectUrl: {
            jsonValue: LinkField;
          };
          failureMessage: Field<string>;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
          errors: ErrorField;
        };
      };
    };
    isOrderConfirmation?: boolean;
  };

interface ErrorField {
  values: {
    name: string;
    value: string;
  }[];
}
export interface FieldValues {
  [key: string]: string;
}
interface radioList {
  name?: string;
  value?: string;
}
interface FormValuesTypes {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  Password: string;
  ConfirmPassword: string;
  ZipCode: string;
  PhoneTypeCheckbox: string;
}

const { formContainerCustom, formCustom, extraCustom } = RegistrationOrderConfirmationTV({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const {
  formContainer,
  form,
  fieldWrap,
  title,
  titleOrderConfirmation,
  contentTitleSection,
  formWrapper,
  submitBtn,
  shortDescription,
  disclaimer,
  position,
  extra,
  radioButtonWrapper,
  radioButtonsWrapper,
  radioField,
  radioIcon,
  successBase,
  loaderWrapper,
} = RegisterFormVariant({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const RegisterForm = ({ fields, params, isOrderConfirmation }: RegisterFormProps): JSX.Element => {
  // const isOrderConfirmation = params?.FieldNames == 'RegistrationOrderConfirmation';
  const isOcAuth = useOcSelector((state) => state?.ocAuth);
  useEffect(() => {
    if (
      !isOcAuth.isAnonymous &&
      !isOcAuth.loading &&
      router?.asPath?.toLowerCase() === currentPath?.isRegistration
    ) {
      router.push(currentPath?.isHome);
    }
  }, [isOcAuth.isAnonymous, isOcAuth.loading]);
  const initialValuesDefault: FormValuesTypes = {
    Email: '',
    FirstName: '',
    LastName: '',
    PhoneNumber: '',
    PhoneTypeCheckbox: 'Mobile Phone', // statically passing the default value over here.
    Password: '',
    ConfirmPassword: '',
    ZipCode: '',
  };
  const [initialValuesCustom, setInitialValuesCustom] = useState({});

  const transFormFields = transformData(fields.data?.data?.formFields);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showUserAlreadyExist, setShowUserAlreadyExist] = useState(false);
  const [showBlackListError, setShowBlackListError] = useState(false);
  const [showRed, setShowRed] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const customErrorMessage = fields?.data?.data?.errors?.values?.flatMap((error) => error);
  const matchPasswordErrorMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.passwordMatch
  );
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const blacklistedUserMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.isBlacklisted
  );
  const UserAlreadyExistMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.userAlreadyExist
  );

  // const setValues = (values: FieldValues) => {
  //   setFieldValues({
  //     ...values,
  //   });
  // };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies] = useCookies(['ordercloud.access-token']);
  const { themeNameUpper } = useTheme();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const pathName = useRealPathName();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const isRegistrationEnable = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.enableAccountCreation?.jsonValue?.value
  );

  const router = useRouter();

  const scrollToRegistrationTop = () => {
    // Need to add a slight delay and changed to top to support ios browsers
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1500);
  };

  useEffect(() => {
    if (componentContextData.ToggleLoginOverlayForm) {
      setcomponentContextData({ ...componentContextData, ToggleLoginOverlayForm: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const radioButtonData = fields.data?.data?.formFields?.targetItems?.find(
    (item: FormFieldsProps) => item?.id?.value === 'PhoneTypeCheckbox'
  )?.checkboxvalues?.values as radioList[] | undefined;

  // Function to handle the registration flow of a user in OC:
  const handleRegistration = async (
    values: FormValuesTypes
    // formikHelpers: FormikHelpers<FormValuesTypes>
  ) => {
    setLoading(true);
    let token;
    if (cookies['ordercloud.access-token']) {
      token = cookies['ordercloud.access-token'];
    } else {
      token = Tokens.GetAccessToken();
    }
    if (values && values?.Email) {
      // will need to send the below payload to an API endpoint here which will handle the flow.
      const registerUser = apiConfig?.authenticationAPI?.registerUserAPI;
      const headersData = {
        Authorization: token,
        site: themeNameUpper, // for now, only PSP would work, WNW integration is left from OC Marketplace
        requesturl: window.location.origin,
      };
      const postData = {
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        Username: values?.Email,
        Password: values?.Password,
        Email: values?.Email,
        Phone: formatPhoneForSaving(values?.PhoneNumber),
        Active: false,
        xp: {
          Verified: false,
          isMobile: true, // TODO: passing this statically as BED is working to add it.
          Zipcode: values?.ZipCode,
          CartUser: false, // default value should be false
        },
      };

      // Check router if came from checkout.
      const returnUrl = router?.query?.ReturnUrl || router?.query?.returnurl;
      if (returnUrl == '/checkout') {
        postData.xp.CartUser = true; // Identifies that user is registered via Checkout page.
      }

      if (values?.PhoneTypeCheckbox && postData.xp.isMobile) {
        postData.xp.isMobile = values?.PhoneTypeCheckbox == 'Mobile Phone' ? true : false;
      }

      const options = { method: 'POST', headers: headersData, data: postData };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await apiRequest(registerUser, options);
      // create a check for blacklist users.
      if (response && response === ErrorCodes?.BlacklistedUser) {
        setLoading(false);
        setShowBlackListError(true);
        scrollToRegistrationTop();
      }

      if (response && response.Errors) {
        setLoading(false);
        setShowError(true);
      }
      if (response === ErrorCodes?.Success) {
        triggerSubmitEvent(formRef, 'registration', {
          userDetails: values?.Email,
        });
        setSuccessResponse(true);
        setShowError(false);
        setLoading(false);
        // if (fields.data?.data?.successRedirectUrl?.jsonValue?.value?.href) {
        //   router.push(fields.data?.data?.successRedirectUrl?.jsonValue?.value?.href);
        // }

        // PSP-1459: OrderConfirmation success has RewardsBar and OrderMessage Block
        const orderConfirmationElement = document.querySelector(
          '.orderconfirmation'
        ) as HTMLElement | null;
        const orderConfirmationSummaryElement = document.querySelector(
          '.OrderConfirmationSummary'
        ) as HTMLElement | null;
        if (orderConfirmationElement) {
          orderConfirmationElement.remove();
        }
        if (orderConfirmationSummaryElement) {
          orderConfirmationSummaryElement.remove();
        }

        scrollToRegistrationTop();
      } else if (response === ErrorCodes?.UserAlreadyExist) {
        setShowUserAlreadyExist(true);
        scrollToRegistrationTop();
        setShowError(true);
        setLoading(false);
      } else {
        setLoading(false);
        setShowError(true);
      }
    }
  };
  const token = useAuthToken();

  // Function to check ifd a username exist or not in OC:
  const checkUsername = async (event: React.FocusEvent<HTMLInputElement>) => {
    // TODO: Add power scope token while checking userExists. Currently, no tokens are required.
    const emailInput = event?.target?.value;
    if (emailInput.length < 5) {
      setShowRed(false);
      // to avoid unnecessary targets
      return;
    }

    const doesUserExists = apiConfig?.authenticationAPI?.userExistAPI;
    const headersData = {
      site: themeNameUpper, // for now, only PSP would work, WNW integration is left from OC Marketplace
      requesturl: window.location.origin,
      Authorization: token,
    };
    const postData = {
      Email: emailInput,
    };
    const options = { method: 'POST', headers: headersData, data: postData };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await apiRequest(doesUserExists, options);
    if (response === ErrorCodes?.Fail) {
      setShowRed(false);
    } else if (response === ErrorCodes?.Success) {
      setShowRed(true);
    }
  };

  useEffect(() => {
    if (isOrderConfirmation && componentContextData?.order && componentContextData?.lineItem) {
      getUserDetailsFromOrder();
    }
  }, [isOrderConfirmation, componentContextData?.order, componentContextData?.lineItem]);

  const getUserDetailsFromOrder = () => {
    if (componentContextData?.order?.xp?.Fulfillment === FulfillmentType?.BOPIS) {
      if (componentContextData?.order?.xp?.PickupInfo) {
        setInitialValuesCustom({
          ...initialValuesCustom,
          FirstName: componentContextData?.order?.xp?.PickupInfo?.FirstName,
          LastName: componentContextData?.order?.xp?.PickupInfo?.LastName,
          Email: componentContextData?.order?.xp?.PickupInfo?.Email,
        });
      }
    } else {
      if (componentContextData?.lineItem?.[0]?.ShippingAddress) {
        setInitialValuesCustom({
          ...initialValuesCustom,
          FirstName: componentContextData?.lineItem[0]?.ShippingAddress?.FirstName,
          LastName: componentContextData?.lineItem[0]?.ShippingAddress?.LastName,
          Email: componentContextData?.lineItem[0]?.ShippingAddress?.xp?.Email,
        });
      }
    }
  };

  return isOrderConfirmation ? (
    !isOcAuth?.isAnonymous ? (
      <></>
    ) : (
      <div
        className={'RegistrationOrderConfirmation ' + params?.Styles}
        id="RegistrationOrderConfirmation"
      >
        {successResponse ? (
          <div className={successBase({ className: 'orderCofirmation successMessage' })}>
            <RichText className="rte" tag="p" field={fields?.data?.data?.successMessage} />
          </div>
        ) : (
          <>
            <div
              className={clsx(
                contentTitleSection(),
                pathName == currentPath?.isOrderConfirmation ? 'container lg:pl-0 lg:!pt-0' : ''
              )}
            >
              {fields.data?.data?.title?.value && (
                <Text
                  field={fields.data?.data?.title}
                  className={clsx(
                    titleOrderConfirmation(),
                    pathName == currentPath?.isOrderConfirmation
                      ? 'container lg:pt-0 !pl-0 lg:w-fit lg:text-left lg:ml-0'
                      : ''
                  )}
                  tag="p"
                />
              )}
              {fields.data?.data?.shortDescription?.value && (
                <p className={shortDescription()}>{fields.data?.data?.shortDescription?.value}</p>
              )}
            </div>
            <div
              className={formWrapper({
                isOrderConfirmationVariant: pathName == currentPath?.isOrderConfirmation,
                className: '!p-0',
              })}
            >
              <div className={formContainerCustom()}>
                <Formik
                  initialValues={initialValuesCustom}
                  enableReinitialize={true}
                  onSubmit={async (
                    values: FormValuesTypes,
                    { setSubmitting }: FormikHelpers<FormValuesTypes>
                  ) => {
                    await handleRegistration(values);

                    setSubmitting(false);
                  }}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {({ setFieldTouched, errors }: { setFieldTouched: any; errors: any }) => (
                    <FormikForm>
                      <div className={formCustom()}>
                        {showBlackListError && (
                          <div className="text-system-red">
                            {decodeURI(blacklistedUserMessage?.[0]?.value)}
                          </div>
                        )}
                        {showError && showUserAlreadyExist && (
                          <div className="text-system-red">
                            {decodeURI(UserAlreadyExistMessage?.[0]?.value)}
                          </div>
                        )}
                        {showError && !showUserAlreadyExist && !showBlackListError && (
                          <div className="text-system-red">
                            {fields?.data?.data?.failureMessage?.value}
                          </div>
                        )}
                        <div className={clsx(fieldWrap(), 'gap-x-[12px]')}>
                          <TextField {...transFormFields.FirstName} />
                          <TextField {...transFormFields.LastName} />
                        </div>
                        <div className={fieldWrap()}>
                          <TextField
                            {...transFormFields.Email}
                            preventAutofill={true}
                            onBlurFunction={(e: React.FocusEvent<HTMLInputElement>) => {
                              if (transFormFields.Email.id) {
                                setFieldTouched(transFormFields?.Email?.id as string, true);
                                setShowRed(false);
                                if (!errors?.Email) {
                                  checkUsername(e);
                                }
                              }
                            }}
                            showRed={showRed}
                            customErrorMessage={decodeURIComponent(
                              fields.data.data.errors.values.find(
                                (e) => e.name == formErrorFlags?.emailExists
                              )?.value ?? errorMessages?.emailExists
                            )}
                            hasCustomBlur={true}
                          />
                        </div>

                        {/* NO PHONE FIELD IS REQUIRED IN THIS VARIANT */}
                        <div className={clsx(fieldWrap(), 'gap-x-[12px]')}>
                          <div className={clsx(fieldWrap(), position())}>
                            <TextField
                              {...transFormFields.Password}
                              fieldtype={showPassword ? 'text' : 'password'}
                              togglePasswordVisibility={togglePasswordVisibility}
                              showPassword={showPassword}
                              // setFieldValue={setValues}
                              preventAutofill={true}
                              autoComplete="new-password"
                            />
                          </div>
                          <div className={clsx(fieldWrap(), position())}>
                            <TextField
                              {...transFormFields.ConfirmPassword}
                              fieldtype={showConfirmPassword ? 'text' : 'password'}
                              togglePasswordVisibility={toggleConfirmPasswordVisibility}
                              showPassword={showConfirmPassword}
                              preventAutofill={true}
                              customErrorMessage={decodeURI(matchPasswordErrorMessage?.[0]?.value)}
                              // matchValue={fieldValues && fieldValues['Password']}
                            />
                          </div>
                        </div>
                        {/* NO ZIPCODE FIELD IS REQUIRED IN THIS VARIANT */}
                      </div>
                      {fields?.data?.data?.consentMessage && (
                        <div className={clsx('gap-x-desk-space-between-base-vertical', extra())}>
                          <Text
                            tag="p"
                            className={disclaimer()}
                            field={fields?.data?.data?.consentMessage}
                          />
                        </div>
                      )}
                      <div className={clsx(extraCustom())}>
                        <button
                          aria-label="submit"
                          className={clsx(
                            submitBtn({
                              isDisabled: !(isRegistrationEnable === 'true'),
                            })
                          )}
                          disabled={!(isRegistrationEnable === 'true')}
                          type="submit"
                        >
                          {fields.data?.data?.submitButtonText?.value}
                        </button>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </div>
          </>
        )}
      </div>
    )
  ) : (
    <div className={'RegistrationForm ' + params?.Styles}>
      {successResponse ? (
        <div className={successBase()}>
          <RichText className="rte" tag="p" field={fields?.data?.data?.successMessage} />
        </div>
      ) : (
        <>
          <div className={contentTitleSection()}>
            {fields.data?.data?.title?.value && (
              <Text field={fields.data?.data?.title} className={title()} tag="p" />
            )}
            {fields.data?.data?.shortDescription?.value && (
              <p className={shortDescription()}>{fields.data?.data?.shortDescription?.value}</p>
            )}
            {showError && showUserAlreadyExist && (
              <div className="text-system-red font-bold">
                {decodeURI(UserAlreadyExistMessage?.[0]?.value)}
              </div>
            )}
          </div>
          <div className={formWrapper()}>
            <div className={formContainer()}>
              <Formik
                initialValues={initialValuesDefault}
                onSubmit={(
                  values: FormValuesTypes,
                  { setSubmitting }: FormikHelpers<FormValuesTypes>
                ) => {
                  handleRegistration(values);
                  setSubmitting(false);
                }}
              >
                {({ setFieldTouched, errors }) => (
                  <FormikForm autoComplete="off" role="presentation" ref={formRef}>
                    <div className={form()}>
                      {showBlackListError && (
                        <div className="text-system-red">
                          {decodeURI(blacklistedUserMessage?.[0]?.value)}
                        </div>
                      )}
                      {showError && !showUserAlreadyExist && !showBlackListError && (
                        <div className="text-system-red">
                          {fields?.data?.data?.failureMessage?.value}
                        </div>
                      )}
                      <div className={fieldWrap()}>
                        <TextField
                          {...transFormFields.Email}
                          onBlurFunction={(e: React.FocusEvent<HTMLInputElement>) => {
                            if (transFormFields.Email.id) {
                              setFieldTouched(transFormFields?.Email?.id as string, true);
                              setShowRed(false);
                              if (!errors.Email) {
                                checkUsername(e);
                              }
                            }
                          }}
                          showRed={showRed}
                          customErrorMessage={decodeURIComponent(
                            fields.data.data.errors.values.find(
                              (e) => e.name == formErrorFlags?.emailExists
                            )?.value ?? errorMessages?.emailExists
                          )}
                          hasCustomBlur={true}
                          autoComplete="on"
                          preventAutofill={true}
                        />
                      </div>
                      <div className={clsx(fieldWrap(), 'gap-x-[12px]')}>
                        <TextField {...transFormFields.FirstName} />
                        <TextField {...transFormFields.LastName} />
                      </div>

                      <div className={fieldWrap()}>
                        <PhoneField {...transFormFields.PhoneNumber} autoComplete={'no'} />
                      </div>
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className={radioButtonsWrapper()}
                      >
                        {radioButtonData?.map((radioButton: radioList, index: number) => {
                          return (
                            <label key={index} className={radioButtonWrapper()}>
                              <FormikField
                                type="radio"
                                class={radioField()}
                                name="PhoneTypeCheckbox"
                                value={radioButton?.name}
                              />
                              {radioButton?.name}
                              <div className={radioIcon()}></div>
                            </label>
                          );
                        })}
                      </div>
                      <div className={clsx(fieldWrap(), position())}>
                        <TextField
                          {...transFormFields.Password}
                          fieldtype={showPassword ? 'text' : 'password'}
                          togglePasswordVisibility={togglePasswordVisibility}
                          showPassword={showPassword}
                          autoComplete="new-password"
                          preventAutofill={true}
                        />
                      </div>
                      <div className={clsx(fieldWrap(), position())}>
                        <TextField
                          {...transFormFields.ConfirmPassword}
                          fieldtype={showConfirmPassword ? 'text' : 'password'}
                          togglePasswordVisibility={toggleConfirmPasswordVisibility}
                          showPassword={showConfirmPassword}
                          preventAutofill={true}
                          customErrorMessage={decodeURI(matchPasswordErrorMessage?.[0]?.value)}
                        />
                      </div>
                      <div className={fieldWrap()}>
                        <TextField {...transFormFields.ZipCode} />
                      </div>
                    </div>
                    <div className={clsx('gap-x-desk-space-between-base-vertical', extra())}>
                      <Text
                        tag="p"
                        className={disclaimer()}
                        field={fields?.data?.data?.consentMessage}
                      />
                    </div>
                    <div className={extra()}>
                      {loading ? (
                        <div className={loaderWrapper()}>
                          <Loader />
                          <p>{statusMessage?.registeringAccount}</p>
                        </div>
                      ) : (
                        <button
                          aria-label="submit"
                          className={submitBtn({
                            isDisabled: !(isRegistrationEnable === 'true'),
                          })}
                          type="submit"
                          disabled={!(isRegistrationEnable === 'true')}
                        >
                          {fields.data?.data?.submitButtonText?.value}
                        </button>
                      )}
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const Default = (props: RegisterFormProps): JSX.Element => {
  return <RegisterForm {...props} />;
};

export const RegistrationOrderConfirmation = (props: RegisterFormProps): JSX.Element => {
  return <RegisterForm {...props} isOrderConfirmation={true} />;
};

// export default RegisterForm;
