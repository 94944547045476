// Global
import { Field as FormikField, Formik, Form as FormikForm, FormikHelpers } from 'formik';
// Utils and Form Helper
import TextAreaField from 'components/helpers/Form/TextAreaField';
import TextField from 'components/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
// Tailwind Import
import deliveryAddress from '../DeliveryAddress/DeliveryAddressTailwindVariant';
import deliveryFrequency from '../DeliveryFrequency/DeliveryFrequencyTailwindVariant';
import billingAddress from '../BillingAddress/BillingTailwindVariant';
import CheckboxField from 'components/helpers/Form/CheckboxField';
import IconHelper from 'components/helpers/IconHelper';
// import { ComponentProps } from '@sitecore-feaas/clientside';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
// import { saveShippingAddress } from 'src/redux/ocCurrentOrder';
import { useState, useEffect, useRef } from 'react';
import { Address, BuyerAddress, Me } from 'ordercloud-javascript-sdk';
import PhoneField, { formatPhoneForDisplay } from 'components/helpers/Form/PhoneField';
import { FulfillmentType, addressType, DiscreteLineItem } from 'components/helpers/Constants';
import { useCheckoutFormContext } from '../../../lib/context/CheckoutFormContext';
import RichTextHelper from 'components/helpers/RichTextHelper';
import clsx from 'clsx';
import pickUpAddress from '../PickupAddress/TailwindVariant';
import LinkHelper from 'components/helpers/LinkHelper';
import useOcCart from 'src/hooks/useOcCart';
import { updateUser } from 'src/redux/ocUser';
import { saveShippingAddress } from 'src/redux/ocCurrentOrder';
import { saveAddress } from 'src/redux/ocAddressBook';
import Loader from 'components/Loader/Loader';
import useDictionary from 'src/hooks/useDictionary';
import { formatPhoneForSaving } from 'lib/utils/string-utils';
import { MeUserWithXp } from 'src/redux/xp';

export type DeliveryAddressProps = {
  fields: {
    data: {
      data: {
        title: Field<string>;
        shortDescription: Field<string>;
        submitButtonText: Field<string>;
        successMessage: Field<string>;
        successRedirectUrl?: { jsonValue: LinkField };
        failureMessage?: Field<string>;
        consentMessage?: Field<string>;
        errors: ErrorField;
        formFields: {
          targetItems: Array<FormFieldsProps>;
        };
      };
      content: {
        editLabel?: Field<string>;
        deliveryInfo: Field<string>;
        delivertoLabel?: Field<string>;
        instructionLabel?: Field<string>;
        deliveryInfoTitle?: Field<string>;
      };
    };
  };
};
interface ErrorField {
  values: {
    name: string;
    value: string;
  }[];
}

interface FormValuesTypes {
  ID?: string;
  Email?: string;
  Message?: string;
  PhoneNumber?: string;
  PhoneTypeCheckbox?: string;
  FirstName?: string;
  LastName?: string;
  DeliveryInstruction?: string;
  DeliveryTo?: string;
  SaveToProfile?: string;
}
const DeliveryAddress = ({ fields }: DeliveryAddressProps): JSX.Element => {
  // Tailwind Variant
  const {
    base1,
    deliveryAddressWrapper,
    informationTitle,
    form,
    radioIcon,
    pickupInformationContainer,
    radioButtonsWrapper,
    radioButtonWrapper,
    radioField,
    validFieldInfo,
    info,
    inlineFieldWrapper,
    radioAsCheckboxWrapper,
    labels,
    inlineFields,
    submitBtn,
    loaderWrapper,
    fieldWrapper,
    titleWrapper,
    title,
  } = deliveryAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { blockTitle } = billingAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const {
    addressBlockHeadline,
    addressInfo,
    personalInfo,
    blockWithMargin,
    addressBlock,
    checkmarkIcon,
    field,
  } = pickUpAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const ocCurrentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();
  const shippingAddress = productlineitems?.[0]?.ShippingAddress;

  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const formRef = useRef<HTMLDivElement>(null);

  const dispatch = useOcDispatch();
  const {
    checkoutContextData,
    updateCheckoutContextData,
    expandedStep,
    deliveryAddressForm,
    activateStep,
    goToNextStep,
  } = useCheckoutFormContext();
  const collapse = expandedStep !== 'delivery';

  const [loading, setLoading] = useState<boolean>(false);

  const [deliverToMe, setDeliverToMe] = useState<boolean>(shippingAddress?.xp?.SelfPickup || true);
  const [formValues, setFormValues] = useState<FormValuesTypes>();

  const initialDataValues = useInitialValues(deliverToMe);

  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);

  // Get form-fields
  const transFormFields = transformData(fields.data?.data?.formFields);
  // Radio for pickup information
  const radioDeliveryTo = fields.data?.data?.formFields?.targetItems?.find(
    (item) => item?.id?.value === 'DeliveryTo'
  )?.checkboxvalues?.values;
  const radioPhoneType = fields.data?.data?.formFields?.targetItems?.find(
    (item) => item?.id?.value === 'PhoneTypeCheckbox'
  )?.checkboxvalues?.values;
  const content = fields.data?.content;
  //set initial componentContextDataValues
  useEffect(() => {
    updateCheckoutContextData({
      warningDeliveryProfileUpdate: undefined,
    });
  }, [checkoutContextData?.setDeliverySubmitting]);

  const { getDictionaryValue } = useDictionary();

  /**
   * Logic to automatically save the Delivery form info if user is authenticated
   */
  useEffect(() => {
    if (
      !isAnonymous &&
      (ocCurrentOrder?.lineItems?.length ?? 0) > 0 &&
      !loading &&
      ocCurrentOrder.order?.xp?.Fulfillment === 'DFS'
    ) {
      const shippingAddress = ocCurrentOrder?.lineItems?.find(
        (x) => ![DiscreteLineItem.TIP].includes(x.ProductID)
      )?.ShippingAddress;

      if (shippingAddress && shippingAddress?.Street1 && shippingAddress?.City) {
        if (!shippingAddress?.FirstName && !shippingAddress?.xp?.Email && !shippingAddress?.Phone) {
          // ---- Extract data from user Details and save to shippingAddress. ---
          submitForm(
            {
              ...initialDataValues,
              FirstName: userDetails?.FirstName,
              LastName: userDetails?.LastName,
              PhoneNumber: formatPhoneForDisplay(userDetails?.Phone),
              Email: userDetails?.Email,
            },
            false
          );
        }
      }
    }
  }, [
    isAnonymous,
    loading,
    ocCurrentOrder.order?.xp?.Fulfillment,
    ocCurrentOrder?.lineItems?.length,
  ]);

  //for saving pickupaddress in home
  const userDeliveryAddress = {
    ID: userDetails?.ID,
    Phone: userDetails?.Phone,
    xp: {
      DeliveryInstruction: userDetails?.xp?.DeliveryInstruction,
      PickupInstruction: userDetails?.xp?.PickupInstruction,
    },
  };

  // save to profile function:
  const saveUserProfile = async (values?: FormValuesTypes) => {
    setLoading(true);
    const request: MeUserWithXp = {
      ID: userDeliveryAddress?.ID as string,
      ...(values?.PhoneTypeCheckbox === 'true' && {
        Phone: formatPhoneForSaving(values?.PhoneNumber),
      }),
      xp: {
        DeliveryInstruction: values?.DeliveryInstruction as string,
        ...(values?.PhoneTypeCheckbox !== 'true' && {
          HomePhone: formatPhoneForSaving(values?.PhoneNumber),
        }),
      },
    };
    request && (await dispatch(updateUser(request)));
    setLoading(false);
  };

  const handleDeliveryOption = (
    event: React.MouseEvent,
    setValues: FormikHelpers<FormValuesTypes>['setValues']
  ) => {
    if ((event.target as HTMLInputElement)?.value == 'true') {
      // deliverToMe = false
      setDeliverToMe(true);
      if (!shippingAddress) {
        // no shippingAddress is saved prior to this.
        if (isAnonymous) {
          setValues({
            FirstName: '',
            LastName: '',
            Email: '',
            PhoneNumber: '',
            PhoneTypeCheckbox: 'true',
            DeliveryTo: 'true',
            DeliveryInstruction: '',
          });
        } else {
          // has some logged in user with details:
          setValues({
            FirstName: userDetails?.FirstName || '',
            LastName: userDetails?.LastName || '',
            Email: userDetails?.Email || '',
            PhoneNumber: formatPhoneForDisplay(userDetails?.Phone),
            PhoneTypeCheckbox: userDetails?.xp?.IsMobile?.toString() ?? 'true',
            DeliveryTo: 'true',
            DeliveryInstruction:
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (shippingAddress as any)?.xp?.DeliveryInstruction ??
              userDetails?.xp?.DeliveryInstruction,
          });
        }
      } else {
        if (shippingAddress?.xp?.SelfPickup) {
          setValues({
            FirstName: isAnonymous ? shippingAddress?.FirstName : userDetails?.FirstName || '',
            LastName: isAnonymous ? shippingAddress?.LastName : userDetails?.LastName || '',
            Email:
              (shippingAddress?.xp?.Email ||
                (!isAnonymous && userDetails?.Email ? userDetails?.Email : '')) ??
              '',
            PhoneNumber:
              formatPhoneForDisplay(shippingAddress?.Phone) ||
              (!isAnonymous && userDetails?.Phone ? formatPhoneForDisplay(userDetails?.Phone) : ''),
            PhoneTypeCheckbox: shippingAddress?.xp?.IsMobile.toString()
              ? shippingAddress?.xp?.IsMobile?.toString()
              : !isAnonymous && userDetails?.xp?.IsMobile
              ? userDetails?.xp?.IsMobile?.toString()
              : 'true',
            // by default, it should be Deliver to me selected
            DeliveryTo: !shippingAddress
              ? 'true'
              : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
            DeliveryInstruction: isAnonymous
              ? shippingAddress?.xp?.DeliveryInstruction
              : userDetails?.xp?.DeliveryInstruction || '',
          });
        } else {
          setValues({
            FirstName: '',
            LastName: '',
            Email:
              ((isAnonymous && '') ||
                (!isAnonymous && userDetails?.Email ? userDetails?.Email : '')) ??
              '',
            PhoneNumber:
              ((isAnonymous && '') ||
                (!isAnonymous && userDetails?.Phone
                  ? formatPhoneForDisplay(userDetails?.Phone as string)
                  : '')) ??
              '',
            PhoneTypeCheckbox: isAnonymous ? 'true' : userDetails?.xp?.IsMobile?.toString(),
            // by default, it should be Deliver to me selected
            DeliveryTo: !shippingAddress
              ? 'true'
              : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
            DeliveryInstruction: '',
          });
        }
      }
    } else {
      setDeliverToMe(false);
      if (shippingAddress?.xp?.SelfPickup) {
        // the values should be null
        setValues({
          FirstName: '',
          LastName: '',
          Email: '',
          PhoneNumber: '',
          PhoneTypeCheckbox: 'true',
          // by default, it should be Deliver to me selected
          DeliveryTo: !shippingAddress
            ? 'true'
            : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
          DeliveryInstruction: '',
        });
      } else {
        setValues({
          FirstName:
            shippingAddress?.FirstName === userDetails?.FirstName
              ? ' '
              : shippingAddress?.FirstName,
          LastName:
            shippingAddress?.LastName === userDetails?.LastName ? ' ' : shippingAddress?.LastName,
          Email: shippingAddress?.xp?.Email ?? '',
          PhoneNumber: formatPhoneForDisplay(shippingAddress?.Phone),
          PhoneTypeCheckbox: shippingAddress?.xp?.IsMobile?.toString() ?? 'true',
          // by default, it should be Deliver to me selected
          DeliveryTo: !shippingAddress
            ? 'true'
            : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
          DeliveryInstruction: shippingAddress?.xp?.DeliveryInstruction ?? '',
        });
      }
    }
  };

  const { linkWithUnderline } = deliveryFrequency({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // Function call to submit/save the delivery address[ShippingAddress] to the currentOrder.
  const submitForm = async (values: FormValuesTypes | undefined, fromUserAction: boolean) => {
    setLoading(true);
    try {
      if (values?.SaveToProfile && !isAnonymous) {
        const request: BuyerAddress = {
          AddressName: addressType?.delivery,
          Shipping: true,
          FirstName: shippingAddress?.FirstName,
          LastName: shippingAddress?.LastName,
          Street1: shippingAddress?.Street1 ?? '',
          Street2: shippingAddress?.Street2,
          Phone: formatPhoneForSaving(shippingAddress?.Phone),
          City: shippingAddress?.City ?? '',
          State: shippingAddress?.State ?? '',
          Zip: shippingAddress?.Zip ?? '',
          Country: shippingAddress?.Country ?? '',
        };
        const listAdd = await Me.ListAddresses({ sortBy: ['DateCreated'] });
        const add = listAdd?.Items?.filter(
          (item) => item?.AddressName === addressType?.delivery
        )[0];
        if (add) {
          await dispatch(saveAddress({ ...request, ID: add?.ID }));
        } else {
          await dispatch(saveAddress({ ...request }));
        }
      }
      const ocShippingAddressData: Address = {
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        Phone: formatPhoneForSaving(values?.PhoneNumber),
        Street1: shippingAddress?.Street1 ?? '',
        Street2: shippingAddress?.Street2,
        City: shippingAddress?.City ?? '',
        State: shippingAddress?.State ?? '',
        Zip: shippingAddress?.Zip ?? '',
        Country: shippingAddress?.Country ?? '',
        xp: {
          IsMobile: values?.PhoneTypeCheckbox == 'false' ? false : true,
          Email: values?.Email ?? '',
          SelfPickup: values?.DeliveryTo == 'false' ? false : true,
          DeliveryInstruction: values?.DeliveryInstruction,
        },
      };
      if (deliverToMe && !isAnonymous) {
        // fetch names from user's details:
        ocShippingAddressData.FirstName = userDetails?.FirstName;
        ocShippingAddressData.LastName = userDetails?.LastName;
      }

      await dispatch(saveShippingAddress(ocShippingAddressData));

      updateCheckoutContextData({ setDeliverySubmitting: undefined });

      if (fromUserAction) {
        goToNextStep('delivery');
      }
      setLoading(false);
    } catch (error) {
      console.error('submitDeliveryAddress: ', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkoutContextData?.setDeliverySubmitting !== undefined) {
      if (checkoutContextData?.setDeliverySubmitting === true) {
        saveUserProfile(formValues);
        submitForm(formValues, true);
      } else if (checkoutContextData?.setDeliverySubmitting === false) {
        submitForm(formValues, true);
      }
    }
  }, [checkoutContextData?.setDeliverySubmitting]);

  // Function call to submit/save the delivery address[ShippingAddress] to the currentOrder.
  const submitDeliveryAddress = async (values: FormValuesTypes) => {
    // updateCheckoutContextData({ ...checkoutContextData });
    setFormValues(values);
    if (values?.SaveToProfile && !isAnonymous) {
      if (
        formatPhoneForSaving(userDeliveryAddress?.Phone) !==
          formatPhoneForSaving(values?.PhoneNumber) ||
        (userDeliveryAddress?.xp?.DeliveryInstruction &&
          userDeliveryAddress?.xp?.DeliveryInstruction?.trim() !==
            values?.DeliveryInstruction?.trim())
      ) {
        updateCheckoutContextData({
          warningDeliveryProfileUpdate: true,
          isDelivery: true,
        });
      } else {
        saveUserProfile(values);
        submitForm(values, true);
      }
    } else {
      submitForm(values, true);
    }
  };

  if (
    !myStoreData ||
    Object.keys(myStoreData).length === 0 ||
    !cart ||
    cart.xp?.Fulfillment === FulfillmentType.BOPIS
  ) {
    return (
      <div id="deliveryaddress" ref={formRef} className="deliveryaddress" hidden>
        {cart?.xp?.Fulfillment}
      </div>
    );
  }

  const phoneNumber = shippingAddress
    ? shippingAddress?.Phone
    : formatPhoneForDisplay(userDetails?.Phone);
  return (
    <>
      <div id="deliveryaddress" className={'deliveryaddress ' + base1({ active: !collapse })}>
        <div className={titleWrapper()}>
          <div className={title({ active: collapse })}>2. {fields?.data?.data?.title?.value}</div>
          {collapse && (
            <div className={linkWithUnderline()} onClick={() => activateStep('delivery')}>
              {content?.editLabel?.value ?? 'Edit'}
            </div>
          )}
        </div>
        <div>
          <div
            className={clsx(
              deliveryAddressWrapper(),
              !collapse ? 'lg:pl-desk-margin-base-right' : ''
            )}
          >
            <div className={blockWithMargin()}>
              {!collapse && (
                <div className="flex flex-col">
                  <p className={blockTitle()}>{content?.delivertoLabel?.value ?? 'Deliver to:'}</p>
                  <span>
                    {shippingAddress
                      ? `${
                          shippingAddress?.FirstName ?? (!isAnonymous ? userDetails?.FirstName : '')
                        } ${
                          shippingAddress?.LastName ?? (!isAnonymous ? userDetails?.LastName : '')
                        }`
                      : `${userDetails?.FirstName} ${userDetails?.LastName}`}
                  </span>
                  <span>
                    {shippingAddress?.Street1} {shippingAddress?.Street2}
                  </span>
                  <span>
                    {shippingAddress?.City}, {shippingAddress?.State} {shippingAddress?.Zip}
                  </span>
                </div>
              )}
              {collapse && (
                <div className="flex flex-col">
                  <div className={personalInfo()}>
                    <div className={blockWithMargin()}>
                      <div className={clsx(addressBlockHeadline(), addressBlock())}>
                        {' '}
                        {content?.delivertoLabel?.value ?? 'Deliver to:'}
                      </div>
                      <div className={addressBlock()}>
                        <span className="block">
                          {shippingAddress
                            ? `${
                                shippingAddress?.FirstName ??
                                (!isAnonymous ? userDetails?.FirstName : '')
                              } ${
                                shippingAddress?.LastName ??
                                (!isAnonymous ? userDetails?.LastName : '')
                              }`
                            : `${userDetails?.FirstName} ${userDetails?.LastName}`}
                        </span>
                        <span className="block">
                          {shippingAddress?.Street1} {shippingAddress?.Street2}
                        </span>
                        <span className="block">
                          {shippingAddress?.City} {shippingAddress?.State} {shippingAddress?.Zip}
                        </span>
                      </div>
                    </div>
                    <div className={blockWithMargin()}>
                      {phoneNumber && (
                        <LinkHelper
                          className={linkWithUnderline()}
                          field={{
                            value: {
                              href: `tel:${phoneNumber}`,
                              text: `${formatPhoneForDisplay(phoneNumber)}`,
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={clsx(addressInfo(), blockWithMargin())}>
              <RichTextHelper
                className={addressInfo({ className: 'break-words' })}
                field={content?.deliveryInfo}
              />
            </div>
          </div>
          <div className={clsx(personalInfo(), !collapse ? 'lg:pl-desk-margin-base-right' : '')}>
            {shippingAddress?.xp?.DeliveryInstruction && (
              <div className="block">
                <div className={addressBlock()}>
                  {content?.instructionLabel?.value ?? 'Special instructions'}
                </div>
                <div className="break-words">{shippingAddress?.xp?.DeliveryInstruction}</div>
              </div>
            )}
          </div>
        </div>

        {!collapse && (
          <Formik
            initialValues={initialDataValues}
            innerRef={deliveryAddressForm}
            onSubmit={(values: FormValuesTypes) => {
              submitDeliveryAddress(values);
            }}
          >
            {({ setValues }) => (
              <FormikForm className={pickupInformationContainer()}>
                <div className={radioButtonsWrapper()}>
                  <div className={informationTitle()}>{content?.deliveryInfoTitle?.value}</div>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className={radioButtonsWrapper()}
                  >
                    {radioDeliveryTo?.map((radioButton, index: number) => {
                      return (
                        <label key={index} className={radioButtonWrapper()}>
                          <FormikField
                            type="radio"
                            className={radioField()}
                            name="DeliveryTo"
                            value={radioButton?.value}
                            onClick={(event: React.MouseEvent) =>
                              handleDeliveryOption(event, setValues)
                            }
                          />
                          {radioButton?.name}
                          <div className={radioIcon()}></div>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className={pickupInformationContainer()}>
                  <div className={form()}>
                    {/* Fields marked with an asterisk * .... */}
                    {fields?.data?.data?.shortDescription?.value && (
                      <div className={fieldWrapper()}>
                        <div className={validFieldInfo()}>
                          {fields?.data?.data?.shortDescription?.value}
                        </div>
                      </div>
                    )}
                    {/* First Name and Last Name */}
                    {isAnonymous && (
                      <div className={inlineFields()}>
                        {transFormFields.FirstName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.FirstName} />
                          </div>
                        )}
                        {transFormFields.LastName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.LastName} />
                          </div>
                        )}
                      </div>
                    )}
                    {!isAnonymous && !deliverToMe && (
                      <div className={inlineFields()}>
                        {transFormFields.FirstName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.FirstName} />
                          </div>
                        )}
                        {transFormFields.LastName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.LastName} />
                          </div>
                        )}
                      </div>
                    )}
                    {/* Email Field */}
                    {transFormFields.Email && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.Email} />
                      </div>
                    )}
                    {/* Telephone - Mobile Phone & Home Phone */}
                    {transFormFields.PhoneNumber && (
                      <div className={inlineFieldWrapper()}>
                        <div className="inlineWrapper">
                          <PhoneField {...transFormFields.PhoneNumber} />
                        </div>
                        <div className={radioAsCheckboxWrapper()}>
                          {radioPhoneType?.map((element, index: number) => {
                            return (
                              // <h2 key={index}>hello</h2>
                              <label className={labels()} key={index}>
                                <FormikField
                                  type="radio"
                                  className={field({ className: 'rounded-full' })}
                                  name="PhoneTypeCheckbox"
                                  value={element?.value}
                                />
                                {element.name}
                                <IconHelper className={checkmarkIcon()} icon={'icon-checkmark'} />
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {/* Special Information TextBlock */}
                    <div className={fieldWrapper()}>
                      {transFormFields.DeliveryInstruction && (
                        <TextAreaField {...transFormFields.DeliveryInstruction} />
                      )}
                      {fields?.data?.data?.consentMessage && (
                        <span className={info()}>{fields?.data?.data?.consentMessage?.value}</span>
                      )}
                    </div>
                    {/* Save to Profile */}
                    {!isAnonymous && transFormFields.SaveToProfile && (
                      <div className={fieldWrapper()}>
                        {<CheckboxField {...transFormFields.SaveToProfile} singleCheckbox={true} />}
                      </div>
                    )}
                    <div className={fieldWrapper()}>
                      {loading ? (
                        <div className={loaderWrapper()}>
                          <Loader />
                          {getDictionaryValue('ScheduleAppointmentsStep2SelectDogInProgressText')}
                        </div>
                      ) : (
                        <>
                          {fields.data?.data?.submitButtonText?.value && (
                            <button aria-label="submit" className={submitBtn()} type="submit">
                              {fields.data?.data?.submitButtonText?.value}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};
export default DeliveryAddress;

function useInitialValues(deliverToMe: boolean): FormValuesTypes {
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);

  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();
  const shippingAddress = productlineitems.find((x) => x.ShipFromAddress)?.ShippingAddress;
  const userDetails = useOcSelector((state) => state?.ocUser?.user);

  if (isAnonymous) {
    return {
      FirstName: shippingAddress?.FirstName ?? '',
      LastName: shippingAddress?.LastName ?? '',
      Email: shippingAddress?.xp?.Email ?? '',
      PhoneNumber: shippingAddress?.Phone ?? '',
      PhoneTypeCheckbox: shippingAddress?.xp?.IsMobile.toString()
        ? shippingAddress?.xp?.IsMobile?.toString()
        : 'true',
      DeliveryTo: !shippingAddress ? 'true' : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
      DeliveryInstruction: shippingAddress?.xp?.DeliveryInstruction ?? '',
      SaveToProfile: 'false',
    };
  } else {
    return {
      FirstName: deliverToMe
        ? (userDetails?.FirstName || '').trim()
        : shippingAddress?.FirstName === userDetails?.FirstName
        ? ' '
        : shippingAddress?.FirstName,
      LastName: deliverToMe
        ? (userDetails?.LastName || '').trim()
        : shippingAddress?.LastName === userDetails?.LastName
        ? ' '
        : shippingAddress?.LastName,
      Email: (shippingAddress?.xp?.Email || userDetails?.Email) ?? '',
      PhoneNumber:
        formatPhoneForDisplay(shippingAddress?.Phone) || formatPhoneForDisplay(userDetails?.Phone),
      PhoneTypeCheckbox:
        shippingAddress?.xp?.IsMobile?.toString() ||
        userDetails?.xp?.IsMobile?.toString() ||
        'true',
      // by default, it should be Deliver to me selected
      DeliveryTo: !shippingAddress ? 'true' : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
      DeliveryInstruction: deliverToMe
        ? shippingAddress?.xp?.DeliveryInstruction || userDetails?.xp?.DeliveryInstruction?.trim()
        : shippingAddress?.xp?.DeliveryInstruction === userDetails?.xp?.DeliveryInstruction
        ? ' '
        : shippingAddress?.xp?.DeliveryInstruction,
      SaveToProfile: 'true',
    };
  }
}
