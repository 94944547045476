import { Field, Text, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React, { useContext, useState } from 'react';
import { useTheme } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';
import {
  // Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikValues,
  FormikHelpers,
} from 'formik';
import TextField from 'components/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import clsx from 'clsx';
import ComponentContext from 'lib/context/ComponentContext';
import ModalWrapper from 'components/helpers/ModalWrapper';
import apiConfig from 'src/utils/apiConfig';
import { apiRequest } from 'src/utils/apiWrapper';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { ErrorCodes } from 'components/helpers/Constants';
export type ForgotPasswordFormProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          submitButtonText?: Field<string>;
          cancelButtonText?: Field<string>;
          closeButtonText?: Field<string>;
          successMessage?: Field<string>;
          failureMessage: Field<string>;
          successRedirectUrl: LinkFieldValue;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
          errors: ErrorField;
        };
      };
    };
  };
interface ErrorField {
  values: {
    name: string;
    value: string;
  }[];
}
interface FormValuesTypes {
  Email: string;
}
const ForgotPasswordForm = ({ fields, params }: ForgotPasswordFormProps): JSX.Element => {
  const { themeName, themeNameUpper } = useTheme();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const token = useAuthToken();
  const loginFormStylings = tv(
    {
      slots: {
        fieldWrap: ['flex', 'justify-between', 'w-full'],
        base: 'mx-auto flex flex-col items-center text-color-text-white center gap-desk-margin-base-bottom items-start relative',
        formWrapper: 'flex flex-col items-start self-stretch bg-color-background-light',
        form: 'flex flex-col items-start text-color-text-black  flex flex-col items-start',
        fieldsWrapper: 'flex flex-col items-start  gap-desk-space-between-base-vertical',
        halfFieldWrapper: 'flex items-start gap-desk-space-between-base-vertical',
        field: 'flex flex-col  items-start self-stretch gap-desk-margin-micro-bottom',
        halfField: 'flex flex-col w-1/2  items-start self-stretch gap-desk-margin-micro-bottom',
        label: 'font-body-large-bold',
        disclaimer: '',
        button:
          'flex content-center items-center rounded-desk-global-radius-small  text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold',
        passwordWrapper: 'flex flex-row w-full justify-between items-center ',
        showPasswordWrap:
          ' w-fit align-right font-primary text-heading-desk-small-regular font-heading-desk-small-regular leading-heading-desk-small-regular border-2 border-solid border-color-border-mid border-l-0 h-[43px] px-desk-global-spacing-spacing-4 flex items-center bg-color-scale-1-white cursor-pointer',
        removeBorderRight: 'border-r-0',
        mobileFieldWrapper: 'flex gap-desk-space-between-base-vertical',
        mobileField:
          'custom-checkbox [&>input]:h-[24px] [&>input]:w-[24px] [&>input]:bg-color-brand-primary-1-base flex gap-desk-space-between-tight-horizontal [&>input:checked~span.checkmark]:bg-color-brand-primary-1-base',
        checkboxField:
          'custom-checkbox [&>input]:h-[24px] [&>input]:w-[24px] [&>input]:bg-color-brand-primary-1-base flex gap-desk-space-between-tight-horizontal [&>input:checked~span.checkmark]:bg-color-brand-primary-1-base font-body-large-bold leading-body-large-bold text-body-large-bold',
        createBlock: 'flex flex-col items-start gap-desk-margin-tight-bottom self-stretch',
        createTitle: '',
        createDesc: 'font-body-large-regular',
        ctaLink:
          'text-color-brand-primary-1-base font-body-large-bold_underline text-body-large-bold_underline leading-body-large-bold_underline underline',
        loggedDesc: 'font-body-small-bold text-body-small-bold leading-body-small-bold',
        position: 'relative',
        input:
          'flex flex-col items-start self-stretch font-body-large-regular h-[43px] gap-[8px] py-desk-global-spacing-spacing-0 px-desk-global-spacing-spacing-4 text-color-text-black border-2 border-solid border-color-border-mid bg-color-scale-1-white w-full ',
        formTitle: 'text-color-text-black',
        formDescription: 'text-color-text-black',
        formTitleBlock: ['flex flex-col'],
      },
      compoundSlots: [
        {
          slots: ['createTitle', 'createDesc'],
          class: ['self-stretch', 'font-primary', 'text-color-text-black'],
        },
        {
          slots: ['form', 'fieldsWrapper', 'halfFieldWrapper', 'disclaimer'],
          class: ['w-full', 'self-stretch'],
        },
        {
          slots: ['input', 'ctaLink', 'button'],
          class: ['outline-none', 'focus:border-color-brand-primary-1-base', 'appearance-none '],
        },
        {
          slots: ['input'],
          class: [
            'focus:bg-color-scale-1-white',
            'focus:border-3',
            'focus:border-color-border-mid',
            'focus:border-solid',
          ],
        },
      ],
      variants: {
        site: {
          psp: {
            base: 'bg-color-background-white',
          },
          wnw: {
            base: 'bg-color-background-white',
          },
        },
        size: {
          mobile: {
            base: 'gap-mob-margin-base-bottom',
            formWrapper:
              'flex-col gap-mob-margin-loose-bottom py-mob-padding-tight-y px-mob-global-grid-margin',
            halfFieldWrapper: 'flex-col',
            form: 'w-full gap-mob-margin-loose-bottom',
            field: 'w-full',
            halfField: 'w-full',
            disclaimer:
              'text-body-large-regular leading-body-large-regular font-body-large-regular',
            button:
              'gap-mob-component-button-full-space-between py-mob-component-button-full-padding-y px-mob-component-button-full-padding-x ',
            createTitle:
              'font-heading-mob-large-bold leading-heading-mob-large-bold text-heading-mob-large-bold',
            fieldWrap: [
              'gap-x-mob-space-between-loose-horizontal',
              'flex-col',
              'gap-mob-space-between-base-vertical',
            ],
            formTitle: [
              'text-heading-mob-large-bold',
              'leading-heading-mob-large-bold',
              'font-heading-mob-large-bold',
            ],
            formDescription: [
              'text-body-large-regular',
              'leading-body-large-regular',
              'font-body-large-regular',
            ],
            formTitleBlock: ['gap-mob-margin-tight-bottom'],
          },
          desktop: {
            base: 'max-w-desk-global-dimensions-width py-desk-padding-tight-y px-desk-global-grid-margin pb-desk-padding-micro-y    gap-desk-margin-base-bottom',
            formWrapper:
              'flex-row gap-desk-margin-loose-bottom py-desk-padding-tight-y px-desk-padding-tight-x ',
            halfFieldWrapper: 'flex-row',
            form: 'min-w-[290px] max-w-1/2 bg-color-red-300',
            field: 'min-w-[280px]',
            halfField: 'min-w-[280px]',
            disclaimer:
              'text-body-small-regular leading-body-small-regular font-body-small-regular',
            button:
              'gap-desk-component-button-full-space-between py-desk-component-button-full-padding-y px-component-button-full-padding-x',
            createTitle:
              'font-heading-desk-large-bold leading-heading-desk-large-bold text-heading-desk-large-bold',
            fieldWrap: [
              'gap-x-desk-space-between-loose-horizontal',
              'flex-row',
              'gap-none',
              'flex flex-cols items-end',
            ],
            formTitle: [
              'text-heading-desk-large-bold',
              'leading-heading-desk-large-bold',
              'font-heading-desk-large-bold',
            ],
            formDescription: [
              'text-body-large-regular',
              'leading-body-large-regular',
              'font-body-large-regular',
            ],
            formTitleBlock: ['gap-desk-margin-tight-bottom'],
          },
        },
        outlineCta: {
          true: {
            button: [
              'bg-color-background-white',
              'text-color-text-brand-1',
              'border-2 border-solid border-color-brand-primary-1-dark',
              'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
              'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
            ],
          },
          false: {
            button: [
              'bg-color-brand-primary-1-base',
              'text-color-text-white',
              'hover:bg-color-brand-primary-1-dark',
              'focus:bg-color-brand-primary-1-dark',
            ],
          },
        },
      },
    },
    {
      responsiveVariants: ['md', 'lg'], // `true` to apply to all screen sizes
    }
  );
  const {
    form,
    fieldWrap,
    // credentialsWrapper,
  } = loginFormStylings({
    site: themeName,
    size: {
      initial: 'mobile',
      md: 'desktop',
    },
  });
  const cookieModal = tv(
    {
      slots: {
        modalHeading: 'w-full h-auto flex flex-col justify-between align-center',
        modalTitle: 'font-primary text-color-text-dark',
        modalDisclaimer:
          'text-color-text-dark [&>*>a]:text-color-brand-primary-1-base [&>a]:text-color-brand-primary-1-base font-primary text-body-large-regular font-body-large-regular leading-body-large-regular w-full',
        modalButton:
          'flex justify-center items-center  rounded-desk-global-radius-small outline-none',
        buttonWrapper: 'w-full flex justify-around',
        formWrapper: 'flex flex-col items-start self-stretch gap-[24px]',
      },
      compoundSlots: [
        {
          slots: ['modalButton'],
          class: [
            'text-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
            'font-heading-desk-large-bold',
          ],
        },
      ],
      variants: {
        size: {
          mobile: {
            modalTitle:
              'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
            modalButton:
              'py-mob-component-button-full-padding-y px-mob-component-button-full-padding-x gap-mob-component-button-full-spacing-between',
            modalHeading: 'gap-mob-margin-tight-bottom',
          },
          desktop: {
            modalTitle:
              'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
            modalButton:
              'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x gap-desk-component-button-full-spacing-between',
            buttonWrapper: 'flex items-start items-stretch gap-[24px]',
            modalHeading: 'gap-desk-margin-tight-bottom',
          },
        },
        buttonOutline: {
          false: {
            modalButton: [
              'bg-color-brand-primary-1-base',
              'text-color-text-white',
              'hover:bg-color-brand-primary-1-dark',
              'focus:bg-color-brand-primary-1-dark',
            ],
          },
          true: {
            modalButton: [
              'bg-color-background-white',
              'text-color-text-brand-1',
              'border-2 border-solid border-color-brand-primary-1-dark',
              'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
              'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
            ],
          },
        },
      },
    },
    {
      responsiveVariants: ['md'],
    }
  );
  const { modalHeading, modalDisclaimer, modalTitle, modalButton, buttonWrapper, formWrapper } =
    cookieModal({
      size: {
        initial: 'mobile',
        md: 'desktop',
      },
    });
  // API to cover the ForgotPassword functionality:
  const forForgotPassword = async (values: FormikValues) => {
    // API for forgot password:
    const emailInput = values?.Email;
    const forgotPasswordAPI = apiConfig?.authenticationAPI?.forgotPasswordAPI;
    const headersData = {
      site: themeNameUpper,
      requesturl: window.location.origin,
      Authorization: token,
    };
    const postData = {
      Email: emailInput,
    };
    const options = {
      method: 'POST',
      headers: headersData,
      data: postData,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = await apiRequest(forgotPasswordAPI, options);
    setFinishPasswordSubmit(true);
    setAfterSubmit(true);
    if (res == ErrorCodes?.NotVerifiedUser) {
      setHasError(true);
    }
  };
  const initialForgotPasswordValues = {
    Email: '',
  };
  const [finishPasswordSubmit, setFinishPasswordSubmit] = useState(true);
  const [afterSubmit, setAfterSubmit] = useState(false);
  const [hasError, setHasError] = useState(false);

  const transFormFields = transformData(fields?.data?.data?.formFields);

  // handle modal Close functionality:
  const handleModalClose = () => {
    setcomponentContextData({ ...componentContextData, ToggleForgotPasswordForm: false });
    setAfterSubmit(false);
    document.body.classList.remove('modal-open');
  };
  //return empty fregment if there is no data
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={'forgotPasswordForm ' + params?.Styles}>
      {componentContextData && componentContextData.ToggleForgotPasswordForm && (
        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={handleModalClose}
          showModal={componentContextData.ToggleForgotPasswordForm}
          customPopup={true}
          popupSpacing="py-mob-padding-tight-y px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x shadow-privacyShadow modal-open"
          popupBG="bg-transparent"
          additionalClassForPopUpModal={`${
            params?.Styles ?? ''
          } !item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
        >
          <div className={formWrapper()}>
            <div className={modalHeading()}>
              <Text tag="h6" className={modalTitle()} field={fields?.data?.data?.title} />
              {!afterSubmit && (
                <Text
                  tag="p"
                  className={modalDisclaimer()}
                  field={fields?.data?.data?.shortDescription}
                />
              )}
            </div>
            <Formik
              initialValues={initialForgotPasswordValues}
              onSubmit={(
                values: FormValuesTypes,
                { setSubmitting }: FormikHelpers<FormValuesTypes>
              ) => {
                setFinishPasswordSubmit(false);
                forForgotPassword(values);
                setSubmitting(false);
              }}
            >
              <FormikForm className={form()}>
                {!afterSubmit && (
                  <div className={fieldWrap()}>
                    <TextField {...transFormFields.Email} />
                  </div>
                )}
                {afterSubmit && (
                  <Text
                    tag="p"
                    className={modalDisclaimer()}
                    field={
                      !hasError
                        ? fields?.data?.data?.successMessage
                        : fields?.data?.data?.failureMessage
                    }
                  />
                )}
                <div className={clsx(buttonWrapper(), '!justify-between', 'gap-[24px]')}>
                  {!afterSubmit && (
                    <button
                      aria-label="submit"
                      type="submit"
                      className={clsx(
                        modalButton({ buttonOutline: false, size: 'desktop' }),
                        'w-full'
                      )}
                    >
                      {!finishPasswordSubmit && 'Loading...'}
                      {finishPasswordSubmit && fields?.data?.data?.submitButtonText?.value}
                    </button>
                  )}
                  {fields?.data?.data?.cancelButtonText?.value && (
                    <button
                      aria-label="close"
                      className={clsx(
                        modalButton({
                          buttonOutline: afterSubmit ? false : true,
                          size: 'desktop',
                        }),
                        'w-full'
                      )}
                      onClick={handleModalClose}
                    >
                      {!afterSubmit && fields?.data?.data?.cancelButtonText?.value}
                      {afterSubmit && 'Close'}
                    </button>
                  )}
                </div>
                {/* )} */}
              </FormikForm>
            </Formik>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};
export default ForgotPasswordForm;
