import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';
import { Form as FormikForm, Formik, FormikValues, FormikHelpers } from 'formik';
import TextField from 'components/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import clsx from 'clsx';
import TextHelper from 'components/helpers/TextHelper';
import { apiRequest } from 'src/utils/apiWrapper';
import apiConfig from 'src/utils/apiConfig';
import { useRouter } from 'next/router';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { ErrorCodes, formErrorFlags } from 'components/helpers/Constants';
import RichTextHelper from 'components/helpers/RichTextHelper';

export type ResetPasswordFormProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          submitButtonText: Field<string>;
          successRedirectUrl: { jsonValue: LinkField };
          successMessage: Field<string>;
          errors: {
            values: { name: string; value: string }[];
          };
          failureMessage: Field<string>;
          disclaimer?: Field<string>;
          passwordCta: Field<string>;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
        };
      };
    };
  };

interface FormValuesTypes {
  Password?: string;
  ConfirmPassword?: string;
}
export interface FieldValues {
  [key: string]: string;
}
const forgot = tv(
  {
    slots: {
      linkError:
        'text-system-red-dark bg-system-red-light p-[15px] border border-system-red-default mb-5 rounded-mob-global-radius-small font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      linkErrorWrapper: 'container py-[15px]',
      base: 'resetPasswordForm flex flex-col items-center text-color-text-white center gap-[8px] self-stretch container mx-auto',
      formWrapper:
        'flex flex-col items-start self-stretch bg-color-background-light py-desk-padding-tight-y bg-color-background-light max-w-[800px] w-full mx-auto',
      form: 'flex flex-col items-start text-color-text-black [&>last-child]:gap-0',
      successMsg:
        '!text-color-text-dark text-body-large-regular font-body-large-regular leading-body-large-regular',
      button:
        'flex content-center rounded-desk-global-radius-small bg-color-brand-primary-1-base text-color-text-white text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold hover:bg-color-brand-primary-1-dark',

      field: 'flex flex-col  items-start self-stretch gap-desk-margin-micro-bottom',
      fieldWrap: ['flex', 'justify-between', 'w-full', ''],
      removeBorderRight: 'border-r-0',
      input:
        'flex flex-col items-start self-stretch font-body-large-regular h-[43px] gap-[8px] py-desk-global-spacing-spacing-0 px-desk-global-spacing-spacing-4 text-color-text-black border-2 border-solid border-color-border-mid bg-color-scale-1-white w-full ',
      title:
        'text-center font-heading-mob-xLarge-bold text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold text-color-text-dark py-mob-padding-tight-y px-mob-global-grid-margin pb-mob-padding-micro-y',
      position: 'relative',
    },
    compoundSlots: [
      {
        slots: ['form', 'title'],
        class: ['w-full', 'self-stretch'],
      },
      {
        slots: ['input', 'button'],
        class: ['outline-none'],
      },
      {
        slots: ['input'],
        class: [
          'focus:bg-color-scale-1-white',
          'focus:border-3',
          'focus:border-color-border-mid',
          'focus:border-solid',
        ],
      },
      {
        slots: ['button'],
        class: ['focus:bg-color-brand-primary-1-dark'],
      },
    ],
    variants: {
      site: {
        psp: {
          base: 'bg-color-background-white',
        },
        wnw: {
          base: 'bg-color-background-white',
        },
      },
      size: {
        mobile: {
          base: 'py-mob-padding-tight-y gap-mob-margin-base-bottom px-0',
          formWrapper:
            'flex-col gap-mob-global-spacing-spacing-7 px-mob-padding-tight-x py-mob-padding-tight-y',
          form: 'w-full gap-mob-margin-base-bottom',
          field: 'w-full',
          button:
            'gap-mob-component-button-full-space-between py-mob-component-button-full-padding-y px-mob-component-button-full-padding-x w-full items-center justify-center mt-[24px]',
          createTitle:
            'font-heading-mob-large-bold leading-heading-mob-large-bold text-heading-mob-large-bold',
          fieldWrap: ['gap-mob-space-between-loose-horizontal', 'flex-col'],
        },
        desktop: {
          base: 'py-desk-padding-tight-y pb-desk-margin-loose-bottom pt-none',
          formWrapper:
            'gap-desk-global-spacing-spacing-11 px-desk-padding-tight-x py-desk-padding-tight-y',
          form: 'min-w-[290px] gap-desk-margin-loose-bottom',
          field: 'min-w-[280px]',
          button:
            'gap-desk-component-button-full-space-between py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x items-start justify-between w-[241px] w-auto -mt-[16px]',
          createTitle:
            'font-heading-desk-large-bold leading-heading-desk-large-bold text-heading-desk-large-bold',
          fieldWrap: [
            'gap-x-desk-space-between-loose-horizontal',
            'flex-row',
            'flex flex-cols items-end',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['md'], // `true` to apply to all screen sizes
  }
);
//main component
const ResetPasswordForm = ({ fields, params }: ResetPasswordFormProps): JSX.Element => {
  const { themeName, themeNameUpper } = useTheme();
  const [fieldValues, setFieldValues] = useState<FieldValues>();
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isResetLinkInValid, setIsResetLinkInValid] = useState<false | string>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const token = useAuthToken();
  const router = useRouter();
  const checkIsURLIsValid = async () => {
    const headersData = {
      site: themeNameUpper, // for now, only PSP would work, WNW integration is left from OC Marketplace
      requesturl: window.location?.origin,
      Authorization: token,
    };
    const options = { method: 'GET', headers: headersData };
    const endPoint = apiConfig.authenticationAPI.ValidateResetPasswordLinkAPI;
    const params = router?.query;
    delete params?.path;
    if (params && Object.keys(params).length > 0) {
      try {
        const response: string = await apiRequest(
          endPoint + `?${new URLSearchParams(params as { [key: string]: string }).toString()}`,
          options
        );
        if (response && response?.toString()?.toLowerCase() === ErrorCodes?.Success) {
          setIsResetLinkInValid(false);
        } else {
          setIsResetLinkInValid(response);
        }
        setIsLoading(false);
      } catch (e) {
        console.error('Error in fetching validation data', e);
      }
    }
  };
  useEffect(() => {
    checkIsURLIsValid();
  }, [router?.query]);
  //geting custome error message
  const customErrorMessage = fields?.data?.data?.errors?.values?.flatMap((error) => error);
  const matchPasswordErrorMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.passwordMatch
  );
  const linkExpiredErrorMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.linkExpired
  );
  //form field initial values
  const initialValues = {
    Password: '',
    ConfirmPassword: '',
  };
  //importing variants
  const {
    base,
    formWrapper,
    successMsg,
    form,
    button,
    title,
    fieldWrap,
    position,
    linkErrorWrapper,
    linkError,
  } = forgot({
    site: themeName,
    size: {
      initial: 'mobile',
      md: 'desktop',
    },
  });

  //geting individual field from the formFields array
  const transFormFields = transformData(fields.data?.data?.formFields);
  //sending Reset password request on form submission.
  const changePassword = async (
    values: FormikValues
    // formikHelpers: FormikHelpers<FormikValues>
  ) => {
    // 1. Payload:
    const passwordObj = {
      Password: values.Password,
    };
    const headersData = {
      site: themeNameUpper, // for now, only PSP would work, WNW integration is left from OC Marketplace
      requesturl: window.location.origin,
      Authorization: token,
    };

    //options for API Request
    const options = { method: 'POST', headers: headersData, data: passwordObj };
    const endPoint = apiConfig.authenticationAPI.resetPasswordAPI;
    const params = router?.query;
    delete params?.path;
    const API_URL =
      endPoint + `?${new URLSearchParams(params as { [key: string]: string }).toString()}`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await apiRequest(API_URL, options);
    if (response && response.Errors) {
      setShowError(true);
    }
    //on success redirecting on login page or dynamic page
    if (response === ErrorCodes?.Success) {
      setShowError(false);
      setSuccess(true);
    } else if (response === ErrorCodes?.LinkExpired) {
      setShowError(true);
      setErrorMessage(decodeURI(linkExpiredErrorMessage[0]?.value));
    } else {
      setShowError(true);
      setErrorMessage(fields?.data?.data?.failureMessage?.value);
    }
  };

  //setting up values on change of the individual field
  const setValues = (values: FieldValues) => {
    setFieldValues({
      ...values,
    });
  };
  //handling show button functionality
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  //return empty fregment if there is no data
  if (fields === undefined || fields === null) return <></>;

  return (
    <>
      {!isLoading && (
        <>
          {!isResetLinkInValid ? (
            <div className={base({ className: params?.Styles ?? '' })}>
              {success ? (
                <div className={successMsg()}>
                  <RichTextHelper field={fields?.data?.data?.successMessage} />
                </div>
              ) : (
                <>
                  <TextHelper tag="p" className={title()} field={fields?.data?.data.title} />
                  <div className={formWrapper()}>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(
                        values: FormValuesTypes,
                        { setSubmitting }: FormikHelpers<FormValuesTypes>
                      ) => {
                        changePassword(values);
                        setSubmitting(false);
                      }}
                    >
                      <FormikForm className={form()}>
                        {showError && <div className="text-system-red">{errorMessage}</div>}
                        <div className={clsx(fieldWrap(), position())}>
                          <TextField
                            {...transFormFields.Password}
                            fieldtype={showPassword ? 'text' : 'password'}
                            className={''}
                            setFieldValue={setValues}
                            togglePasswordVisibility={togglePasswordVisibility}
                            showPassword={showPassword}
                          />
                        </div>

                        <div className={clsx(fieldWrap(), position())}>
                          <TextField
                            {...transFormFields.ConfirmPassword}
                            fieldtype={showConfirmPassword ? 'text' : 'password'}
                            className={''}
                            matchValue={fieldValues && fieldValues['Password']}
                            togglePasswordVisibility={toggleConfirmPasswordVisibility}
                            showPassword={showConfirmPassword}
                            customErrorMessage={decodeURI(matchPasswordErrorMessage?.[0]?.value)}
                          />
                        </div>
                        <button aria-label="submit" type="submit" className={button()}>
                          {
                            /* <TextHelper tag="p" field= */ fields?.data?.data?.submitButtonText
                              ?.value
                          }{' '}
                        </button>
                      </FormikForm>
                    </Formik>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={linkErrorWrapper()}>
              <div className={linkError()}>
                {decodeURI(
                  fields?.data?.data?.errors?.values?.find(
                    (error) => error?.name === isResetLinkInValid
                  )?.value || ''
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withDatasourceCheck()<ResetPasswordFormProps>(ResetPasswordForm);
