/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { setAppointments } from 'src/redux/Grooming/appointments';
import { getUser } from 'src/redux/ocUser';
import { useCookies } from 'react-cookie';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { GTM_EVENT, groomingPastAppointmentStatus } from 'components/helpers/Constants';
import { apiRequest, apiRequestExtended } from 'src/utils/apiWrapper';
import { tv } from 'tailwind-variants';
import useDictionary from 'src/hooks/useDictionary';
import clsx from 'clsx';
import { NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { setDogs } from 'src/redux/Grooming/user-dogs';
import LinkHelper from 'components/helpers/LinkHelper';
import { groomingUpcomingAppointmentStatus } from 'components/helpers/Constants';
import IconHelper from 'components/helpers/IconHelper';
import { setPastAppointments } from 'src/redux/Grooming/past-appointments';
import ComponentContext from 'lib/context/ComponentContext';
import ModalWrapper from 'components/helpers/ModalWrapper';
import ButtonHelper from 'components/helpers/Button';
import { REQUEST_METHOD } from 'src/helpers/MyPetsHelper';
import { PetResponse } from 'components/MyPet/MyPet';
import { useRouter } from 'next/router';
import { ChangeStoreVariants } from 'components/ProductDetail/ChangeStorePopup/ChangeStoreVariants';
import Loader from 'components/Loader/Loader';
import { groomingCancelAppointmentData } from 'src/utils/sendGTMEvent';
import { BirthdayDatePicker } from 'components/Grooming/ScheduleAppointment/ScheduleAppointmentStep2';
import { groomingApi, petsApi } from 'src/utils/nextApiConfig';

/**
 * Type for userData which will be sent to api/appointments
 */
type UserData = {
  payload: {
    FirstName?: string;
    LastName?: string;
    Email?: string;
    Phone?: string;
  } | null;
};

/**
 * GetAppointments data types
 */
interface Location {
  Id: number;
  CompanyId: number;
  StreetAddress: string;
  StreetAddress2: string | null;
  City: string;
  State: string;
  ZipCode: string;
  PhoneNumber: string;
  TimeZone: string;
  Lat: string;
  Lng: string;
  PspStoreId: string;
}

interface Service {
  Id: number;
  DealId: number;
  Name: string;
  Description: string | null;
  Duration: number;
  SortOrder: number;
  Amount: number;
}

interface Appointment {
  Id: number;
  Status: string;
  BeginAt: string;
  EmployeeName: string;
  Location: Location;
  ChildId: number;
  ChildName: string;
  ChildBreed: string;
  Services: Service[];
  PetImage?: string;
  PetDob?: string;
}

interface AppointmentArr {
  appointmentID: any;
  MyTimeChildId: any;
  ChildName: any;
  PetBreedName: any;
  BeginAt: any;
  EmployeeName: any;
  Services: any;
  Location: any;
  PetImage: any;
  PetDob: string;
}

const initialUserData: UserData = { payload: null };

/**
 * Upcoming and Past events tailwind variant code
 */
const bookGroomingVariants = tv(
  {
    slots: {
      appointmentsWrapper: ['relative'],
      upcomingAppointmentsWrapper: ['my-desk-margin-loose-bottom'],
      appointmentWrapperTitleWrapper: ['mb-desk-margin-base-bottom', 'flex', 'justify-between'],
      appointmentWrapperTitle: [
        'font-body-large-bold text-body-large-bold leading-body-large-bold text-color-text-black mb-desk-margin-micro-bottom',
      ],
      appointmentItemsWrapper: ['flex flex-col appointment-items-wrapper'],
      appointmentItemWrapper: [
        'py-desk-padding-micro-y px-desk-padding-micro-x border border-solid border-color-border-mid appointment-item-wrapper mb-desk-padding-micro-bottom even:bg-color-background-light ',
      ],
      petTitle: [
        'font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold text-color-text-black mb-desk-margin-base-bottom',
      ],
      petInfoWrapper: ['flex'],
      petImg: ['mr-[30px]'],
      petAppointmentDetails: [],
      petAppointmentStatusCTA: ['flex flex-col '],
      petAppointmentDateInfo: [
        'font-body-small-regular text-body-small-regular leading-body-small-regular text-color-text-dark mb-desk-padding-micro-bottom',
      ],
      petServiceName: ['block'],
      petAppointmentAddress: [
        'text-color-brand-primary-1-base underline font-body-small-regular_underline text-body-small-regular_underline leading-body-small-regular_underline max-w-[150px] cursor-pointer',
      ],
      petAppointementCTA: [
        'z-10',
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'rounded-[0.25rem]',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'py-desk-component-button-full-padding-y',
        'px-desk-component-button-full-padding-x',
        'text-color-text-white',
      ],
      scheduleAgain: ['bg-color-brand-primary-1-base'],
      cancelAppointment: ['bg-color-brand-primary-1-base'],
      fillBase: [
        '[&>svg>*>*]:fill-color-brand-primary-1-base [&>svg>*]:fill-color-brand-primary-1-base',
      ],
      showMore: [
        'flex flex-row items-center w-full justify-center text-color-brand-primary-1-base ',
      ],
      appointmentFilter: [
        'cursor-pointer appearance-none border-2 focus:outline-none w-full h-[48px] border-color-scale-mid',
      ],
      petFilterDropDown: ['relative'],
      petFilterDropDownIcon: ['absolute cursor-pointer top-1/2 transform -translate-y-1/2 right-2'],
      checkbox: [
        'w-[24px]',
        'h-[24px]',
        'outline-color-border-mid',
        'border',
        'appearance-none',
        'bg-color-background-white',
        'peer',
        'checked:bg-color-brand-primary-1-base',
        'rounded-[4px]',
        'border',
        'border-color-border-brand',
      ],
      checkboxIcon: [
        '[&>svg>*>*]:fill-color-background-white absolute [&>svg]:w-[24px] [&>svg]:h-[24px] invisible peer-checked:visible left-0',
      ],
    },
    variants: {
      size: {
        mobile: {
          appointmentFilter: [
            'rounded-desk-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4 pr-desk-global-spacing-spacing-6',
          ],
          petInfoWrapper: ['flex-col'],
          appointmentItemWrapper: ['min-h-auto'],
          petAppointementCTA: ['block mt-[20px]'],
          appointmentWrapperTitleWrapper: ['flex-col'],
        },
        desktop: {
          appointmentFilter: [
            'rounded-desk-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
          ],
          petInfoWrapper: ['flex-row'],
          appointmentItemWrapper: ['min-h-[198px]'],
          petAppointmentStatusCTA: ['ml-auto'],
          appointmentWrapperTitleWrapper: ['flex-row'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

const UpcomingAppointments = () => {
  // Const declrations
  const { sitecoreContext } = useSitecoreContext();
  const dispatch = useOcDispatch();
  const minItems = Number(
    (sitecoreContext as any)?.route?.fields?.groomingConfiguration?.[0]?.fields
      ?.appointmentInitialLoadCount?.value
  );
  const maxItems = Number(
    (sitecoreContext as any)?.route?.fields?.groomingConfiguration?.[0]?.fields
      ?.appointmentLoadMoreCount?.value
  );
  const [userData, setUserData] = useState<UserData>(initialUserData);
  const [visibleAppointments, setVisibleAppointments] = useState<number[]>([]);
  const [pastvisibleAppointments, setPastVisibleAppointments] = useState<number[]>([]);
  const [petDropdownItems, setPetDropdownItems] = useState<any[]>([]);
  const [pastpetDropdownItems, setPastPetDropdownItems] = useState<any[]>([]);

  const [selectedUpcomingDogId, setSelectedUpcomingDogId] = useState('');
  const [selectedPastDogId, setSelectedPastDogId] = useState('');
  const [isUpcomingDropdownVisible, setIsUpcomingDropdownVisible] = useState(false);
  const [isPastDropdownVisible, setIsPastDropdownVisible] = useState(false);
  const [scheduleAgainLoader, setscheduleAgainLoader] = useState(null);
  const [createUpdatePetWhileReschdulingLoader, setcreateUpdatePetWhileReschdulingLoader] =
    useState<boolean>(false);

  const [showBirthdayWarning, setShowBirthdayWarning] = useState(false);
  const [showCancelWarning, setshowCancelWarning] = useState(false);
  const [addPetToCancel, setAddPetToCancel] = useState<any>([]);
  const [appointmentForBirthday, setAppointmentForBirthday] = useState<any>([]);
  const [showCancelLoader, setShowCancelLoader] = useState(false);
  const [showCancelSuccess, setShowCancelSuccess] = useState(false);
  const [saveToProfileChecked, setSaveToProfileChecked] = useState(true);
  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const [petbirthDate, setPetBirthDate] = useState<string | number | null>('');
  const [appointmentsLoader, setAppointmentsLoader] = useState<boolean>(true);
  const router = useRouter();
  const [cookies] = useCookies(['ordercloud.access-token']);
  const upcomingAppointmentData = useOcSelector(
    (state) => state?.upcomingAppointments?.appointments
  );
  const pastAppointmentData = useOcSelector((state) => state?.pastAppointments?.appointments);
  //const petsDogsData = useOcSelector((state) => state?.getDogs?.dogs);
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const appointmentConfirmationPopupContent = (sitecoreContext as any)?.route?.fields
    ?.groomingConfiguration?.[0]?.fields;
  const {
    appointmentsWrapper,
    upcomingAppointmentsWrapper,
    appointmentWrapperTitle,
    appointmentWrapperTitleWrapper,
    appointmentItemsWrapper,
    appointmentItemWrapper,
    petTitle,
    petInfoWrapper,
    petImg,
    petAppointmentDetails,
    petAppointmentStatusCTA,
    petAppointmentDateInfo,
    petServiceName,
    petAppointmentAddress,
    petAppointementCTA,
    scheduleAgain,
    fillBase,
    showMore,
    appointmentFilter,
    petFilterDropDown,
    petFilterDropDownIcon,
    checkbox,
    checkboxIcon,
  } = bookGroomingVariants({ size: { initial: 'mobile', lg: 'desktop' } });
  const { selectStoreCTA } = ChangeStoreVariants({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  useEffect(() => {
    setcomponentContextData({
      ...componentContextData,
      petsData: null,
      bookedAppointmentForStep1: null,
      bookedAppointmentForStep2: null,
      bookedAppointmentForStep3: null,
      bookedAppointmentForStep4: null,
      resetStep2: null,
      resetStep3: null,
      resetStep4: null,
      groomingStep1StoreWidget: null,
    });
  }, []);
  /**
   * Below useEffect fetches loggedin user data which needs to be used to make further calls to Azure functions
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getUser());
        setUserData(response as any);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchData();
  }, [dispatch]);

  /**
   * Below code checks if logged in user's appointment data is already present in Redux or not
   */
  useEffect(() => {
    if (userData && userData?.payload) {
      const appionmentsData = {
        firstName: userData?.payload?.FirstName,
        lastName: userData?.payload?.LastName,
        email: userData?.payload?.Email,
        phone: userData?.payload?.Phone,
      };

      getAppointments(appionmentsData)
        .then((appointmentsData) => {
          const filteredAppointments = appointmentsData.filter(
            (appointment: Appointment) =>
              appointment?.Status === groomingUpcomingAppointmentStatus?.booked ||
              appointment?.Status === groomingUpcomingAppointmentStatus?.auto_booked ||
              appointment?.Status === groomingUpcomingAppointmentStatus?.confirmed
          );
          setAppointmentsData(filteredAppointments, false);
          const filterPastAppointments = appointmentsData.filter(
            (appointment: Appointment) =>
              appointment?.Status === groomingPastAppointmentStatus?.CanceledByConsumer ||
              appointment?.Status === groomingPastAppointmentStatus?.CanceledByMerchant ||
              appointment?.Status === groomingPastAppointmentStatus?.NoShow
          );
          setAppointmentsLoader(false);
          setAppointmentsData(filterPastAppointments, true);
        })
        .catch((error) => console.error('Error getting appointments data:', error));

      getDogs()
        .then((dogsData) => setDogsData(dogsData))
        .catch((error) => console.error('Error getting dogs data:', error));
    }
  }, [userData, cookies, dispatch]);

  /**
   * getAppointments call
   */
  const getAppointments = (appointmentsData: any) => {
    const options = { method: 'POST', data: appointmentsData };
    return apiRequest(groomingApi.appointments, options)
      .then((response: any) => {
        if (response && response.Errors) {
          console.error('Error');
        }
        return response;
      })
      .catch((error) => {
        console.error('Error fetching appointments:', error);
        return null;
      });
  };

  /**
   * getDogs call
   */
  const getDogs = () => {
    const options = { method: 'POST' };
    return apiRequest(petsApi.allPets, options)
      .then((response: any) => {
        if (response && response.Errors) {
          console.error('Error');
        }

        setcomponentContextData({
          ...componentContextData,
          petsData: response,
        });
        return response;
      })
      .catch((error) => {
        console.error('Error fetching dogs:', error);
        return null;
      });
  };

  /**
   * Below code stores appointment data to redux
   */
  const setAppointmentsData = (appointmentsData: any, isPast?: boolean) => {
    isPast == true
      ? dispatch(setPastAppointments(appointmentsData))
      : dispatch(setAppointments(appointmentsData));
  };

  // Below 2 functions are written for GTM Related features

  function calculateCombinedServicePrice(data: any) {
    if (data) {
      const combinedServicePrice = data.Services.reduce((acc: any, item: any) => {
        const price = parseFloat(item.Amount);
        return acc + (isNaN(price) ? 0 : price);
      }, 0).toFixed(2);
      return combinedServicePrice;
    }
    return '0.00';
  }

  function transformItems(appointement: any) {
    if (appointement?.Services && appointement?.Services.length > 0) {
      if (
        appointement?.Services[0].servicedescription === 'Nail & Quick Services' ||
        appointement?.Services[0].servicedescription === 'Puppy Services'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return appointement?.Services.slice(1).map((item: any) => ({
          itemName: item.Name,
          price: Number(item.Amount).toFixed(2),
        }));
      } else {
        return appointement?.Services.map((item: any) => ({
          itemName: item.Name,
          price: Number(item.Amount).toFixed(2),
        }));
      }
    }
  }

  /**
   * Below code stores dogs data to redux
   */
  const setDogsData = (dogsData: any) => {
    dispatch(setDogs(dogsData));
  };
  const upcomingAppointments: { [key: string]: any } = upcomingAppointmentData || {};
  const pastAppointments: { [key: string]: any } = pastAppointmentData || {};

  const cancelAppointment = (appointmentID: string, addPetToCancel: any) => {
    setShowCancelLoader(true);

    const options = {
      method: 'POST',
      data: {
        id: appointmentID,
      },
    };
    return apiRequestExtended(groomingApi.cancelAppointment, options)
      .then((response: any) => {
        if (response && response.Errors) {
          console.error('Error');
        }
        if (response?.status === 200) {
          setShowCancelLoader(false);
          setShowCancelSuccess(true);
          const items = transformItems(addPetToCancel);
          groomingCancelAppointmentData({
            event: GTM_EVENT?.groomingCancel,
            storeId: myStoreData?.storeId,
            groomingStoreId: addPetToCancel?.Location?.PspStoreId,
            groomingApptId: addPetToCancel?.Id,
            revenue: calculateCombinedServicePrice(addPetToCancel),
            items: items,
          });
        }
        return response;
      })
      .catch((error) => {
        console.error('Error fetching appointments:', error);
        return null;
      });
  };

  const groupedAppointments: AppointmentArr[] = Object.values(upcomingAppointments).reduce(
    (acc, appointment: any) => {
      const { ChildName } = appointment;
      if (!acc[ChildName]) {
        acc[ChildName] = [];
      }

      const foundPet: any = componentContextData?.petsData
        ? Object.values(componentContextData.petsData).find((dog: any) => dog.Petname === ChildName)
        : undefined;

      // Add the dog image from here
      const modifiedAppointment = {
        ...appointment,
        petImage:
          foundPet && foundPet?.PetImageName
            ? process.env.NEXT_PUBLIC_PSP_PET_CDN + '/' + foundPet?.PetImageName
            : (sitecoreContext as any)?.route?.fields?.groomingConfiguration?.[0]?.fields
                ?.defaultPetImage?.value?.src || null,
        PetDob: foundPet && foundPet?.Birthdate ? foundPet?.BirthdateString : null,
      };

      acc[ChildName].push(modifiedAppointment);
      return acc;
    },
    {} as { [key: string]: AppointmentArr[] }
  );

  const groupedAppointmentsArray = groupedAppointments;

  const groupedAppointmentsArrayWithWrappers: { group: string; appointments: AppointmentArr[] }[] =
    Object.entries(groupedAppointments).map(([group, appointments]) => ({
      group,
      appointments: appointments as unknown as AppointmentArr[],
    }));

  // Same procedure for pastAppointments

  // Same procedure for pastAppointments
  const groupedPastAppointments: AppointmentArr[] = Object.values(pastAppointments).reduce(
    (acc, appointment: any) => {
      const { ChildName } = appointment;
      if (!acc[ChildName]) {
        acc[ChildName] = [];
      }
      const foundPet: any = componentContextData?.petsData
        ? Object.values(componentContextData.petsData).find((dog: any) => dog.Petname === ChildName)
        : undefined;

      // Add the dog image here

      const modifiedAppointment = {
        ...appointment,
        petImage:
          foundPet && foundPet?.PetImageName
            ? process.env.NEXT_PUBLIC_PSP_PET_CDN + '/' + foundPet?.PetImageName
            : (sitecoreContext as any)?.route?.fields?.groomingConfiguration?.[0]?.fields
                ?.defaultPetImage?.value?.src || null,
        PetDob: foundPet && foundPet?.Birthdate ? foundPet?.BirthdateString : null,
      };
      acc[ChildName].push(modifiedAppointment);
      return acc;
    },
    {} as { [key: string]: AppointmentArr[] }
  );

  const groupedPastAppointmentsArray = groupedPastAppointments;

  const groupedPastAppointmentsArrayWithWrappers: {
    group: string;
    appointments: AppointmentArr[];
  }[] = Object.entries(groupedPastAppointments).map(([group, appointments]) => ({
    group,
    appointments: appointments as unknown as AppointmentArr[],
  }));

  /**
   * Dropdown items for pets
   */
  useEffect(() => {
    // !Upcoming appointement pets

    const uniquePets = new Set<string>();

    const pets: { id: any; name: any }[] = Object.values(groupedAppointmentsArray).reduce(
      (acc: any, group: any) => {
        if (group) {
          group.forEach((appointment: any) => {
            if (!uniquePets.has(appointment.ChildName)) {
              uniquePets.add(appointment.ChildName);
              acc.push({
                id: appointment.MyTimeChildId,
                name: appointment.ChildName,
              });
            }
          });
        }
        return acc;
      },
      []
    );
    const newPetDropdownItems = [{ id: '', name: 'Show All Dogs' }, ...pets];

    setPetDropdownItems((prevItems) => {
      if (JSON.stringify(prevItems) === JSON.stringify(newPetDropdownItems)) {
        return prevItems;
      }
      return newPetDropdownItems;
    });
    setIsUpcomingDropdownVisible(newPetDropdownItems.length > 2);

    //! Past appointment pets
    const uniquePastPets = new Set<string>();
    const Pastpets: { id: any; name: any }[] = Object.values(groupedPastAppointmentsArray).reduce(
      (acc: any, group: any) => {
        if (group) {
          group.forEach((appointment: any) => {
            if (!uniquePastPets.has(appointment.ChildName)) {
              uniquePastPets.add(appointment.ChildName);
              acc.push({
                id: appointment.MyTimeChildId,
                name: appointment.ChildName,
              });
            }
          });
        }
        return acc;
      },
      []
    );
    const newPastPetDropdownItems = [{ id: '', name: 'Show All Dogs' }, ...Pastpets];

    setPastPetDropdownItems((prevItems) => {
      if (JSON.stringify(prevItems) === JSON.stringify(newPastPetDropdownItems)) {
        return prevItems;
      }
      return newPastPetDropdownItems;
    });
    setIsPastDropdownVisible(newPastPetDropdownItems.length > 2);
  }, [groupedAppointmentsArray]);

  // For Upcoming appointments
  const handleChangeUpcoming = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUpcomingDogId(e.target?.value);
  };

  // For Past appointments
  const handleChangePast = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPastDogId(e.target?.value);
  };

  // Filtered appointments based on pets
  const filteredAppointments = groupedAppointmentsArrayWithWrappers.reduce(
    (acc: any, group: any) => {
      if (group.appointments) {
        const filteredGroupAppointments = group.appointments.filter(
          (appointment: any) =>
            !selectedUpcomingDogId || appointment.ChildName === selectedUpcomingDogId
        );
        if (filteredGroupAppointments.length > 0) {
          acc.push({
            group: group.group,
            appointments: filteredGroupAppointments,
          });
        }
      }

      return acc;
    },
    []
  );

  useEffect(() => {
    if (
      filteredAppointments?.length > 0 &&
      filteredAppointments?.length > visibleAppointments?.length
    ) {
      setVisibleAppointments((prevAppointments) => [
        ...prevAppointments,
        ...Array(filteredAppointments.length).fill(
          minItems ||
            (sitecoreContext as any)?.route?.fields?.groomingConfiguration?.[0]?.fields
              ?.appointmentLoadMoreCount?.value
        ),
      ]);
    }
  }, [filteredAppointments, sitecoreContext]);

  // Filtered past appointments based on pets
  const filteredPastAppointments = groupedPastAppointmentsArrayWithWrappers.reduce(
    (acc: any, group: any) => {
      if (group.appointments) {
        const filteredGroupAppointments = group.appointments.filter(
          (appointment: any) => !selectedPastDogId || appointment.ChildName === selectedPastDogId
        );

        if (filteredGroupAppointments.length > 0) {
          acc.push({
            group: group.group,
            appointments: filteredGroupAppointments,
          });
        }
      }
      return acc;
    },
    []
  );

  useEffect(() => {
    if (
      filteredPastAppointments?.length > 0 &&
      filteredPastAppointments?.length > pastvisibleAppointments?.length
    ) {
      setPastVisibleAppointments((prevAppointments) => [
        ...prevAppointments,
        ...Array(filteredPastAppointments.length).fill(
          minItems ||
            (sitecoreContext as any)?.route?.fields?.groomingConfiguration?.[0]?.fields
              ?.appointmentLoadMoreCount?.value
        ),
      ]);
    }
  }, [filteredPastAppointments, sitecoreContext]);

  const handleModalClose = () => {
    setscheduleAgainLoader(null);
    setShowBirthdayWarning(false);
  };

  const handleCancelModalPopup = async () => {
    if (showCancelSuccess) {
      if (Array.isArray(upcomingAppointmentData) && Array.isArray(pastAppointmentData)) {
        const data = [...pastAppointmentData, addPetToCancel];
        await dispatch(
          setAppointments(upcomingAppointmentData?.filter((p) => p?.Id !== addPetToCancel?.Id))
        );
        await dispatch(setPastAppointments(data));
      }
    }
    setshowCancelWarning(false);
    setShowCancelSuccess(false);
    setAddPetToCancel([]);
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const profileChecked = () => {
    setSaveToProfileChecked(!saveToProfileChecked);
  };

  const updatePetFromBdyPopuop = async () => {
    const addPetRequest = {
      method: REQUEST_METHOD?.post,
      data: {
        PetId: appointmentForBirthday?.ChildId,
        petName: appointmentForBirthday?.ChildName,
        speciesID: 2,
        genderID: appointmentForBirthday?.Gender,
        breedID: appointmentForBirthday?.BreedId,
        birthdate:
          petbirthDate && petbirthDate != '' ? formatDate(new Date(petbirthDate as string)) : null,
        SaveToProfile: saveToProfileChecked,
      },
    };
    if (saveToProfileChecked) {
      await apiRequest(petsApi.updatePet, addPetRequest);
    }
  };

  //set visibleAppointment on click of Show more button
  const handleVisibleAppointment = (index: number, val: number) => {
    setVisibleAppointments((prevAppointments) => {
      const updatedAppointments = [...prevAppointments];
      updatedAppointments[index] = updatedAppointments[index] + val;
      return updatedAppointments;
    });
  };

  //set pastVisibleAppointment on click of Show more button
  const handlePastVisibleAppointments = (index: number, val: number) => {
    setPastVisibleAppointments((prevAppointments) => {
      const updatedAppointments = [...prevAppointments];
      updatedAppointments[index] = updatedAppointments[index] + val;
      return updatedAppointments;
    });
  };

  const createUpdatePetWhileReschduling = async (appointment: any) => {
    const createUpdatePetData = {
      userPetId: '',
      PetName: appointment?.ChildName,
      petdateofbirth: petbirthDate,
      BreedName: appointment?.ChildBreed,
      PreviousPetName: '',
      firstName: userDetails?.FirstName,
      lastName: userDetails?.LastName,
      email: userDetails?.Email,
      phone: userDetails?.Phone,
      SaveToProfile: saveToProfileChecked,
    };
    // // setPetLoader(true);

    try {
      const options = {
        method: REQUEST_METHOD?.post,
        data: createUpdatePetData,
      };
      const res: PetResponse[] = await apiRequest(groomingApi.createUpdatePet, options);
      if (res) {
        setcomponentContextData({
          ...componentContextData,
          bookedAppointmentForStep1: appointment,
          bookedAppointmentForStep2: appointment,
          bookedAppointmentForStep3: appointment,
          bookedAppointmentForStep4: appointment,
          //petsData: petsDogsData,
          resetStep2: false,
          resetStep3: false,
          resetStep4: false,
          newPetBdy: petbirthDate,
        });

        router.push('/accountmanagement/grooming-salon/schedule-appointment');
      }
    } catch (err) {
      console.error('@@Error', err);
    }
  };

  const triggerCancelPopup = (appointment: any) => {
    setAddPetToCancel(appointment);
    setshowCancelWarning(true);
  };

  return (
    <>
      {appointmentsLoader ? (
        <div className="w-full text-center p-[25px] flex justify-center">
          <Loader />
        </div>
      ) : (
        <div>
          {/* Upcoming appointments */}
          <div className={clsx(appointmentsWrapper(), upcomingAppointmentsWrapper())}>
            <div
              className={clsx(
                appointmentWrapperTitleWrapper(),
                filteredAppointments.length > 0 ? 'lg:flex-row ' : 'lg:flex-col w-fit'
              )}
            >
              <div className={appointmentWrapperTitle()}>
                {getDictionaryValue('GroomingAppointmentPageUpcomingAppointments')}
              </div>
              {filteredAppointments.length === 0 && (
                <>
                  <div>{getDictionaryValue('GroomingMessageNoUpcomingAppointments')}</div>
                  <LinkHelper
                    className={clsx(selectStoreCTA(), 'inline-block my-[12px] !w-auto')}
                    field={{
                      value: {
                        href: appointmentConfirmationPopupContent?.scheduleAnotherAppointmentCTA
                          ?.value?.href,
                        text: getDictionaryValue(
                          'ScheduleAppointmentsStep4ScheduleAppointmentButtonText'
                        ),
                      },
                    }}
                    onClick={() => {
                      setShowCancelLoader(true);
                    }}
                  />
                </>
              )}
              {/* Dropdown for pets */}
              {isUpcomingDropdownVisible && filteredAppointments.length > 0 && (
                <div className={petFilterDropDown()}>
                  <select
                    aria-label="petDropdownItems"
                    value={selectedUpcomingDogId}
                    onChange={handleChangeUpcoming}
                    className={appointmentFilter()}
                  >
                    {petDropdownItems.map((pet) => (
                      <option key={pet.id} value={pet.id}>
                        {pet.name}
                      </option>
                    ))}
                  </select>
                  <IconHelper
                    icon={'Caret'}
                    className={clsx(fillBase({ className: 'ml-2' }), petFilterDropDownIcon())}
                  />
                </div>
              )}
            </div>

            <div className={appointmentItemsWrapper()}>
              {filteredAppointments.length > 0 &&
                filteredAppointments.map((group: any, groupIndex: number) => (
                  <div key={groupIndex}>
                    {Array.isArray(group.appointments) &&
                      group.appointments.length > 0 &&
                      group.appointments
                        .sort(
                          (a: any, b: any) =>
                            new Date(a.BeginAt).getTime() - new Date(b.BeginAt).getTime()
                        )
                        .slice(0, visibleAppointments[groupIndex])
                        .map((appointment: any, index: number) => (
                          <div key={index} className={appointmentItemWrapper()}>
                            <div className={petTitle()}>{appointment.ChildName}</div>
                            <div className={petInfoWrapper()}>
                              <div className={petImg()}>
                                <NextImage
                                  className="rounded-full h-[64px] w-[64px] object-cover"
                                  field={{
                                    value: {
                                      src: appointment.petImage,
                                      alt: 'AddPet',
                                      width: '64',
                                      height: '64',
                                    },
                                  }}
                                />
                              </div>
                              <div
                                className={clsx(petAppointmentDetails(), ' mt-[12px] lg:mt-[0px]')}
                              >
                                <div className={petAppointmentDateInfo()}>
                                  {new Date(appointment.BeginAt).toLocaleString('en-US', {
                                    month: 'long',
                                    day: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                    timeZone: 'UTC',
                                  })}{' '}
                                  with {appointment?.EmployeeName}
                                  {appointment?.Services &&
                                    appointment?.Services.length > 0 &&
                                    appointment.Services.map((service: any, index: number) => (
                                      <span key={index} className={petServiceName()}>
                                        {service.Name}
                                      </span>
                                    ))}
                                </div>
                                <div className={petAppointmentAddress()}>
                                  {appointment.Location?.StreetAddress
                                    ? appointment.Location.StreetAddress + ','
                                    : ''}
                                  {appointment.Location?.StreetAddress2
                                    ? appointment.Location.StreetAddress2 + ','
                                    : ''}
                                  {appointment.Location?.ZipCode
                                    ? appointment.Location.ZipCode + ','
                                    : ''}
                                  <p className="block">
                                    {appointment?.Location?.PhoneNumber ? (
                                      <a href={`tel:${appointment.Location.PhoneNumber}`}>
                                        {appointment.Location.PhoneNumber}
                                      </a>
                                    ) : (
                                      ''
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className={petAppointmentStatusCTA()}>
                                <div className={'w-full text-center mt-[12px] lg:mt-[0px]'}>
                                  <div className={''}>
                                    {scheduleAgainLoader !== null &&
                                    scheduleAgainLoader == appointment.Id ? (
                                      <div
                                        className={
                                          'items-center flex flex-row border-b-0 gap-[7px] text-center justify-center'
                                        }
                                      >
                                        <Loader />
                                        <span>
                                          {getDictionaryValue(
                                            'ScheduleAppointmentsStep2SelectDogInProgressText'
                                          )}
                                        </span>
                                      </div>
                                    ) : (
                                      <LinkHelper
                                        field={{
                                          value: {
                                            title: 'Schedule Again',
                                            text: 'Schedule Again',
                                            // href: '/accountmanagement/grooming-salon/schedule-appointment',
                                            href: '/',
                                          },
                                        }}
                                        className={clsx(
                                          scheduleAgain(),
                                          petAppointementCTA(),
                                          '!mt-[0px]'
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          {
                                            if (appointment?.PetDob === null) {
                                              setAppointmentForBirthday(appointment);
                                              setShowBirthdayWarning(true);
                                            } else {
                                              router.push(
                                                '/accountmanagement/grooming-salon/schedule-appointment'
                                              );
                                            }
                                            setscheduleAgainLoader(appointment.Id);
                                            setcomponentContextData({
                                              ...componentContextData,
                                              bookedAppointmentForStep1: appointment,
                                              bookedAppointmentForStep2: appointment,
                                              bookedAppointmentForStep3: appointment,
                                              bookedAppointmentForStep4: appointment,
                                              //  petsData: petsDogsData,
                                              resetStep2: false,
                                              resetStep3: false,
                                              resetStep4: false,
                                            });
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                {new Date(appointment.BeginAt).getTime() - Date.now() >
                                172800000 ? (
                                  <div className={''}>
                                    <ButtonHelper
                                      field={{
                                        value: getDictionaryValue(
                                          'GroomingAppointmentPageCancelAppointmentModalHeading'
                                        ),
                                      }}
                                      className="block mt-[12px] !px-[24px] text-center"
                                      isLinkfield={false}
                                      size="medium"
                                      variant="secondary"
                                      onClickHandler={() => {
                                        triggerCancelPopup(appointment);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <p className="mt-[12px]">
                                    Please call store:{' '}
                                    <a
                                      className=" text-color-brand-primary-1-base font-body-large-bold_underline text-body-large-bold_underline
leading-body-large-bold_underline  underline"
                                      href={`tel:${appointment?.Location?.PhoneNumber}`}
                                    >
                                      {appointment?.Location?.PhoneNumber}
                                    </a>{' '}
                                    to cancel
                                  </p>
                                )}
                                {/* { Cancel appointment popup} */}
                                <ModalWrapper
                                  showCloseButtonInModalHeader={false}
                                  closeModalOnOverlayClick={true}
                                  onCloseClick={handleCancelModalPopup}
                                  showModal={showCancelWarning}
                                  customPopup={true}
                                  popupWidth="max-w-[1200px] overflow-y-visible"
                                  popupSpacing="py-mob-padding-tight-y px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x"
                                >
                                  <div className="birthday-warning-popup">
                                    <span className="font-heading-desk-xLarge-bold text-color-text-black text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold mb-[24px] block">
                                      {getDictionaryValue(
                                        'GroomingAppointmentPageCancelAppointmentModalHeading'
                                      )}
                                    </span>

                                    {!showCancelSuccess && (
                                      <p className="text-body-large-bold leading-body-large-bold font-body-large-bold  text-color-text-black  mb-[20px]">
                                        {/* {getDictionaryValue(
                                    'ScheduleAppointmentsStep2DogDateOfBirthMissingText'
                                  ).replace('{0}', `${appointment?.ChildName}`)} */}
                                        Are you sure you want to cancel your apppointment for{' '}
                                        {addPetToCancel?.ChildName} on{' '}
                                        {new Date(addPetToCancel.BeginAt).toLocaleString('en-US', {
                                          month: 'long',
                                          day: '2-digit',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true,
                                        })}
                                        ?
                                      </p>
                                    )}
                                    {showCancelSuccess && (
                                      <p className="text-body-large-bold leading-body-large-bold font-body-large-bold  text-color-text-black  mb-[20px]">
                                        {getDictionaryValue(
                                          'GroomingCancelAppointmentSuccessMessage'
                                        )}
                                      </p>
                                    )}
                                    {!showCancelSuccess && (
                                      <div className="flex lg:flex-row flex-col gap-[12px] mt-[24px] items-center">
                                        {showCancelLoader && (
                                          <div
                                            className={
                                              'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'
                                            }
                                          >
                                            <NextImage
                                              field={{
                                                value: {
                                                  src: '/images/loader.gif',
                                                  alt: 'loader',
                                                  width: '32',
                                                  height: '32',
                                                },
                                              }}
                                            />
                                            <span>
                                              {getDictionaryValue(
                                                'ScheduleAppointmentsStep2SelectDogInProgressText'
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        {!showCancelLoader && (
                                          <ButtonHelper
                                            field={{
                                              value: 'Confirm Cancellation',
                                            }}
                                            className="lg:w-auto w-full"
                                            isLinkfield={false}
                                            size="medium"
                                            variant={'primary'}
                                            onClickHandler={() => {
                                              cancelAppointment(addPetToCancel.Id, addPetToCancel);
                                            }}
                                          />
                                        )}

                                        <ButtonHelper
                                          field={{
                                            value: 'Do Not Cancel',
                                          }}
                                          isLinkfield={false}
                                          className="lg:w-auto w-full"
                                          size="medium"
                                          variant="secondary"
                                          onClickHandler={() => {
                                            handleCancelModalPopup();
                                            setscheduleAgainLoader(null);
                                          }}
                                        />
                                      </div>
                                    )}
                                    {showCancelSuccess && (
                                      <div className="flex lg:flex-row flex-col gap-[12px] mt-[24px] items-center">
                                        {showCancelLoader && (
                                          <div
                                            className={
                                              'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'
                                            }
                                          >
                                            <NextImage
                                              field={{
                                                value: {
                                                  src: '/images/loader.gif',
                                                  alt: 'loader',
                                                  width: '32',
                                                  height: '32',
                                                },
                                              }}
                                            />
                                            <span>
                                              {getDictionaryValue(
                                                'ScheduleAppointmentsStep2SelectDogInProgressText'
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        {!showCancelLoader && (
                                          <LinkHelper
                                            className={clsx(
                                              selectStoreCTA(),
                                              'inline-block my-[12px] lg:w-auto w-full'
                                            )}
                                            field={{
                                              value: {
                                                href: appointmentConfirmationPopupContent
                                                  ?.scheduleAnotherAppointmentCTA?.value?.href,
                                                text: appointmentConfirmationPopupContent
                                                  ?.scheduleAnotherAppointmentCTA?.value?.text,
                                              },
                                            }}
                                            onClick={() => {
                                              setShowCancelLoader(true);
                                            }}
                                          />
                                        )}

                                        <LinkHelper
                                          className={clsx(
                                            'ml-[12px] text-color-text-brand-1 lg:w-auto w-full'
                                          )}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleCancelModalPopup();
                                          }}
                                          field={{
                                            value: {
                                              href: '/',
                                              text: getDictionaryValue(
                                                'ScheduleAppointmentsStep4ViewYourAppointmentText'
                                              ),
                                            },
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </ModalWrapper>
                              </div>
                            </div>
                          </div>
                        ))}
                    {group.appointments &&
                      group.appointments.length > minItems &&
                      visibleAppointments[groupIndex] < group.appointments.length && (
                        <button
                          aria-label="show more"
                          onClick={() => handleVisibleAppointment(groupIndex, maxItems)}
                          className={showMore()}
                        >
                          {getDictionaryValue('ScheduleAppointmentsStep4ViewMoreAppointmentsText')}
                          <IconHelper icon={'Caret'} className={fillBase({ className: 'ml-2' })} />
                        </button>
                      )}
                  </div>
                ))}
            </div>
          </div>
          {/* Past appointments */}
          <div className={appointmentsWrapper()}>
            <div
              className={clsx(
                appointmentWrapperTitleWrapper(),
                filteredPastAppointments.length > 0 ? 'lg:flex-row' : 'lg:flex-col'
              )}
            >
              <div className={appointmentWrapperTitle()}>
                {getDictionaryValue('GroomingAppointmentPagePastAppointmentHeading')}
              </div>
              {filteredPastAppointments.length === 0 && (
                <div>{getDictionaryValue('GroomingMessageNoPastAppointments')}</div>
              )}
              {/* Dropdown for pets */}

              {isPastDropdownVisible && filteredPastAppointments.length > 0 && (
                <div className={petFilterDropDown()}>
                  <select
                    aria-label="pastPetDropdownItems"
                    value={selectedPastDogId}
                    onChange={handleChangePast}
                    className={appointmentFilter()}
                  >
                    {pastpetDropdownItems.map((pet) => (
                      <option key={pet.id} value={pet.id}>
                        {pet.name}
                      </option>
                    ))}
                  </select>
                  <IconHelper
                    icon={'Caret'}
                    className={clsx(fillBase({ className: 'ml-2' }), petFilterDropDownIcon())}
                  />
                </div>
              )}
            </div>
            <div className={appointmentItemsWrapper()}>
              {filteredPastAppointments.length > 0 &&
                filteredPastAppointments.map((group: any, groupIndex: number) => (
                  <div key={groupIndex}>
                    {Array.isArray(group.appointments) &&
                      group.appointments.length > 0 &&
                      group.appointments
                        .sort(
                          (a: any, b: any) =>
                            new Date(a.BeginAt).getTime() - new Date(b.BeginAt).getTime()
                        )
                        .slice(0, pastvisibleAppointments[groupIndex])
                        .map((appointment: any, index: number) => (
                          <>
                            <div key={index} className={appointmentItemWrapper()}>
                              <div className={petTitle()}>{appointment?.ChildName}</div>
                              <div className={petInfoWrapper()}>
                                <div className={petImg()}>
                                  <NextImage
                                    className="rounded-full h-[64px] w-[64px] object-cover"
                                    field={{
                                      value: {
                                        src: appointment?.petImage,
                                        alt: 'AddPet',
                                        width: '64',
                                        height: '64',
                                      },
                                    }}
                                  />
                                </div>
                                <div
                                  className={clsx(
                                    petAppointmentDetails(),
                                    ' mt-[12px] lg:mt-[0px]'
                                  )}
                                >
                                  <div className={petAppointmentDateInfo()}>
                                    {new Date(appointment?.BeginAt).toLocaleString('en-US', {
                                      month: 'long',
                                      day: '2-digit',
                                      year: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: true,
                                      timeZone: 'UTC',
                                    })}{' '}
                                    with {appointment?.EmployeeName}
                                    {appointment?.Services &&
                                      appointment?.Services.length > 0 &&
                                      appointment.Services.map((service: any, index: number) => (
                                        <span key={index} className={petServiceName()}>
                                          {service.Name}
                                        </span>
                                      ))}
                                  </div>
                                  <div className={petAppointmentAddress()}>
                                    {appointment?.Location?.StreetAddress
                                      ? appointment?.Location?.StreetAddress + ','
                                      : ''}
                                    {appointment?.Location?.StreetAddress2
                                      ? appointment?.Location?.StreetAddress2 + ','
                                      : ''}
                                    {appointment?.Location?.ZipCode
                                      ? appointment?.Location?.ZipCode + ','
                                      : ''}
                                    <p className="block">
                                      {appointment?.Location?.PhoneNumber ? (
                                        <a href={`tel:${appointment.Location.PhoneNumber}`}>
                                          {appointment.Location.PhoneNumber}
                                        </a>
                                      ) : (
                                        ''
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className={petAppointmentStatusCTA()}>
                                  <div className={'mt-[12px] lg:mt-[0px]'}>
                                    {scheduleAgainLoader !== null &&
                                    scheduleAgainLoader == appointment.Id ? (
                                      <div
                                        className={
                                          'items-center flex flex-row border-b-0 gap-[7px] my-[24px] text-center justify-center'
                                        }
                                      >
                                        <Loader />
                                        <span>
                                          {getDictionaryValue(
                                            'ScheduleAppointmentsStep2SelectDogInProgressText'
                                          )}
                                        </span>
                                      </div>
                                    ) : (
                                      <LinkHelper
                                        field={{
                                          value: {
                                            title: 'Schedule Again',
                                            text: 'Schedule Again',
                                            // href: '/accountmanagement/grooming-salon/schedule-appointment',
                                            href: '/',
                                          },
                                        }}
                                        className={clsx(scheduleAgain(), petAppointementCTA())}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          {
                                            if (appointment?.PetDob === null) {
                                              setAppointmentForBirthday(appointment);
                                              setShowBirthdayWarning(true);
                                            } else {
                                              router.push(
                                                '/accountmanagement/grooming-salon/schedule-appointment'
                                              );
                                            }

                                            setscheduleAgainLoader(appointment.Id);
                                            setcomponentContextData({
                                              ...componentContextData,
                                              bookedAppointmentForStep1: appointment,
                                              bookedAppointmentForStep2: appointment,
                                              bookedAppointmentForStep3: appointment,
                                              bookedAppointmentForStep4: appointment,
                                              //petsData: petsDogsData,
                                              resetStep2: false,
                                              resetStep3: false,
                                              resetStep4: false,
                                            });
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}

                    {group.appointments &&
                      group.appointments.length > minItems &&
                      pastvisibleAppointments &&
                      pastvisibleAppointments[groupIndex] < group.appointments.length && (
                        <button
                          aria-label="view more"
                          onClick={() => {
                            handlePastVisibleAppointments(groupIndex, maxItems);
                          }}
                          className={showMore()}
                        >
                          {getDictionaryValue('ScheduleAppointmentsStep4ViewMoreAppointmentsText')}
                          <IconHelper icon={'Caret'} className={fillBase({ className: 'ml-2' })} />
                        </button>
                      )}
                  </div>
                ))}
            </div>
          </div>
          {/* Birthday warning Popup */}
          {showBirthdayWarning && (
            <ModalWrapper
              showCloseButtonInModalHeader={false}
              closeModalOnOverlayClick={true}
              onCloseClick={handleModalClose}
              showModal={true}
              customPopup={true}
              popupWidth="max-w-[1200px] overflow-y-visible"
              popupSpacing="py-mob-padding-tight-y px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x"
            >
              <div className="birthday-warning-popup">
                <span className="font-heading-desk-xLarge-bold text-color-text-black text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold mb-[24px] block">
                  Date of Birth Required
                </span>
                <p className="text-body-large-bold leading-body-large-bold font-body-large-bold  text-color-text-black  mb-[20px]">
                  {getDictionaryValue('ScheduleAppointmentsStep2DogDateOfBirthMissingText').replace(
                    '{0}',
                    `${appointmentForBirthday?.ChildName}`
                  )}
                </p>
                <div className="birthday-date-picker max-w-[250px]">
                  <span className="text-body-large-bold font-body-large-bold leading-body-large-bold">
                    * Birth Date
                  </span>
                  <BirthdayDatePicker setBirthDate={setPetBirthDate} />
                </div>
                <div className="save-to-profile flex flex-row gap-[10px] my-[12px]">
                  <label htmlFor="saveToProfile" className="relative">
                    <input
                      aria-label="saveToProfile"
                      type="checkbox"
                      name="saveToProfile"
                      value="checked"
                      onChange={() => {
                        profileChecked();
                      }}
                      checked={saveToProfileChecked}
                      className={checkbox()}
                    />
                    {saveToProfileChecked && (
                      <IconHelper
                        className={checkboxIcon()}
                        icon={'icon-checkmark'}
                        onClick={() => {
                          profileChecked();
                        }}
                      />
                    )}
                  </label>
                  <span> {getDictionaryValue('ScheduleAppointmentsStep4SaveToProfileText')}</span>
                </div>
                <div className="flex flex-row gap-[12px] mt-[24px]">
                  {createUpdatePetWhileReschdulingLoader && (
                    <div className={'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'}>
                      <Loader />
                      <span>
                        {getDictionaryValue('ScheduleAppointmentsStep2SelectDogInProgressText')}
                      </span>
                    </div>
                  )}
                  {!createUpdatePetWhileReschdulingLoader && (
                    <ButtonHelper
                      field={{
                        value: 'Save and select a dog',
                      }}
                      isLinkfield={false}
                      size="medium"
                      variant={'primary'}
                      isDisbaled={!petbirthDate}
                      className={!petbirthDate ? 'opacity-50' : ''}
                      onClickHandler={async () => {
                        setcreateUpdatePetWhileReschdulingLoader(true);
                        if (saveToProfileChecked) {
                          await updatePetFromBdyPopuop();
                        }

                        await createUpdatePetWhileReschduling(appointmentForBirthday);
                      }}
                    />
                  )}

                  <ButtonHelper
                    field={{
                      value: 'Cancel',
                    }}
                    isLinkfield={false}
                    size="medium"
                    variant="secondary"
                    onClickHandler={() => {
                      handleModalClose();
                      setscheduleAgainLoader(null);
                    }}
                  />
                </div>
              </div>
            </ModalWrapper>
          )}
        </div>
      )}
    </>
  );
};

export default UpcomingAppointments;
