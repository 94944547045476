//global
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
//local
import { navigationVariants } from './GlobalHeader';
import IconHelper from 'components/helpers/IconHelper';
import { TargetItem } from 'lib/types/header';
import MobilesubNav from './MobilesubNav';
import LinkHelper from 'components/helpers/LinkHelper';
import TextHelper from 'components/helpers/TextHelper';
import { ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcSelector } from 'src/redux/ocStore';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';

//types
export type isOpen = {
  [key: string]: boolean;
};
export type MobileNavProps = {
  setIsHamburger: (val: boolean) => void;
  item: TargetItem;
  height: number;
  isActive: string;
  backText: Field<string>;
  parentIndex?: number; //for subnav
  rendering: ComponentRendering;
};

export default function MobileNav({
  item,
  isActive,
  backText,
  height,
  setIsHamburger,
}: // rendering,
// parentIndex,
MobileNavProps) {
  const {
    mobileNavOpen,
    flexGrid,
    ulWrapper,
    iconWrapper,
    navigationLink,
    mobSubWrappwer,
    fillBlack,
    iconXSmall,
    wFull,
  } = navigationVariants();
  const hasChildren = item?.childrens?.results && item?.childrens?.results.length > 0;

  const [isOpen, setIsOpen] = useState<isOpen>({});
  //show the current sub nav
  const toggleItem = (itemName: string) => {
    setIsOpen({
      ...isOpen,
      [itemName]: !isOpen[itemName],
    });
    if (itemName === '') {
      setIsOpen({});
    }
  };
  //handling sub navigation scroll
  useEffect(() => {
    const subNav = document?.getElementById('mobileNav');
    setTimeout(() => {
      subNav ? (subNav.scrollTop = 0) : '';
    }, 300);
  }, [isOpen]);

  //store data
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const myStoreServices = myStoreData?.services;
  // const storeName = myStoreData?.city + ', ' + myStoreData.state;
  const myStoreServicesArray = myStoreServices ? myStoreServices.split('|') : [];

  const { storeServices } = useGraphQlDataContext();

  const selectedService = myStoreServicesArray?.map((storeServiceId: string) =>
    storeServices?.find(
      (service) =>
        service?.serviceId?.value?.toString() === storeServiceId &&
        service?.hideInNavigation?.value !== true
    )
  );

  //Is Service Data checkbox will implement here
  const services =
    item.name?.toLowerCase() === 'services' || item.name?.toLowerCase() === 'service';

  return (
    <ul className={clsx(ulWrapper())}>
      <div
        id="subNav"
        className={flexGrid()}
        onClick={() =>
          hasChildren || services ? toggleItem(item?.link?.jsonValue?.value?.text || '') : ''
        }
      >
        {hasChildren || services ? (
          <p className={navigationLink()}>{item?.link?.jsonValue?.value?.text}</p>
        ) : (
          item?.link?.jsonValue?.value?.href &&
          item?.link?.jsonValue?.value?.href?.length > 0 && (
            <LinkHelper
              field={item?.link?.jsonValue}
              className={clsx(navigationLink(), wFull())}
              editable={false}
            />
          )
        )}
        {(hasChildren || services) && (
          <IconHelper
            icon={'chevron-right'}
            className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
            onClick={() => toggleItem(item?.link?.jsonValue?.value?.text as string)}
          />
        )}
      </div>
      <div
        id="subNav"
        style={{ height: `calc(100vh - ${height}px)` }}
        className={clsx(
          mobileNavOpen(),
          'overflow-hidden overflow-y-auto top-[64px] pb-[100px]',
          `${isOpen[item?.link?.jsonValue?.value?.text || ''] ? '!translate-x-0 !w-full' : ''} ${
            item?.link?.jsonValue?.value?.text === isActive ? '!block' : ''
          }`
        )}
      >
        <div
          id={'subNav'}
          className={clsx(flexGrid(), mobSubWrappwer())}
          onClick={() => toggleItem(item?.link?.jsonValue?.value?.text as string)}
        >
          <IconHelper
            icon={'chevron-left'}
            className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
          />
          <TextHelper tag="button" field={backText} />
        </div>
        {!services &&
          item?.childrens?.results &&
          item?.childrens?.results?.flatMap((child, index) => {
            return (
              <MobilesubNav
                key={index}
                height={height}
                subNav={child}
                isOpen={isOpen}
                setIsHamburger={setIsHamburger}
                toggleItem={toggleItem}
                backText={backText}
              />
            );
          })}
        {services &&
          selectedService?.length > 0 &&
          selectedService?.map((service, index) => {
            return (
              service?.link?.value?.href &&
              service?.link?.value?.href?.length > 0 && (
                <ul className={clsx(ulWrapper())} key={index}>
                  <LinkHelper
                    field={{
                      value: {
                        href: service?.link?.value?.href,
                        text: service?.name?.value,
                      },
                    }}
                    key={index}
                    className={clsx(navigationLink(), wFull())}
                    onClick={() => setIsHamburger(false)}
                  />
                </ul>
              )
            );
          })}

        {/* <div
          className={'px-mob-padding-tight-x'}
          data-placeholder-id={`primary-nav-mobile-${parentIndex}`}
        >
          <Placeholder name={`primary-nav-mobile-${parentIndex}`} rendering={rendering} />
        </div> */}

        {/* <div className={h250({ className: 'bg-white' })}></div> */}
      </div>
    </ul>
  );
}
