import { useOcSelector } from 'src/redux/ocStore';
import useOcCart from './useOcCart';
import { useEffect, useState } from 'react';
import { UIProductPrice, getProductPriceUI } from 'src/helpers/ProductPriceUI';
import ls from 'localstorage-slim';
import { DiscreteLineItem } from 'components/helpers/Constants';

export function useProductPriceForUI(productId: string, storeId?: string) {
  return useProductPriceListForUI([productId], storeId)?.[productId];
}

export function useProductPriceListForUI(productIds: string[], storeId?: string) {
  const [priceForUI, setPriceForUI] = useState<Record<string, UIProductPrice>>();
  const { getProductPrice } = useOcCart();
  const store = useOcSelector((state) => state.storeReducer.selectedStore);

  // Creating a string so we don't get an infinite loop of dependency change
  const productIdsDep = productIds.join('|');

  useEffect(() => {
    const fetchPrices = async () => {
      const priceLookup: Record<string, UIProductPrice> = {};

      for (let i = 0; i < productIds.length; i++) {
        const productId = productIds[i];
        if (productId == DiscreteLineItem.TIP) continue;
        const key = `product-price-${storeId ?? store.storeId}-${productId}`;
        const cachedPrice = ls.get<UIProductPrice>(key);

        if (cachedPrice) {
          priceLookup[productId] = cachedPrice;
        } else {
          try {
            const productPrice = await getProductPrice(productId, storeId ?? store.storeId);
            const priceForUI = getProductPriceUI(productPrice);
            ls.set(key, priceForUI, { ttl: 60 * 10 });
            priceLookup[productId] = priceForUI;
          } catch (error) {
            console.error(`Error fetching price for product ${productId}:`, error);
          }
        }
      }

      setPriceForUI(priceLookup);
    };

    fetchPrices();
  }, [getProductPrice, productIdsDep, storeId ?? store.storeId]);

  return priceForUI;
}
