import { Field, FormikValues, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import FieldWrapper from './FieldWrapper';
import { tv } from 'tailwind-variants';
import { FieldValues } from 'components/Registration/RegistrationForm/RegistrationForm';

type PhoneFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  subLabel?: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValues?: (val: FieldValues | any) => void;
  requiredvalidationmessage?: string;
  validationpattern?: string;
  hasFullWidth?: boolean;
  placeholdertext?: string;
  characterlimit?: string | number;
  fieldtype?: string;
  validationerrormessage?: string;
  mincharacterlimit?: string;
  mincharacterlimitvalidationmessage?: string;
  autoComplete?: string;
};

const FieldsVariant = tv(
  {
    slots: {
      fields: [
        'outline-none',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        'focus:border-color-brand-primary-1-base',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
      ],
    },
    variants: {
      device: {
        mobile: {
          fields: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
        },
        desktop: {
          fields: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
        },
      },
      hasErrorStyle: {
        true: { fields: ['border-system-red'] },
        false: { fields: ['border-color-border-mid'] },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

export const formatPhoneForDisplay = (value?: string) => {
  // Format the phone number as needed to render on UI that is like (234) 242-4344
  const phoneNumber = value?.replace(/\D/g, ''); // Remove non-numeric characters

  if (!phoneNumber?.length) {
    return value ?? ''; // Return the original value if no characters entered yet
  }

  let formattedValue = `(${phoneNumber?.slice(0, 3)}`;
  if (phoneNumber?.length > 3) {
    formattedValue += `) ${phoneNumber?.slice(3, 6)}`;
  }
  if (phoneNumber?.length > 6) {
    formattedValue += `-${phoneNumber?.slice(6, 10)}`;
  }

  return formattedValue ?? '';
};

const PhoneField: React.FC<PhoneFieldProps> = (props: PhoneFieldProps) => {
  const {
    id,
    name,
    placeholdertext,
    requiredvalidationmessage,
    required,
    characterlimit,
    validationpattern,
    fieldtype,
    validationerrormessage,
    disabled,
    setFieldValues,
    mincharacterlimitvalidationmessage,
    mincharacterlimit,
    autoComplete,
  } = props;

  const fieldValidate = (value: string) => {
    let error;
    if (required && !value) {
      error = requiredvalidationmessage || 'This field is required.'; // TODO: - Static message callback can be take from dictionary item.
    } else if (mincharacterlimit && value.length < Number(mincharacterlimit)) {
      error = mincharacterlimitvalidationmessage;
    } else if (validationpattern) {
      const regex = new RegExp(validationpattern);
      if (!regex.test(value)) {
        error = validationerrormessage;
      }
    }

    return error;
  };
  const { errors, touched, values, setFieldValue } = useFormikContext<FormikValues>();
  const hasError = name && touched[name] && errors[name] ? true : false;
  const { fields } = FieldsVariant({
    hasErrorStyle: hasError,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const handleChange = (e: { target: { value: string } }) => {
    const formattedValue = formatPhoneForDisplay(e.target?.value);
    name && setFieldValue(name, formattedValue);
  };

  useEffect(() => {
    setFieldValues && setFieldValues(values);
  }, [values]);

  useEffect(() => {
    const formattedValue = formatPhoneForDisplay(name && values[name]);
    name && setFieldValue(name, formattedValue);
  }, []);

  const handleKeyDown = (e: { key: string; preventDefault: () => void }) => {
    // Allow digits and the delete/backspace key
    const regex = new RegExp(/^\d$/);
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

    const isAllowedKey = regex.test(e.key) || allowedKeys.includes(e.key);

    if (!isAllowedKey) {
      e.preventDefault();
    }
  };

  return (
    <>
      <FieldWrapper hasError={hasError} {...props}>
        <Field
          aria-label={name}
          className={fields()}
          id={id}
          name={name}
          value={(name && values[name]) || ' '}
          placeholder={placeholdertext}
          validate={fieldValidate}
          type={fieldtype || 'text'}
          data-characterlimit={characterlimit}
          maxLength={characterlimit} // TODO: need to discuss the fallback value.
          disabled={disabled}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handleKeyDown}
          onDrop={handleKeyDown}
          autoComplete={autoComplete}
        />
      </FieldWrapper>
    </>
  );
};

export default PhoneField;
