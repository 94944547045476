import React, { useContext, useState } from 'react';
import { tv } from 'tailwind-variants';

import { StoreLocatorProps, StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import ScheduleAppointmentStep1Widget from './ScheduleAppointmentStep1';
import ScheduleAppointmentStep2 from './ScheduleAppointmentStep2';
import clsx from 'clsx';
import ScheduleAppointmentStep3 from './ScheduleAppointmentStep3';
import ScheduleAppointmentStep4 from './ScheduleAppointmentStep4';
import ComponentContext from 'lib/context/ComponentContext';
import useDictionary from 'src/hooks/useDictionary';
//import GetGroomingStoreWidget from './GetGroomingStoreWidget';

const ScheduleAppointmentTV = tv(
  {
    slots: {
      scheduleAppointmentWrapper: ['relative'],
      scheduleAppointmentTitle: [
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold text-color-text-black mb-[20px]',
      ],
      scheduleAppointementSteps: ['flex flex-col'],
      steps: [
        'py-desk-global-spacing-spacing-6 px-desk-padding-micro-x  border border-color-border-mid border-b-0 border-l-0 border-r-0 last:border-b',
      ],
    },
  },
  { responsiveVariants: ['lg'] }
);
const ScheduleAppointment = ({ fields }: StoreLocatorProps) => {
  const { scheduleAppointmentWrapper, scheduleAppointmentTitle, scheduleAppointementSteps, steps } =
    ScheduleAppointmentTV({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const [isStoreSelected, setIsStoreSelected] = useState(false); // State to track store selection
  const [selectedStoreFromChild, setselectedStoreFromChild] = useState<StoreProps | null>(null);
  const [step3Initiated, setStep3Initiated] = useState(false);
  const [step2PetData, setstep2PetData] = useState(null);
  const [petmyTimeChildID, setpetmyTimeChildID] = useState<number>();
  const [step3Data, setStep3Data] = useState(null);
  const [step4Initiated, setStep4Initiated] = useState(false);
  const [resetChildState, setResetChildState] = useState(false);
  const [step4Data, setStep4Data] = useState(null);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { getDictionaryValue } = useDictionary();
  const resetStateAndContext = () => {
    setIsStoreSelected(false);
    setselectedStoreFromChild(null);
    setStep3Initiated(false);
    setstep2PetData(null);
    setpetmyTimeChildID(undefined);
    setStep3Data(null);
    setStep4Initiated(false);
    setStep4Data(null);
    setcomponentContextData({
      ...componentContextData,
      // petsData: null,
      storeId: false,
      bookedAppointmentForStep1: null,
      bookedAppointmentForStep2: null,
      bookedAppointmentForStep3: null,
      bookedAppointmentForStep4: null,
      resetStep2: null,
      resetStep3: null,
      resetStep4: null,
      groomingStep1StoreWidget: null,
    });
    setResetChildState(true);
  };
  // Function to handle store selection flag
  const handleStoreSelected = (selected: boolean, store: StoreProps) => {
    if (store) {
      setselectedStoreFromChild(store);
      setcomponentContextData((prevData) => ({
        ...prevData,
        resetStep2: false,
      }));
    }
    setIsStoreSelected(selected);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const step2Data = (data: any) => {
    setstep2PetData(data);
  };

  const setmyTimeChildId = (myTimeChildID: number) => {
    setpetmyTimeChildID(myTimeChildID);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStep3Initiation = (data: any) => {
    setStep3Initiated(true);
    setStep3Data(data);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStep4Initiation = (data: any) => {
    setStep4Initiated(true);
    setStep4Data(data);
  };
  return (
    <div className={scheduleAppointmentWrapper()}>
      <h2 className={scheduleAppointmentTitle()}>
        {getDictionaryValue('ScheduleAppointmentTitle')}
      </h2>
      <div className={scheduleAppointementSteps()}>
        <div className={clsx(steps(), !isStoreSelected && 'bg-color-background-light')}>
          <ScheduleAppointmentStep1Widget
            resetChildState={resetChildState}
            fields={fields}
            onStoreSelected={handleStoreSelected}
          />
        </div>
        <div
          className={clsx(
            steps(),
            isStoreSelected && step3Initiated === false && 'bg-color-background-light'
          )}
        >
          <ScheduleAppointmentStep2
            fields={fields}
            storeSelected={isStoreSelected}
            onInitiateStep3={handleStep3Initiation}
            step2data={step2Data}
            setmyTimeChildid={setmyTimeChildId}
            selectedStoreFromStep1={selectedStoreFromChild}
          />
        </div>
        <div
          className={clsx(
            steps(),
            step3Initiated === true && step4Initiated === false && 'bg-color-background-light'
          )}
        >
          <ScheduleAppointmentStep3
            data={step3Data}
            onInitiateStep4={handleStep4Initiation}
            selectedStoreFromStep1={selectedStoreFromChild}
          />
        </div>
        <div
          className={clsx(
            steps(),
            'step-4-wrapper',
            step4Initiated === true && 'bg-color-background-light'
          )}
        >
          <ScheduleAppointmentStep4
            resetChildState={resetChildState}
            setResetChildState={setResetChildState}
            data={step4Data}
            resetStateAndContext={resetStateAndContext}
            selectedStoreFromStep1={selectedStoreFromChild}
            step2data={step2PetData}
            myTimeChildID={petmyTimeChildID}
          />
        </div>
      </div>
    </div>
  );
};
export default ScheduleAppointment;
