/* eslint-disable @typescript-eslint/no-explicit-any */
//global
import React, { useEffect, useState } from 'react';
import {
  Field,
  ImageField,
  LinkField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import SplideSlider from 'components/helpers/SplideSlider';
import ImageHelper from 'components/helpers/ImageHelper';
import Link from 'next/link';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import { GTM_EVENT } from 'components/helpers/Constants';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import { useRouter } from 'next/router';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
//type

export type HeroCarouselProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.HeroCarousel.Fields.HeroCarouselContainer;
export type HeroCarouselPersionlizedProps = {
  fields: {
    bannerList: {
      ctaLink: LinkField;
      mobileImage: ImageField;
      isAutoPlay: Field<boolean>;
    }[];
  };
};
//component variants
export const heroCarouselVariants = tv(
  {
    slots: {
      base: [
        'heroCarousel',
        'relative',
        '[&_.splide_.arrow-prev]:left-4',
        '[&_.splide_.arrow-prev]:right-4',
        '[&_.splide_.splide-button]:bg-transparent',
        '[&_.splide_.splide-button]:h-[25px]',
        '[&_.splide_.splide-button]:w-[25px]',
        '[&_.splide_.splide-button>svg>path]:!drop-shadow-arrows',
        '[&_.splide_.splide-button]:opacity-100',
        '[&_.splide_.splide-button>svg]:fill-color-brand-primary-1-base',
      ],
      deskImg: [''],
      mobImg: [''],
      card: [''],
      img: ['h-auto w-full'],
    },
    compoundSlots: [{ slots: ['deskImg', 'mobImg'], class: ['h-full', 'w-full'] }],
    variants: {
      size: {
        mobile: { deskImg: ['hidden'], mobImg: ['block'] },
        desktop: { deskImg: ['block'], mobImg: ['hidden'] },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

//main component
const HeroCarousel: React.FC<HeroCarouselProps> = ({ fields, params }) => {
  const { base, card, img, deskImg, mobImg } = heroCarouselVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const [viewedSlideIds, setViewedSlideIds] = useState<number[]>([]);

  const listLength = (fields?.selectCarouselItems && fields?.selectCarouselItems?.length) || 0;
  const isArrow = listLength > 1 ? true : false;
  fields?.selectCarouselItems && fields?.selectCarouselItems?.length > 1 ? true : false;
  const [visibleIndex, setVisibleIndex] = useState(0);
  const { deviceName, isDesktop } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore?.storeId) as string;
  const isEE = useExperienceEditor();
  const { isReady } = useRouter();

  const setPromotionData = (gtmEvent: string, clicked?: boolean) => {
    const gtmPromotion = [];
    const currentIndex = visibleIndex || 0;
    const visibleIndexData = fields?.selectCarouselItems?.find(
      (_, index) => currentIndex === index
    );

    const promotionObject = {
      promotion_id: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.componentName?.value),
      creative_slot: Number(visibleIndexData?.fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${visibleIndexData?.fields?.promotionDateFROM?.value} - ${visibleIndexData?.fields?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionURL?.value?.href),
    };

    if (
      visibleIndexData &&
      !viewedSlideIds?.includes(visibleIndex) &&
      myStoreId &&
      visibleIndexData?.fields?.componentName?.value !== ''
    ) {
      gtmPromotion.push(promotionObject);
      trackObjectForPromotion(gtmEvent, myStoreId, gtmPromotion);
    }

    if (clicked) {
      const data = [{ ...promotionObject }];
      trackObjectForPromotion(gtmEvent, myStoreId, data);
    }
  };

  useEffect(() => {
    // Start: Promotion GTM data push on load
    isReady && myStoreId && setPromotionData(GTM_EVENT?.viewPromotion);
    isReady &&
      myStoreId &&
      setViewedSlideIds((prev) =>
        !prev?.includes(visibleIndex) ? [...prev, visibleIndex] : [...prev]
      );
    // End: Promotion GTM data push on load
  }, [deviceName, visibleIndex, isReady, myStoreId]);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={base({ className: params?.Style ?? '' })}
      data-component={'components/HeroCarousel'}
    >
      <SplideSlider
        setVisibleIndex={setVisibleIndex}
        isAutoPlay={fields?.isAutoPlay?.value || true}
        options={{
          arrows: isArrow,
          type: isArrow ? 'loop' : '',
          interval: Number(fields?.autoPlayDuration?.value) || 3000,
          autoplay: isArrow ? fields?.isAutoPlay?.value || true : false,
          classes: {
            prev: 'arrow-prev splide-button splide__arrow--prev your-class-prev',
            next: 'arrow-next splide-button splide__arrow--next your-class-next',
          },
        }}
      >
        {fields?.selectCarouselItems?.slice(0, 10)?.map((banner, index) => {
          return (
            <div
              className={card()}
              onClick={() => {
                setPromotionData(GTM_EVENT?.selectPromotion, true);
              }}
              data-creative-id={banner?.fields?.componentName?.value}
              data-promotion-cta={banner?.fields?.cTAButtonCopy?.value}
              data-promo-id={banner?.fields?.promotionId?.value}
              data-promotion-name={banner?.fields?.promotionName?.value}
              data-promotion-copy={banner?.fields?.promotionCopy?.value}
              dta-promotion-url={banner?.fields?.promotionURL?.value?.href}
              key={index}
            >
              <div className={img()}>
                <Link aria-label="image" href={banner?.fields?.link?.value?.href || '#'}>
                  {isEE ? (
                    <>
                      <ImageHelper
                        field={banner?.fields?.desktopImage}
                        className={deskImg()}
                        priority
                      />
                      <ImageHelper
                        field={banner?.fields?.mobileImage}
                        className={mobImg()}
                        priority
                      />
                    </>
                  ) : (
                    <>
                      {isDesktop ? (
                        <ImageHelper
                          field={banner?.fields?.desktopImage}
                          className={deskImg()}
                          priority
                        />
                      ) : (
                        <ImageHelper
                          field={banner?.fields?.mobileImage}
                          className={mobImg()}
                          priority
                        />
                      )}
                    </>
                  )}
                </Link>
              </div>
            </div>
          );
        })}
      </SplideSlider>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<HeroCarouselProps>(withPersonalization(HeroCarousel));
