//global
import React, { useEffect, useState } from 'react';
// import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//hooks
import useDictionary from 'src/hooks/useDictionary';
import BVRatingSummary from 'components/BazarVoice/BVRatingSummary';
import { useTheme } from 'lib/context/ThemeContext';
//type
export interface StoreDetailTabProps {
  bvProductId?: string;
  bullets?: string[];
  ingredientsComposition?: string;
  guaranteedAnalysisNutrientName?: string;
  GuaranteedAnalysisPDP?: string;
}
//component variants
const storeDetailTabVariants = tv(
  {
    slots: {
      base: ['tabbed', 'container', 'mx-auto', 'product-details-section'],
      tabs: ['text-center', 'justify-center', 'items-center', 'bg-color-background-brand-1'],
      tabWrapper: [''],
      tabTitle: ['text-color-scale-3-light-mid'],
      description: [
        'product-details-tabs-section',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      contentWrapper: ['product-details-section-wrapper', 'border-2', 'border-color-border-light'],
      block: ['block'],
      hidden: ['hidden'],
      ul: ['flex-col'],
      whiteText: ['text-color-text-white'],
      guaranteedAnalysisPDPLabel: [
        'block',
        'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
        'mb-2',
      ],
    },
    compoundSlots: [{ slots: ['tabs', 'description', 'ul'], class: ['flex'] }],
    variants: {
      size: {
        mobile: {
          base: ['py-mob-padding-tight-y'],
          tabs: [
            'px-mob-padding-micro-x',
            'py-mob-padding-micro-y',
            'gap-mob-global-spacing-spacing-5',
          ],
          tabTitle: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
          description: [
            'px-mob-padding-tight-x',
            'py-mob-padding-tight-y',
            'flex-col',
            'gap-mob-global-spacing-spacing-13',
          ],
          tabWrapper: ['flex', 'flex-col', 'gap-mob-space-between-loose-vertical'],
          ul: ['gap-mob-margin-base-bottom'],
        },
        desktop: {
          base: ['py-desk-padding-tight-y'],
          tabs: ['px-desk-padding-loose-x', 'py-desk-padding-micro-y', 'gap-desk-padding-loose-x'],
          tabTitle: [
            'cursor-pointer',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          description: [
            'px-desk-padding-tight-x',
            'py-desk-padding-tight-y',
            'flex-row',
            'gap-desk-space-between-loose-vertical',
          ],
          tabWrapper: ['grid', 'grid-cols-2', 'gap-desk-space-between-loose-vertical'],
          ul: ['gap-desk-margin-base-bottom'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const StoreDetailTab: React.FC<StoreDetailTabProps> = ({
  bvProductId,
  guaranteedAnalysisNutrientName,
  bullets,
  ingredientsComposition,
  GuaranteedAnalysisPDP,
}) => {
  //variants for StoreDetailTab
  const {
    base,
    contentWrapper,
    tabWrapper,
    tabs,
    tabTitle,
    description,
    whiteText,
    block,
    hidden,
    ul,
    guaranteedAnalysisPDPLabel,
  } = storeDetailTabVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { themeName } = useTheme();

  //getting disctionary data for tab labels
  const { getDictionaryValue } = useDictionary();
  const [currentTab, setCurrentTab] = useState(getDictionaryValue('TabDeailsPDP'));
  //getting tab label from the dictionary and created array for individual tab
  const tabArray = [
    getDictionaryValue('TabDeailsPDP'),
    getDictionaryValue('TabIngredientsAndCompositionPDP'),
    getDictionaryValue('TabReviewsPDP'),
  ];
  useEffect(() => {
    document.body.classList.add(themeName);

    return function cleanup() {
      document.body.classList.remove(themeName);
    };
  }, []);
  return (
    <div className={base()} data-component={'components/StoreDetailTab/StoreDetailTab'}>
      <div className={contentWrapper()}>
        <div className={tabs()}>
          {tabArray?.map((tab, index) => {
            return (
              <div
                key={index}
                className={tabTitle({
                  className: tab === currentTab ? whiteText() : '',
                })}
                id={tab === getDictionaryValue('TabReviewsPDP') ? 'el-pReviews' : tab}
                onClick={() => setCurrentTab(tab)}
              >
                <p id={tab === getDictionaryValue('TabReviewsPDP') ? 'reviewSection' : ''}>{tab}</p>
              </div>
            );
          })}
        </div>
        <div className={description()}>
          <div className={currentTab === getDictionaryValue('TabDeailsPDP') ? block() : hidden()}>
            <ul className={ul()}>
              {bullets &&
                bullets?.map((detail) => {
                  return <li key={detail}>{detail}</li>;
                })}
            </ul>
          </div>
          <div
            className={
              currentTab === getDictionaryValue('TabIngredientsAndCompositionPDP')
                ? tabWrapper()
                : hidden()
            }
          >
            <div>{ingredientsComposition}</div>
            <div>
              <span className={guaranteedAnalysisPDPLabel()}>{GuaranteedAnalysisPDP}</span>
              <p>{guaranteedAnalysisNutrientName}</p>
            </div>
          </div>
          <div
            id="pReview"
            className={`bazaar-voice-pdp ${
              currentTab === getDictionaryValue('TabReviewsPDP') ? block() : hidden()
            }`}
          >
            {bvProductId && <BVRatingSummary bvProductId={bvProductId} />}
          </div>
        </div>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default StoreDetailTab;
