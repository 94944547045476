import { NextImage, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import ModalWrapper from 'components/helpers/ModalWrapper';
import AutoCompleteWrapper from 'components/helpers/Form/AutoCompleteWrapper';
import IconHelper from 'components/helpers/IconHelper';
import GetGroomingStoreWidget from './GetGroomingStoreWidget';
import useDictionary from 'src/hooks/useDictionary';
import { StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import LinkHelper from 'components/helpers/LinkHelper';
import { ChangeStoreVariants } from 'components/ProductDetail/ChangeStorePopup/ChangeStoreVariants';
import clsx from 'clsx';

import { Cookies } from 'react-cookie';
import ComponentContext from 'lib/context/ComponentContext';
import { groomingStepsData } from 'src/utils/sendGTMEvent';
import { GTM_EVENT, GTMLabels, hardErrorIcon } from 'components/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';

const ScheduleAppointmentStep1TV = tv(
  {
    slots: {
      base: ['storeLocatorGlobalPopup'],
      loaderWrapper: 'flex',
      loadingText: 'font-primary',
      noSearchResultsFoundText:
        'text-body-small-regular font-body-small-regular leading-body-small-regular text-system-red mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom',
      loadMoreButton: 'w-full flex justify-center',
      modalWrapper: '',
      loadMore:
        'flex group underline h-fit w-fit font-primary text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      headingWrapper: 'flex flex-col',
      input:
        'w-full h-[56px] text-color-text-dark placeholder-color-text-dark px-desk-padding-micro-x py-desk-padding-micro-y font-primary text-body-large-regular leading-body-large-regular font-body-large-regular focus:outline-none border-[1px] border-color-border-mid mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom',
      heading: 'font-primary',
      subHeading:
        'font-primary text-body-large-regular leading-body-large-regular font-body-large-regular',
      formHeading: 'font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      searchIcon: 'w-[40px] h-[40px] [&>svg]:w-[40px] [&>svg]:h-[40px]',
      storePageArrow:
        'ml-1 [&>svg>*]:stroke-color-text-brand-1 [&>svg>path]:fill-color-text-brand-1 h-[16px] w-[16px] [&_svg]:max-w-[16px] [&_svg]:max-h-[16px] group-hover:[&>svg>*]:stroke-color-brand-tertiary-3-base group-focus:[&>svg>path]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:stroke-color-brand-tertiary-3-base group-hover:[&>svg>path]:fill-color-brand-tertiary-3-base',
      searchButton:
        'bg-color-background-brand-1 flex justify-center items-center w-[72px] h-[56px] mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom absolute right-0 top-0 hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      step1HeadingWrapper: ['flex flex-row justify-between'],
      step1HeadingTitle: [
        'font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold text-color-text-black',
      ],
      step1HeadingLink: [
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline',
        'cursor-pointer',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          loadingText:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          loaderWrapper:
            'gap-desk-component-button-full-space-between py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x mt-desk-margin-base-top',
          headingWrapper: 'gap-desk-space-between-tight-vertical',
          modalWrapper: 'py-desk-padding-tight-y px-desk-padding-base-x',
          loadMore:
            'my-desk-margin-tight-top text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold',
          heading:
            'font-heading-desk-xLarge-bold text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
        },
        mobile: {
          loadingText:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          loaderWrapper:
            'py-mob-component-button-full-padding-y px-mob-component-button-full-padding-x mt-mob-margin-base-top gap-mob-component-button-full-space-between',

          modalWrapper: 'py-mob-padding-tight-y px-mob-padding-base-x',
          headingWrapper: 'gap-mob-space-between-tight-vertical',
          loadMore:
            'my-mob-margin-tight-top text-heading-mob-medium-bold leading-heading-mob-medium-bold font-heading-mob-medium-bold',
          heading:
            'font-heading-mob-xLarge-bold text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
        },
      },
      isError: {
        true: {
          input: 'border-system-red pointers-none',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

const { storeListRow } = ChangeStoreVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const ScheduleAppointmentStep1 = ({
  fields,
  onStoreSelected,
  resetChildState,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any;
  onStoreSelected: (selected: boolean, store: StoreProps) => void;
  autoBook?: boolean;
  resetChildState: boolean;
}) => {
  const {
    modalWrapper,
    headingWrapper,
    heading,
    subHeading,
    input,
    searchButton,
    searchIcon,
    step1HeadingWrapper,
    step1HeadingTitle,
    step1HeadingLink,
  } = ScheduleAppointmentStep1TV({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { storeDetail, storeAddressWrapper, storeAddress } = ChangeStoreVariants({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const modalRef = useRef<HTMLDivElement>(null);
  const [isGroomingSearchLocatorOpen, setGroomingSearchLocatorOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [isErrorInStoreData, setIsErrorInStoreData] = useState<boolean>(false);
  const [groomingStoreLat, setgroomingStoreLat] = useState<number>(0);
  const [groomingStoreLong, setgroomingStoreLong] = useState<number>(0);
  const [, setShowGroomingStoreWidget] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<StoreProps | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, setSelectedSuggestions] = useState<any>({ Keywords: '' });
  const [zipCodeError, setZipCodeError] = useState(false);
  const [, setStores] = useState<StoreProps[]>([]);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [hasstep1gtmrun, setHasStep1GtmRun] = useState(false);
  const circleRadius = fields?.circleRadius?.value ? +fields?.circleRadius?.value : 0;
  const cookies = new Cookies();

  const [coordinates, setCoordinates] = useState({
    lat: cookies.get('MyLatitude') || fields?.defaultLatitude?.value || 0,
    lng: cookies.get('MyLongitude') || fields?.defaultLongitude?.value || 0,
  });
  useEffect(() => {
    if (resetChildState) {
      setSelectedStore(null);
      setAddress('');
      setGroomingSearchLocatorOpen(false);
      setIsErrorInStoreData(false);
      setgroomingStoreLat(0);
      setgroomingStoreLong(0);
      setShowGroomingStoreWidget(false);
      setHasStep1GtmRun(false);
    }
  }, [resetChildState]);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { getDictionaryValue } = useDictionary();
  const handleStoreSelection = (store: StoreProps) => {
    setSelectedStore(store);
    setGroomingSearchLocatorOpen(false);
    onStoreSelected(true, store);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const closeGroomingSearchLocator = () => {
    setcomponentContextData({
      ...componentContextData,
      groomingStep1StoreWidget: false,
    });
    setGroomingSearchLocatorOpen(false);
  };

  const openGroomingSearchLocator = () => {
    if (selectedStore) {
      setcomponentContextData({
        ...componentContextData,
        resetStep2: true,
        resetStep3: true,
        resetStep4: true,
        petsData: null,
        bookedAppointmentForStep1: null,
        bookedAppointmentForStep2: null,
        bookedAppointmentForStep3: null,
        bookedAppointmentForStep4: null,
        groomingStep1StoreWidget: null,
      });
    }
    setGroomingSearchLocatorOpen(true);
  };

  useEffect(() => {
    if (hasstep1gtmrun === false && componentContextData?.bookedAppointmentForStep1) {
      groomingStepsData({
        eventName: GTM_EVENT?.groomingSteps,
        storeId: myStoreData?.storeId,
        groomingStoreId: componentContextData?.bookedAppointmentForStep1?.Location?.PspStoreId,
        stepName: GTMLabels?.groomingStep1StepName,
      });
      setHasStep1GtmRun(true);
    }
  }, []);

  useEffect(() => {
    if (componentContextData?.bookedAppointmentForStep1) {
      setSelectedStore(componentContextData?.bookedAppointmentForStep1);
    }
  }, [componentContextData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = async (value: any) => {
    if (value && value?.isError) {
      setIsErrorInStoreData(true);
    }
    setSelectedSuggestions({
      Keywords: value?.keyword,
    });
    if (!(coordinates?.lat === value.lat && coordinates?.lng === value.lng)) {
      setStores([]);
    }
    if (coordinates?.lat === value.lat && coordinates?.lng === value.lng) {
    } else {
      setCoordinates({ lat: value.lat, lng: value.lng });
    }

    setgroomingStoreLat(value?.lat);
    setgroomingStoreLong(value?.lng);
    setShowGroomingStoreWidget(true);
    setcomponentContextData({
      ...componentContextData,
      groomingStep1StoreWidget: true,
    });
  };

  const options = {
    types: ['(regions)'],
    componentRestrictions: {
      country: fields?.country?.value,
    },
    bounds: {
      //This calculates the radius and this will be in static form only
      east: coordinates.lng + (circleRadius / 111000) * Math.cos(coordinates.lat * (Math.PI / 180)),
      west: coordinates.lng - (circleRadius / 111000) * Math.cos(coordinates.lat * (Math.PI / 180)),
      north: coordinates.lat + circleRadius / 111000,
      south: coordinates.lat - circleRadius / 111000,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (e: any) => {
    const value = e.target?.value;
    const numericValue = value.replace(/\D/g, '');
    setAddress(numericValue);

    if (numericValue.length === 5) {
      setIsErrorInStoreData(false);
    } else if (numericValue?.length > 0) {
      setZipCodeError(false);
    } else if (numericValue?.length === 0) {
      setZipCodeError(true);
    } else {
      setIsErrorInStoreData(true);
    }
  };

  const ZipCodeRequired = () => {
    return (
      <div
        className={clsx(storeListRow(), 'lg:flex-row border-b-0 lg:pl-0 lg:pr-0 flex !gap-[12px]')}
      >
        <NextImage
          field={{
            value: {
              src: hardErrorIcon?.src,
              alt: 'hard-error-icon',
              width: '17',
              height: '17',
              class: 'w-[17px] h-[17px]',
            },
          }}
        />
        <p
          className={clsx(
            'text-color-accent-red',
            'text-body-large-bold',
            'font-body-large-bold',
            'leading-body-large-bold'
          )}
        >
          {'The Zip Code field is required'}
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className={step1HeadingWrapper()}>
        <p className={step1HeadingTitle()}>
          {getDictionaryValue('ScheduleAppointmentStep1Heading')}
        </p>
        <p
          className={step1HeadingLink()}
          onClick={() => {
            openGroomingSearchLocator();
          }}
        >
          {selectedStore
            ? getDictionaryValue('ScheduleAppointmentStep1EditLinkText')
            : getDictionaryValue('ScheduleAppointmentStep1SelectStoreLinkText')}
        </p>
      </div>
      {selectedStore && (
        <div className={storeDetail()}>
          <div className={storeAddressWrapper()}>
            {/* <div className={clsx(storeName(), 'mb-[25px]')}>
              {selectedStore.firstname || selectedStore?.Location?.City}
            </div> */}
            <Text
              className={
                (storeAddress(),
                'ml-0 !font-body-large-regular-regular_underline !text-body-large-regular-regular_underline !leading-body-large-regular-regular_underline !text-color-text-brand-1 underline')
              }
              tag="p"
              field={{ value: selectedStore?.street1 || selectedStore?.Location?.StreetAddress }}
            />
            <Text
              className={
                (storeAddress(),
                'ml-0 !font-body-large-regular-regular_underline !text-body-large-regular-regular_underline !leading-body-large-regular-regular_underline !text-color-text-brand-1 underline')
              }
              tag="p"
              field={{
                value: `${
                  selectedStore.city ? selectedStore.city : selectedStore?.Location?.City
                }, ${selectedStore.state ? selectedStore.state : selectedStore?.Location?.State} ${
                  selectedStore?.zip ? selectedStore?.zip : selectedStore?.Location?.ZipCode
                }`,
              }}
            />

            <LinkHelper
              className={clsx(
                storeAddress(),
                '!ml-0  !font-body-large-regular-regular_underline !text-body-large-regular-regular_underline !leading-body-large-regular-regular_underline !text-color-text-brand-1 underline'
              )}
              field={{
                value: {
                  text: selectedStore.phone
                    ? selectedStore.phone
                    : selectedStore?.Location?.PhoneNumber,
                  href: selectedStore.phone
                    ? `tel:${selectedStore.phone}`
                    : `tel:${selectedStore?.Location?.PhoneNumber}`,
                },
              }}
            />
          </div>
        </div>
      )}
      {isGroomingSearchLocatorOpen && (
        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={closeGroomingSearchLocator}
          showModal={isGroomingSearchLocatorOpen}
          customPopup={true}
          popupWidth="max-w-[997px]"
          popupSpacing="p-0"
        >
          <div ref={modalRef} className={modalWrapper()}>
            <div className={headingWrapper()}>
              <div>
                <Text tag="p" className={heading()} field={fields?.headerText} />
                <RichText tag="p" className={subHeading()} field={fields?.requiredText} />
              </div>
              <div>
                {/* {!(selectedSuggestions?.Keywords?.length > 0 && stores?.length > 0) && (
                  <Text tag="p" className={formHeading()} field={fields?.searchboxText} />
                )} */}

                <AutoCompleteWrapper
                  options={options}
                  hideSuggestions={handleInputChange}
                  onHandleChange={handleSelect}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {({ onAutoSuggestionSubmit }: any) => {
                    return (
                      <form className="relative">
                        <input
                          aria-label="google-auto-suggestion-address"
                          name="google-auto-suggestion-address"
                          id="google-auto-suggestion-address"
                          value={address}
                          placeholder={
                            '*' + getDictionaryValue('ScheduleAppointmentStep1ZipCodeLabel')
                          }
                          className={input({ isError: isErrorInStoreData })}
                          onChange={handleInputChange}
                          maxLength={5}
                          pattern="\d{5}"
                        />
                        <button
                          aria-label="search"
                          onClick={async (e) => {
                            e.preventDefault();
                            const inputElement = document.getElementById(
                              'google-auto-suggestion-address'
                            ) as HTMLInputElement;
                            if (inputElement) {
                              const inputValue = inputElement?.value;
                              if (inputValue) {
                                setAddress(inputValue);
                                setIsErrorInStoreData(false);
                                handleSelect(await onAutoSuggestionSubmit(inputValue));
                              } else {
                                setZipCodeError(true);
                              }
                            }
                          }}
                          className={searchButton()}
                        >
                          <IconHelper className={searchIcon()} icon={'search'} />
                        </button>
                      </form>
                    );
                  }}
                </AutoCompleteWrapper>
              </div>
            </div>
            {componentContextData?.groomingStep1StoreWidget && (
              <GetGroomingStoreWidget
                rfkId="rfk_Store_Locator_Geo_Location"
                coordinates={{
                  lat: groomingStoreLat,
                  lng: groomingStoreLong,
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                fields={fields as any}
                location={address}
                onStoreSelect={handleStoreSelection}
                iszipcodeerror={zipCodeError}
              />
            )}
            {zipCodeError && <ZipCodeRequired />}
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};
// const ScheduleAppointmentStep1Widget = widget(
//   ScheduleAppointmentStep1,
//   WidgetDataType.SEARCH_RESULTS,
//   'store'
// );
export default ScheduleAppointmentStep1;
