import ImageHelper from 'components/helpers/ImageHelper';
import { ProductSearchResultModelWithVariants } from './types';
import useOcCart from 'src/hooks/useOcCart';
import useDictionary from 'src/hooks/useDictionary';
import { ProductPrice } from 'src/helpers/search/SearchResults/products/ProductPrice';
import { ProductLink } from './products/ProductLink';
import { RecommendationWidget, SearchResultsWidget } from '@sitecore-search/react/dist/esm/types';
import { ProductAutoship } from './products/ProductAutoship';
import { ProductInventoryMessage } from './products/ProductInventoryMessage';
import { ProductMoreOptions } from './products/ProductMoreOptions';
import { ProductMadeInUsa } from './products/ProductMadeInUsa';
import clsx from 'clsx';

import searchResultsTailwind from './SearchResultsTailwind';
import IconHelper from 'components/helpers/IconHelper';
import { pDPComponentVariants } from 'components/ProductDetail/ProductDetail';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import BVPLPRating from 'components/BazarVoice/BVPLPRating';
import { useContext, useEffect, useState } from 'react';
import ComponentContext from 'lib/context/ComponentContext';
import { getProductPriceFromSearch } from 'src/helpers/ProductPriceUI';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductPromoTag,
  getTruncatedProductPromoTag,
} from 'components/helpers/Constants';
import { useProductInventory } from './use-product-inventory';
import { myFavoriteVariants } from 'components/MyFavorite/myFavoriteVariants';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import { trackAddEvent } from '@sitecore-search/react';

export interface PspProductRootProps {
  actions: SearchResultsWidget['ActionProps'] | RecommendationWidget['ActionProps'];
  product: ProductSearchResultModelWithVariants;
  index: number;
  currentPage?: string;
  styles?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event?: any) => void;
}

export const PspProductRoot = ({ product, index, actions, onClick }: PspProductRootProps) => {
  const { addToCart } = useOcCart();
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const image = getFirstProductImage(product?.xp?.Images);
  const productPriceForPLP = getProductPriceFromSearch(product);
  const {
    sitecoreProductRoot,
    cardWrapper,
    productInfoWrapper,
    sitecoreProductName,
    submitBtn,
    productBottomWrapper,
    productImage,
    productTopWrapper,
    sitecoreImageWrapper,
    productStockPriceInfo,
    sitecoreImage,
  } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { addToFavoritesWrapper, addToFavoriteIcon } = pDPComponentVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = pDPComponentVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useOcDispatch();
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const wishlistData = useOcSelector((s) => s.wishlist);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [isPickUpSelected, setIsPickUpSelected] = useState<boolean>();
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean | string>();

  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );
  const { saleLabel, offerLabel } = myFavoriteVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const inventoryStatus = useProductInventory(product);

  // getting current page from GTM local Storage
  const { currentPage, pageItem } = getGTMSessionStorage();

  // function to call upon addToFavourite button:
  const addToFavoriteClick = (product: ProductSearchResultModelWithVariants, index: number) => {
    const productId = product?.id;
    if (productId) {
      if (isAnonymous) {
        setcomponentContextData({
          ...componentContextData,
          ToggleLoginOverlayForm: true,
          productToAddInWishlistAfterLogin: product,
          productIdToAddInWishlistAfterLogin: productId,
          clickFrom: currentPage === 'PLP Page' ? GTMLabels?.PLP : currentPage,
        });
      } else {
        dispatch(
          updateProductInWishlist({
            product: product,
            index: index,
            storeId: myStoreData?.storeId,
            clickFrom: currentPage === 'PLP Page' ? GTMLabels?.PLP : currentPage,
            currentPage: currentPage,
            pageItem: pageItem,
            wishlistData: wishlistData,
          })
        );
      }
    }
  };
  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);

  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod;
    setIsPickUpSelected(newMethod === FulfillmentType.DFS);
  }, [fulfillmentMethod, myStoreData?.storeId, componentContextData?.isDFSMethodChanged]);

  const ecommerceDisabled = myStoreData?.ecommerceDisabled;

  const addToCartDisabled = ecommerceDisabled === 1 || inventoryStatus === 'OutOfStock';
  const promoTag =
    product?.xp?.PromoTag &&
    getProductPromoTag(product?.xp?.PromoTag, myStoreData?.dataareaid, myStoreData.priceGroupArray);
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;

  return (
    <div key={product.id} className={clsx(cardWrapper())}>
      {showOfferTooltip == product.id && (
        <div
          className={topTooltipWrapper({
            className: '!bottom-[85%] !left-1/2 -translate-x-1/2',
          })}
        >
          <div className={tooltipContainer()}>
            <div className={topTooltipArrowFirstDiv()}></div>
            <div className={topTooltipArrowSecondDiv()}></div>
            <p className={topToolTipDescription({ className: 'text-center' })}>{promoTag}</p>
          </div>
        </div>
      )}
      <div
        className={clsx(sitecoreProductRoot({ className: '!border-0 !p-0' }))}
        onClick={() => {
          window && window?.localStorage?.setItem(GTMLabels?.position, index?.toString());
          sendProductsPromotion({
            eventName: GTM_EVENT?.selectItem,
            data: product as ProductSearchResultModelWithVariants,
            currentPage: currentPage,
            pageItem: pageItem,
            position: index,
            isRatingShow: true,
          });
        }}
      >
        <div className={clsx(productTopWrapper({ className: 'relative' }))}>
          <ProductMadeInUsa product={product} />
          {/* On Sale Tag: */}
          {product?.xp?.OnSale && (
            <span className={saleLabel({ className: 'absolute top-[150%]' })}>
              {getDictionaryValue('Sale')}
            </span>
          )}
          {/* <span>[Add to Favorite Icon]</span> */}
          <button
            aria-label="add to favorites"
            className={addToFavoritesWrapper()}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event?.stopPropagation();
              addToFavoriteClick(product || '', index);
            }}
          >
            <IconHelper
              className={addToFavoriteIcon({
                className: '[&>svg]:!h-[16px]',
              })}
              icon={
                !isAnonymous &&
                wishlistData?.wishlist &&
                isProductInWishlist(wishlistData?.wishlist, product.id || '')
                  ? 'heart'
                  : 'heart-stroke'
              }
            />
          </button>
        </div>
        {/* <div className={styles['sitecore-image-wrapper']}> */}
        <div className={sitecoreImage()}>
          <div className={sitecoreImageWrapper()} onClick={onClick}>
            <ProductLink {...{ actions, product, index }}>
              <ImageHelper
                className={productImage()}
                field={{
                  value: {
                    src: image?.Url || dummyProductImage?.jsonValue?.value?.src,
                    alt: product?.name || dummyProductImage?.jsonValue?.value?.alt,
                    width: 140,
                    height: 140,
                  },
                }}
              />
            </ProductLink>
          </div>
          {promoTag && (
            <div
              className={offerLabel()}
              onMouseEnter={() => {
                if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                  setShowOfferTooltip(product.id);
                }
              }}
              onMouseLeave={() => {
                if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                  setShowOfferTooltip(false);
                }
              }}
            >
              {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
            </div>
          )}
        </div>
        {product?.xp?.RetailUnit && (
          <div className="product-weight-container relative">
            <p className="absolute pr-3 pl-1 bottom-[100px] right-[0px] text-left bg-color-brand-primary-1-base text-color-text-white product-weight-clip text-body-small-bold font-body-text-bold leading-body-small-bold">
              {product?.xp?.RetailUnit} {product?.xp?.RetailMeasure}
            </p>
          </div>
        )}
        {/* Product Tile Info */}
        <h2
          className={sitecoreProductName()}
          onClick={(event: React.MouseEvent<HTMLHeadingElement>) => {
            event?.stopPropagation();
            window && window?.localStorage?.setItem(GTMLabels?.position, index?.toString());
            sendProductsPromotion({
              eventName: GTM_EVENT?.selectItem,
              data: product as ProductSearchResultModelWithVariants,
              currentPage: currentPage,
              pageItem: pageItem,
              position: index,
              isRatingShow: true,
            });
          }}
        >
          <ProductLink {...{ actions, product, index }}>{product.name}</ProductLink>
        </h2>
      </div>
      <div className={productInfoWrapper()}>
        {/* Product Tile Middle  */}
        <div className={productStockPriceInfo()}>
          <div className={'flex h-[80px] items-center flex-col justify-between w-full'}>
            <ProductInventoryMessage product={product} />
            <ProductMoreOptions {...{ actions, product, index }} />
          </div>
          <ProductPrice productPrice={productPriceForPLP} />
        </div>
        {/* Product Tile Bottom */}
        <div className={productBottomWrapper()}>
          <ProductAutoship product={product} />
          <div>
            <button
              aria-label="add to cart"
              disabled={addToCartDisabled as boolean}
              onClick={async () => {
                await addToCart({
                  productId: product.id as string,
                  quantity: 1,
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                sendProductsPromotion({
                  eventName: GTM_EVENT?.addToCart,
                  data: product as ProductSearchResultModelWithVariants,
                  isRatingShow: true,
                  currentPage: currentPage,
                  pageItem: pageItem,
                  position: index,
                  click_from: currentPage === 'PLP Page' ? GTMLabels?.PLP : currentPage,
                  storeId: myStoreData?.storeId,
                  fulfillment_option: isPickUpSelected ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                });

                //Sending Add Cart event to Search
                trackAddEvent('product', 'cart', {
                  items: [{ entityType: 'product', id: product.id as string }],
                  actionSubtype: 'conversion',
                });
              }}
              className={clsx(
                submitBtn({ outlineCta: false }),
                'font-heading-desk-medium-bold',
                'text-heading-desk-medium-bold',
                'leading-heading-desk-medium-bold'
              )}
            >
              {productPriceForPLP?.hasImapPrice || productPriceForPLP?.showViewPriceInCart
                ? getDictionaryValue('ViewPriceInCartPDP')
                : getDictionaryValue('AddToCartCTAPDP')}
            </button>
          </div>
          {/* Ratings to be placed here */}
          <BVPLPRating bvProductId={product?.xp?.UPC} bvRedirectUrl={product?.product_url || ''} />
        </div>
      </div>
    </div>
  );
};
