import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';

const publicURL = publicURLTrailingSlash(); // Add this for XMC Pages.

export const loyaltyApi = {
  acceptLoyaltyProgramAgreementEndpoint: () =>
    publicURL + 'api/loyalty/accept-loyalty-program-agreement',
};

export const petsApi = {
  allPets: publicURL + 'api/pets/all-pets',
  getGender: publicURL + 'api/pets/genders',
  getSpecies: publicURL + 'api/pets/species',
  getBreed: publicURL + 'api/pets/breeds',
  addPet: publicURL + 'api/pets/add-pet',
  getPet: publicURL + 'api/pets/get-pet',
  updatePet: publicURL + 'api/pets/update-pet',
  deletePets: publicURL + 'api/pets/delete-pet',
  addPetImage: publicURL + 'api/pets/add-pet-image',
};

export const groomingApi = {
  addNewBreedAndAssignToPet: publicURL + 'api/grooming/add-new-breed-and-assign-to-pet',
  createUpdatePet: publicURL + 'api/grooming/create-update-pet',
  groomingServices: publicURL + 'api/grooming/grooming-services',
  nextAvailableTimeslots: publicURL + 'api/grooming/next-available-timeslots',
  timeslotsByDateRangeAndGroomer: publicURL + 'api/grooming/timeslots-by-date-range-and-groomer',
  getMoreAvailableTimeSlots: publicURL + 'api/grooming/get-more-available-time-slots',
  scheduleAppointment: publicURL + 'api/grooming/schedule-appointment',
  availableGroomers: publicURL + 'api/grooming/available-groomers',
  appointments: publicURL + 'api/grooming/appointments',
  cancelAppointment: publicURL + 'api/grooming/cancel-appointment',
};

export const orderPromotionApi = {
  addPromotion: publicURL + 'api/promotion/add-promotion',
};
