import React, { useEffect } from 'react';
import { tv } from 'tailwind-variants';
import {
  Field,
  NextImage,
  RichText,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'components/helpers/LinkHelper';
import { useTheme } from 'lib/context/ThemeContext';
import {
  HideLinkFromStoreNavigation,
  ShowLinkFromStoreNavigation,
} from 'src/helpers/StoreLandingHelper';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import { StoreService } from 'lib/page-props-factory/graphql/store-services';
import { PSP } from 'models/PetSuppliesPlus.Model';

export const StoreDetailVariants = tv(
  {
    slots: {
      base: 'container',
      linkWrapper: 'w-fit',
      link: 'w-fit font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      headingWrapper: '',
      serviceTitle:
        'font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
      serviceWrapper: 'flex flex-col',
      servicesWrapper: 'bg-color-background-light',
      serviceDetailWrapper: 'flex flex-col',
      serviceDescription:
        'font-primary text-body-large-regular leading-body-large-regular font-body-large-regular',
      heading:
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold font-primary',
    },
    variants: {
      size: {
        desktop: {
          serviceDetailWrapper: 'gap-desk-margin-tight-bottom pb-mob-margin-micro-bottom',
          serviceWrapper: 'px-desk-padding-micro-x gap-desk-margin-tight-bottom',
          servicesWrapper:
            'grid grid-cols-3  gap-x-6 py-desk-padding-tight-y px-desk-padding-tight-x gap-y-desk-margin-loose-bottom gap-x-6',
          headingWrapper:
            'bg-color-background-light pt-desk-padding-tight-y px-desk-global-grid-margin',
          heading: 'px-0',
        },
        mobile: {
          serviceWrapper: 'pl-mob-padding-micro-x w-full gap-mob-margin-tight-bottom',
          serviceDetailWrapper: 'gap-mob-margin-tight-bottom pb-mob-margin-micro-bottom',
          servicesWrapper:
            'flex flex-col py-mob-padding-tight-y px-mob-global-grid-margin gap-mob-margin-loose-bottom',
          headingWrapper: 'pt-mob-padding-tight-y',
          heading: 'px-mob-global-grid-margin',
        },
      },
      theme: {
        psp: {
          servicesWrapper:
            '[&_img]:rounded-mob-global-radius-pill lg:[&_img]:rounded-desk-global-radius-pill',
        },
        wnw: {},
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);

const getSelectedService = (index: string, servicesData: StoreService[]) => {
  return servicesData?.find((service) => service?.serviceId?.value.toString() === index);
};
const RenderServices = ({
  services,
  servicesData,
}: {
  services: string[];
  servicesData: StoreService[];
}) => {
  const { themeName } = useTheme();
  const {
    servicesWrapper,
    serviceWrapper,
    serviceTitle,
    serviceDetailWrapper,
    serviceDescription,
    link,
    linkWrapper,
  } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    theme: themeName,
  });
  return (
    <div className={servicesWrapper()}>
      {services?.length > 0 &&
        services?.map((serviceIndex, index) => {
          const data = getSelectedService(serviceIndex, servicesData);
          return (
            <div className={serviceWrapper()} key={index}>
              {data?.link?.value?.href ? (
                <LinkHelper
                  field={{
                    value: {
                      href: data?.link?.value?.href,
                    },
                  }}
                  className={linkWrapper()}
                >
                  <NextImage field={data?.icon} />
                </LinkHelper>
              ) : (
                <NextImage field={data?.icon} />
              )}
              <div className={serviceDetailWrapper()}>
                <Text tag="p" className={serviceTitle()} field={data?.name} />
                <RichText tag="p" className={serviceDescription()} field={data?.description} />
              </div>
              <LinkHelper className={link()} field={data?.link} />
            </div>
          );
        })}
    </div>
  );
};
const Services = ({
  fields,
}: {
  fields: {
    data: {
      datasource: {
        servicesText: Field<string>;
      };
      storeServices: {
        services: Field<string>;
      };
    };
  };
}) => {
  const { storeServices } = useGraphQlDataContext();

  const { base, headingWrapper, heading } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const storeDetails: PSP.Sitecore.templates.PetSuppliesPlus.PageTypes.Fields.StoreDetail['fields'] =
    useSitecoreContext()?.sitecoreContext?.route?.fields;

  useEffect(() => {
    if (
      !(
        storeDetails?.services?.value &&
        storeDetails?.services?.value?.split('|') &&
        storeDetails?.services?.value?.split('|')?.length > 0 &&
        storeServices?.length
      )
    ) {
      HideLinkFromStoreNavigation('services');
    } else {
      ShowLinkFromStoreNavigation('services');
    }
  }, [storeDetails, storeServices]);
  return (
    <>
      {storeDetails?.services?.value &&
        storeDetails?.services?.value?.split('|') &&
        storeDetails?.services?.value?.split('|')?.length > 0 &&
        storeServices?.length && (
          <div className={base()} id="services">
            <div className={headingWrapper()}>
              <Text tag="p" className={heading()} field={fields?.data?.datasource?.servicesText} />
            </div>
            <RenderServices
              services={storeDetails?.services?.value?.split('|') || []}
              servicesData={storeServices}
            />
          </div>
        )}
    </>
  );
};

export default Services;
