import { EntityModel, useRecommendation, widget, WidgetDataType } from '@sitecore-search/react';
import IconHelper from 'components/helpers/IconHelper';
import Link from 'next/link';
import { tv } from 'tailwind-variants';
// import { RFKID } from '@sitecore-search/react';

// function addStyle(rfkId: RFKID, node: HTMLElement, css: string) {
//   let styleNode = document.querySelector<HTMLElement>(`[data-rfk-id-style=${rfkId}]`);

//   if (!styleNode) {
//     styleNode = document.createElement('style');
//     styleNode.setAttribute('type', 'text/css');
//     styleNode.dataset.rfkIdStyle = rfkId;
//     node.parentNode?.appendChild(styleNode);
//   }
//   styleNode.innerHTML = css;
// }
export type AlertPromoData = {
  data: {
    linkUrl: string;
    linkText: string;
    backgroundColor: string;
  };
};
const alertPromoVariant = tv(
  {
    slots: {
      base: ['alertPromo', 'relative'],
      linkTitle: ['hover:no-underline'],
      innerDiv: ['container', 'mx-auto', 'flex', 'justify-center', 'gap-2', 'items-center'],
      closeIcon: ['[&>svg>*>*]:fill-color-scale-8-dark [&>svg>*]:fill-color-scale-8-dark'],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      device: {
        mobile: {
          base: ['py-mob-padding-micro-y'],
          linkTitle: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
        },
        desktop: {
          base: ['py-mob-padding-micro-y'],
          linkTitle: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          closeIcon: ['absolute', 'right-4'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
const ContentBlockWidget = () => {
  //alertpromo variant
  const { base, innerDiv, linkTitle, closeIcon } = alertPromoVariant({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  //fetching alertpromo data
  const {
    queryResult: {
      isLoading,
      data: {
        appearance: {
          templates: {
            html: { devices: { pc: { jsondata: jsonContent = null } = {} } = {} } = {},
          } = {},
        } = {},
      } = {},
    },
  }: EntityModel = useRecommendation();
  const data: AlertPromoData = JSON.parse(jsonContent);
  // useEffect(() => {
  //   setData(JSON.parse(jsonContent));
  //   getType(data);
  // }, []);

  // const { ref } = useWidgetVisibility();
  // const contentRef = useRef(null);
  // // sets widget styles
  // useEffect(() => {
  //   if (contentRef.current && cssContent) {
  //     addStyle(rfkId, contentRef.current, cssContent);
  //   }
  // }, [rfkId, cssContent]);

  // sets widget content
  // useEffect(() => {
  //   if (contentRef.current && htmlContent) {
  //     (contentRef.current as HTMLElement).innerHTML = htmlContent;
  //   }
  // }, [htmlContent]);
  // return createElement(
  //   'div',
  //   { ref, 'data-rfkid': rfkId, className: cssNames.join(' ') },
  //   createElement('div', { ref: contentRef })
  // );
  // useEffect(() => {
  //   use;
  // // });
  return (
    data && (
      <div id={'alertPromo'} className={base()}>
        {!isLoading && (
          <div className={innerDiv()}>
            <Link aria-label="alertPromo link" href={data?.data?.linkUrl} className={linkTitle()}>
              <p>{data?.data?.linkText}</p>
            </Link>
            <IconHelper icon="close" className={closeIcon()} />
          </div>
        )}
      </div>
    )
  );
};

export default widget(ContentBlockWidget, WidgetDataType.CONTENT_BLOCK, 'ContentBlockWidget');
