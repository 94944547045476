import { useState } from 'react';
import { ConditionTypes, IPersonalizationCondition } from '../models';
import { useRuleOptions } from '../providers/RuleOptionsProvider';
import DropdownField, { OptionFieldProps } from './form/DropdownField';
import FormTextField from './form/TextField';
import { ItemTreeViewModal } from './ItemTreeViewModal';
import { ItemNode } from './ItemTreeView/ItemTreeView';
import { useField } from 'formik';

interface ConditionValueFieldProps {
  ruleIndex: number;
  index: number;
  condition: IPersonalizationCondition;
}

export function ConditionValueField({ condition, ruleIndex, index }: ConditionValueFieldProps) {
  const conditionField = `rules.${ruleIndex}.conditions.${index}`;
  const id = `${conditionField}.value`;
  const name = `${conditionField}.name`;
  const { serviceOptions, speciesOptions } = useRuleOptions();
  const [showGoalsModal, setShowGoalsModal] = useState(false);
  const [{ value: conditionId }, {}, { setValue: setConditionValue }] = useField(id);
  const [{ value: conditionName }, {}, { setValue: setConditionName }] = useField(name);

  const storeTypeOptions: OptionFieldProps[] = [
    'DFS Enabled',
    'BOPIS Enabled',
    'Franchise',
    'Corporate',
  ].map((value) => ({
    value: { value },
    text: { value },
  }));

  /**
   * Handles the save click event when selecting a goal from the goals modal.
   *
   * @param {ItemNode | undefined} selectedItem - The selected item from the goals modal.
   * @example
   * handleGoalSaveClick({
   *   id: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *   name: 'Goal 1',
   * });
   */
  const handleGoalSaveClick = (selectedItem?: ItemNode) => {
    const regexPattern =
      /([A-Fa-f0-9]{8})([A-Fa-f0-9]{4})([A-Fa-f0-9]{4})([A-Fa-f0-9]{4})([A-Fa-f0-9]{12})/;
    const formattedId = selectedItem?.id.replace(regexPattern, '$1-$2-$3-$4-$5');
    setConditionValue(formattedId);
    setConditionName(selectedItem?.name);
    setShowGoalsModal(false);
  };

  const deviceTypeOptions: OptionFieldProps[] = ['Desktop', 'Mobile'].map((value) => ({
    value: { value },
    text: { value },
  }));

  if (typeof condition === 'string') {
    return <></>;
  }
  switch (condition.type) {
    case ConditionTypes.WHERE_STORE_IS:
    case ConditionTypes.EXCEPT_WHERE_STORE_IS:
      return (
        <FormTextField
          id={id}
          name={id}
          label="Store"
          placeholdertext="Enter condition"
          title={condition?.value}
          required={true}
        />
      );
    case ConditionTypes.WHERE_STORE_TYPE_IS:
    case ConditionTypes.EXCEPT_WHERE_STORE_TYPE_IS:
      return <DropdownField id={id} name={id} options={storeTypeOptions} required={true} />;
    case ConditionTypes.WHERE_STORE_OFFERS:
    case ConditionTypes.EXCEPT_WHERE_STORE_OFFERS:
      return <DropdownField id={id} name={id} options={serviceOptions} required={true} />;
    case ConditionTypes.WHERE_BOOKING_ONLINE:
    case ConditionTypes.EXCEPT_WHERE_BOOKING_ONLINE:
      // Value is implicitly determined by the condition type
      return <></>;
    case ConditionTypes.WHEN:
    case ConditionTypes.EXCEPT_WHEN:
      return (
        <FormTextField
          placeholdertext="Enter condition"
          fieldtype="datetime-local"
          id={id}
          name={id}
          required={true}
        />
      );
    case ConditionTypes.WHERE_PAGE_URL_IS:
    case ConditionTypes.EXCEPT_WHERE_PAGE_URL_IS:
      return <FormTextField placeholdertext="Enter condition" id={id} name={id} required={true} />;
    case ConditionTypes.WHERE_GOAL_IS:
    case ConditionTypes.EXCEPT_WHERE_GOAL_IS:
      return (
        <>
          <a onClick={() => setShowGoalsModal(true)} className={!conditionId ? 'text-red-500' : ''}>
            {conditionName || conditionId || 'Select Goal'}
          </a>
          <ItemTreeViewModal
            showModal={showGoalsModal}
            onCloseClick={() => setShowGoalsModal(false)}
            onSaveClick={handleGoalSaveClick}
            rootNode={{
              id: '/sitecore/content/PetSuppliesPlus/psp/Data/Lookups/PSP Goals',
              name: 'PSP Goals',
              hasLayout: false,
              hasChildren: true,
            }}
          />
        </>
      );
    case ConditionTypes.WHERE_DEVICE_IS:
    case ConditionTypes.EXCEPT_WHERE_DEVICE_IS:
      return <DropdownField id={id} name={id} options={deviceTypeOptions} required={true} />;
    case ConditionTypes.WHERE_USER_HAS_PET_SPECIES:
    case ConditionTypes.EXCEPT_WHERE_USER_HAS_PET_SPECIES:
      return <DropdownField id={id} name={id} options={speciesOptions} required={true} />;
    case ConditionTypes.WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE:
    case ConditionTypes.EXCEPT_WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE:
      // Value is implicitly determined by the condition type
      return <></>;
    default:
      return <div>No condition type selected</div>;
  }
}
