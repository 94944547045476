import { errorMessages } from 'components/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
import React, { useContext } from 'react';
import useDictionary from 'src/hooks/useDictionary';
import { tv } from 'tailwind-variants';

const errorLabelVariant = tv(
  {
    slots: {
      title: ['text-system-red'],
    },
    variants: {
      size: {
        mobile: {
          title: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
        },
        desktop: {
          title: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

const ErrorLabel = () => {
  const { title } = errorLabelVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { componentContextData } = useContext(ComponentContext);
  const { getDictionaryValue } = useDictionary();
  return (
    <>
      <span id="payment-error-label" className={title()}>
        {componentContextData?.unavailableItemsRemoved == true
          ? getDictionaryValue('checkoutpriceexcludecheck')
          : errorMessages?.invalidCVV}
      </span>
    </>
  );
};

export default ErrorLabel;
