import { unwrapResult } from '@reduxjs/toolkit';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  FilterGeo,
  PageController,
  SearchResultsInitialState,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import clsx from 'clsx';
import { StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import { FulfillmentType, GTM_EVENT } from 'components/helpers/Constants';
import LinkHelper from 'components/helpers/LinkHelper';
import ComponentContext from 'lib/context/ComponentContext';
import { PSP } from 'models/PetSuppliesPlus.Model';
// import { useRouter } from 'next/router';
import { BuyerAddress, Order } from 'ordercloud-javascript-sdk';
import { useContext, useEffect } from 'react';
import useDictionary from 'src/hooks/useDictionary';
import {
  clearCurrentOrderWithoutInitialized,
  patchOrder,
  retrieveOrder,
  saveShippingAddress,
} from 'src/redux/ocCurrentOrder';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { convertMilesToKM } from 'src/utils/milestokm';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { tv } from 'tailwind-variants';

type inminicart = true | false | undefined;

const FindStoreVariants = tv(
  {
    slots: {
      storeListing: ['flex', 'flex-col', 'border-t', 'border-t-color-border-mid'],
      storeItem: ['flex', 'gap-[12px]', 'border-b', 'border-b-color-border-mid'],
      storeDetailsWrapper: ['flex', 'gap-[12px]'],
      storeDetail: ['flex flex-col gap-[12px]'],
      storeName: ['text-color-text-dark'],
      myStoreWrapper: ['flex', 'gap-[12px]'],
      myStoreTxt: [
        'text-color-text-brand-1',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-body-large-bold',
      ],
      selectStoreWrapper: ['flex', 'flex-col'],
      selectStoreCTA: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
        'text-center',
      ],
      storeThumbnailImage: ['w-auto', 'max-h-[124px]', 'self-start', 'flex'],
    },
    variants: {
      device: {
        mobile: {
          selectStoreWrapper: ['w-full'],
          storeName: ['font-body-large-bold', 'leading-body-large-bold', 'text-body-large-bold'],
          storeListing: ['py-mob-margin-tight-top', 'gap-mob-space-between-tight-vertical'],
          storeItem: ['pb-desk-margin-tight-bottom'],
          storeDetailsWrapper: ['flex-col', 'justify-between'],
          selectStoreCTA: [
            'font-body-large-bold',
            'leading-body-large-bold',
            'text-body-large-bold',
            'px-mob-component-button-condensed-padding-x',
            'py-mob-component-button-condensed-padding-y',
          ],
        },
        desktop: {
          selectStoreWrapper: ['w-auto'],
          storeName: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          storeListing: ['py-desk-margin-tight-top', 'gap-desk-space-between-tight-vertical'],
          storeDetailsWrapper: ['flex-row', 'justify-between', 'flex-[1_0_0]'],
          storeItem: ['pb-desk-margin-tight-bottom'],
          myStoreWrapper: ['order-2'],
          selectStoreCTA: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
        },
      },
      inminicart: {
        true: {
          selectStoreWrapper: ['w-full', 'self-auto'],
          storeName: ['font-body-large-bold', 'leading-body-large-bold', 'text-body-large-bold'],
          storeListing: ['py-mob-margin-tight-top', 'gap-mob-space-between-tight-vertical'],
          storeItem: ['pb-desk-margin-tight-bottom'],
          storeDetailsWrapper: ['lg:flex-col', 'justify-between'],
          selectStoreCTA: [
            'font-body-large-bold',
            'leading-body-large-bold',
            'text-body-large-bold',
            'px-mob-component-button-condensed-padding-x',
            'py-mob-component-button-condensed-padding-y',
          ],
          storeDetail: ['lg:order-2'],
          storeThumbnailImage: [],
        },
        false: {
          selectStoreWrapper: ['self-end'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

export type GenericStoreLocatorProps =
  (PSP.Sitecore.templates.PetSuppliesPlus.FulfillmentSwitcher.Fields.FulfillmentSwitcher &
    PSP.Sitecore.templates.PetSuppliesPlus.Store.Fields.StoreLocator) & {
    handleModalClose: () => void;
    coordinates: { lat: number; lng: number };
    showMainLoader: (value: boolean) => void;
    setFindStorePopupOpen: (value: boolean) => void;
    setShowHardError: (value: boolean) => void;
    setShowNoDeliveryError: (value: boolean) => void;
    setRenderStoreWidget: (value: boolean) => void;
    setLoading?: (value: boolean) => void;
    formShippingAddress?: Partial<BuyerAddress>;
    inMiniCart: boolean;
  };

const GenericStoreLocator = ({
  fields,
  handleModalClose,
  coordinates,
  showMainLoader,
  setFindStorePopupOpen,
  setShowHardError,
  setShowNoDeliveryError,
  setRenderStoreWidget,
  formShippingAddress,
  setLoading,
  inMiniCart,
}: GenericStoreLocatorProps) => {
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const setRadius = fields?.globalRadiusInMiles?.value
    ? fields?.globalRadiusInMiles?.value
    : myStoreData?.radiusInMiles;
  // added km statically as circleRadius used other places as well
  const milestokm = convertMilesToKM(setRadius).toFixed(0);
  const dispatch = useOcDispatch();

  // const router = useRouter();
  const {
    storeListing,
    storeItem,
    storeDetailsWrapper,
    storeName,
    myStoreWrapper,
    myStoreTxt,
    selectStoreCTA,
    selectStoreWrapper,
    storeDetail,
    storeThumbnailImage,
  } = FindStoreVariants({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
    inminicart: inMiniCart as inminicart,
  });

  type InitialState = SearchResultsInitialState<'itemsPerPage' | 'sortType'>;
  const {
    queryResult: { isFetching, isLoading, data: { content: storesList = [] } = {} },
    query,
  } = useSearchResults<StoreProps, InitialState>({
    state: {
      itemsPerPage: Number(fields?.numberOfRecords?.value),
      sortType: 'near_by_distance_asc',
    },
  });

  const { getDictionaryValue } = useDictionary();

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const geoFilter = new FilterGeo('location', `${milestokm}km`);

  useEffect(() => {
    query.getRequest().resetSearchFilter().setSearchFilter(geoFilter);
  }, []);

  useEffect(() => {
    PageController.getContext().setGeo({
      location: { lat: coordinates?.lat, lon: coordinates?.lng },
    });
  }, [coordinates]);

  useEffect(() => {
    if (!(isLoading || isFetching)) {
      if (storesList?.length <= 0) {
        setShowHardError(false);
        setShowNoDeliveryError(true);
        setRenderStoreWidget(false);
        setLoading && setLoading(false);
        return;
      }
      setFindStorePopupOpen(true);
      setLoading && setLoading(false);
    }
  }, [isLoading, isFetching]);

  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);

  async function selectStoreClick(store: { storeid: string }) {
    if (store?.storeid === myStoreData?.storeId) {
      sendStoreGTMData({
        eventName: GTM_EVENT?.selectStore,
        storeId: store?.storeid,
        storesShown: storesList?.length?.toString(),
      });

      handleModalClose();
      const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
      showMainLoader(true);
      //Save shipping Address

      if (formShippingAddress) {
        if (currentOrder?.lineItems && currentOrder?.lineItems?.length > 0) {
          await dispatch(saveShippingAddress(formShippingAddress));
        } else {
          localStorage.setItem('selected_delivery_address', JSON.stringify(formShippingAddress));
        }
        if (currentOrder?.order && currentOrder?.order?.ID) {
          await dispatch(patchOrder(request)).then(() => {
            showMainLoader(false);
          });
        } else {
          localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
          showMainLoader(false);
        }
      }
    } else {
      //Show Warning Popup while switch to another store.
      setcomponentContextData({
        ...componentContextData,
        storeId: store?.storeid,
        fromFulfillment: true,
        callBackFunctionAfterStoreChange: async () => {
          handleModalClose();
          showMainLoader(true);
          // const redirectUrl = '/'; // TODO: will this url configure from CMS?
          const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
          const retrieveStoreOrder = await dispatch(retrieveOrder());
          const responsesStoreOrder = unwrapResult(retrieveStoreOrder);
          if (responsesStoreOrder === undefined) {
            await dispatch(clearCurrentOrderWithoutInitialized());
            if (formShippingAddress) {
              localStorage.setItem(
                'selected_delivery_address',
                JSON.stringify(formShippingAddress)
              );
              localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
            }
          } else {
            await dispatch(retrieveOrder());
            if (responsesStoreOrder?.LineItems && responsesStoreOrder?.LineItems?.length > 0) {
              if (formShippingAddress) {
                await dispatch(await saveShippingAddress(formShippingAddress));
              }
              localStorage.removeItem('selected_fulfillment_Method');
            }
          }
          // if (currentOrder?.lineItems && currentOrder?.lineItems?.length > 0) {
          //   if (formShippingAddress) {
          //     await dispatch(await saveShippingAddress(formShippingAddress));
          //   }
          // } else if (formShippingAddress) {
          //   localStorage.setItem('selected_delivery_address', JSON.stringify(formShippingAddress));
          // }
          if (responsesStoreOrder?.Order && responsesStoreOrder?.Order?.ID) {
            await dispatch(patchOrder(request)).then(() => {
              showMainLoader(false);
            });
          } else {
            // localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
            showMainLoader(false);
          }

          // router?.push(redirectUrl);
        },
      });
    }
  }
  return (
    <>
      {!(isLoading || isFetching) && storesList?.length > 0 && (
        <div className={storeListing()}>
          {storesList?.map((store) => {
            return (
              <>
                {store?.xp_dfs && (
                  <div
                    className={storeItem()}
                    key={store?.id}
                    data-storeid={store?.storeid}
                    data-companyname={store?.companyname}
                  >
                    <div className={clsx('storeimage', 'w-3/12')}>
                      {store?.xp_storeimage && (
                        <NextImage
                          className={storeThumbnailImage()}
                          field={{
                            value: {
                              src: `${store?.xp_storeimage[0]}`,
                              alt:
                                myStoreData?.storeId === store?.storeid
                                  ? myStoreData?.storeName
                                  : store?.state,
                              width: '124',
                              height: '124',
                            },
                          }}
                        />
                      )}
                    </div>
                    <div className="flex flex-col flex-[1_0_0] gap-[12px]">
                      <div className={storeDetailsWrapper()}>
                        {myStoreData?.storeId === store?.storeid && (
                          <div className={myStoreWrapper()}>
                            <div className="storeImage">
                              <NextImage
                                field={{
                                  value: {
                                    src:
                                      fields?.storeImageIcon?.value?.src ||
                                      `/images/my-store-icon-green.jpg`,
                                    height: '24',
                                    width: '24',
                                    alt: myStoreData?.storeName,
                                  },
                                }}
                              />
                            </div>
                            <span className={myStoreTxt()}>{getDictionaryValue('MyStore')}</span>
                          </div>
                        )}

                        <div className={storeDetail()}>
                          <p className={storeName()}>{store?.firstname}</p>
                        </div>
                      </div>

                      <div className={selectStoreWrapper()}>
                        <LinkHelper
                          className={selectStoreCTA()}
                          onClick={async (e) => {
                            e.preventDefault();
                            await selectStoreClick(store);
                          }}
                          field={{
                            value: {
                              href: '/',
                              text: getDictionaryValue('SelectStore'),
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      )}
    </>
  );
};
const FindStorePopupWidget = widget(GenericStoreLocator, WidgetDataType.SEARCH_RESULTS, 'store');

export default FindStorePopupWidget;
