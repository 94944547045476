import { Field, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from 'lib/context/ThemeContext';
import React, { useEffect, useState } from 'react';
import apiConfig from 'src/utils/apiConfig';
import { apiRequest } from 'src/utils/apiWrapper';
import { ComponentProps } from 'lib/component-props';
import { tv } from 'tailwind-variants';
import LinkHelper from 'components/helpers/LinkHelper';
import clsx from 'clsx';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useRouter } from 'next/router';
import { ErrorCodes, statusMessage } from 'components/helpers/Constants';
import Loader from 'components/Loader/Loader';

export type AccountVerificationProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      success: Field<string>;
      genericError: Field<string>;
      linkExpire: Field<string>;
      ctaText: Field<string>;
      cta: {
        jsonValue: LinkField;
      };
    };
  };

const AccountVerificationVariant = tv(
  {
    slots: {
      successBase: '',
      loaderWrapper: '',
      errorMessage: 'text-system-red',
      button: [
        'flex',
        'content-center',
        'items-center',
        'rounded-desk-global-radius-small',
        'text-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'outline-none',
        'justify-center',
        'hover:bg-color-brand-primary-1-dark',
        'bg-color-brand-primary-1-base',
        'text-color-text-white',
      ],
    },
    compoundSlots: [
      {
        class: [
          'flex',
          'items-center',
          'justify-center',
          'w-full',
          'text-center',
          'py-mob-padding-tight-y',
          'px-mob-global-grid-margin',
          'lg:py-desk-padding-tight-y',
          'lg:px-desk-global-grid-margin',
        ],
        slots: ['successBase', 'loaderWrapper', 'errorMessage'],
      },
    ],
    variants: {
      device: {
        mobile: {
          successBase:
            'mb-mob-margin-loose-bottom py-mob-padding-tight-y px-mob-global-grid-margin ',
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          button: [
            'gap-mob-component-button-full-space-between',
            'py-mob-component-button-full-padding-y',
            'px-mob-component-button-full-padding-x',
            'w-full',
          ],
        },
        desktop: {
          successBase:
            'mb-desk-margin-loose-bottom y-desk-padding-tight-y px-desk-global-grid-margin',
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          button: [
            'gap-desk-component-button-full-space-between',
            'py-desk-component-button-full-padding-y',
            'px-component-button-full-padding-x',
            'w-fit',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const AccountVerification = ({ fields, params }: AccountVerificationProps): JSX.Element => {
  const { successBase, loaderWrapper, button } = AccountVerificationVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { themeNameUpper } = useTheme();
  const [successResponse, setSuccessResponse] = useState(false);
  const token = useAuthToken();
  const [showLoader, setShowLoader] = useState(true);
  //const [errorMessageVal, setErrorMessageVal] = useState('Something went wrong. Try again.');
  const [messageCode, setMessageCode] = useState('');
  const router = useRouter();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Set the isClient flag to true after the component has mounted
    setIsClient(true);
  }, []);

  //   Function call to verify the user:
  const activateUser = async (queryParams: unknown) => {
    const activateUserAPI = apiConfig?.authenticationAPI?.activateUserAPI;
    const headersData = {
      site: themeNameUpper,
      requesturl: window.location.origin,
      Authorization: token,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const paramsData: any = queryParams;

    // Encode each value in the object
    for (const key in paramsData) {
      paramsData[key] = encodeURIComponent(paramsData[key]);
    }
    const options = {
      method: 'GET',
      headers: headersData,
      params: paramsData,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await apiRequest(activateUserAPI, options);
      if (response && response === ErrorCodes?.Success) {
        setShowLoader(false);
        setSuccessResponse(true);
        setMessageCode(response);
      } else if (response && response === ErrorCodes?.LinkExpired) {
        setMessageCode(response);
        setShowLoader(false);
        setSuccessResponse(false);
      } else {
        setMessageCode('Error');
        setShowLoader(false);
        setSuccessResponse(false);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    if (!isClient) return;

    try {
      const dataToSend = router?.query;
      delete dataToSend?.path;
      const queryParams = dataToSend;
      if (dataToSend && dataToSend.e) activateUser(queryParams);
    } catch (error) {
      console.error(error);
    }
  }, [router?.query, isClient]);

  const msg =
    messageCode == ErrorCodes?.Success
      ? fields?.success
      : messageCode == ErrorCodes?.LinkExpired
      ? fields?.linkExpire
      : fields?.genericError;
  return (
    <div className={'AccountVerification ' + params?.Styles}>
      {showLoader && (
        <div className={loaderWrapper()}>
          <Loader />
          <p>{statusMessage?.inProgress}</p>
        </div>
      )}
      {!showLoader && (
        <div className={clsx(successBase(), 'flex-col', 'gap-[12px]')}>
          <RichText className="rte" tag="p" field={msg} />
          {successResponse && (
            <LinkHelper
              className={button({ className: 'hover:no-underline' })}
              field={{
                value: {
                  href: fields?.cta?.jsonValue?.value.href ?? '/login',
                  text: fields?.ctaText?.value,
                },
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AccountVerification;
