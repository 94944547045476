import React, { useState } from 'react';
import { BaseItemNode, TreeViewer } from './TreeViewer/TreeViewer';
import axios from 'axios';
import { graphQLResponseItemData } from 'src/pages/api/personalization/getItem';

export type ItemTreeViewProps = {
  onElementSelected: (item: ItemNode) => void;
  rootNode?: ItemNode;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ItemNode extends BaseItemNode<ItemNode> {}

const ItemTreeView = ({
  onElementSelected,
  rootNode = {
    id: '/sitecore/content/PetSuppliesPlus',
    name: 'PetSuppliesPlus',
    hasLayout: false,
    hasChildren: true,
  },
}: ItemTreeViewProps) => {
  const [selectedItem, setSelectedItem] = useState<ItemNode>();
  const item = { ...rootNode };
  const fetchData = async (item: ItemNode) => {
    item.children = [];
    const addedItemIds = new Set<string>();
    const data = await getItemData(item);

    if (data) {
      data?.graphQLResponse?.item?.children.results.forEach((x) => {
        if (addedItemIds.has(x.id)) {
          return;
        }
        addedItemIds.add(x.id);
        item.children?.push({
          id: x.id,
          name: x.name,
          hasLayout: !!x.rendered,
          hasChildren: x.children.results.length > 0,
        });
      });
    }

    return item.children;
  };

  return (
    <TreeViewer<ItemNode>
      item={item}
      onItemSelected={(item) => {
        setSelectedItem(item);
        onElementSelected(item);
      }}
      isSelected={(item) => selectedItem?.id === item.id}
      fetchData={fetchData}
    />
  );
};

export default ItemTreeView;

async function getItemData(item: ItemNode) {
  const response = await axios.get<graphQLResponseItemData>(
    `${process.env.PUBLIC_URL}/api/personalization/getItem?path=${item.id}`
  );
  return response.data;
}
