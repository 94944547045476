import axios from 'axios';
import { RulesModal } from './components/RulesModal';
import { IDropdownItem, IPersonalizationRule, OptionFieldProps } from './models';
import { useRouter } from 'next/router';
import { ComponentType, FC, useEffect, useMemo, useState } from 'react';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useBreakpoints } from 'src/utils/breakpoints';
import { SimpleDropdown } from './components/form/SimpleDropdown';
import { evaluateRules } from './evaluateRules/evaluateRules';
import { button, iconButton, personalizeButtonGroup } from './styles';
import { useOcSelector } from 'src/redux/ocStore';
import { difference } from 'lodash';
import { FaEdit } from 'react-icons/fa';

// TODO: remove type 'any' once we have the generated type
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
const withPersonalization = <P extends any>(WrappedComponent: ComponentType<P>): FC<P> => {
  // TODO: remove type 'any' once we have the generated type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WithPersonalization: FC<P> = (props: any /*:P */) => {
    const isEE = useExperienceEditor();
    // temporary way of viewing the experience editor while developing locally (set isEditor to true in localStorage)
    const isDevEditor =
      typeof window !== 'undefined' && localStorage.getItem('isEditor') === 'true';
    const isEditor = isDevEditor || isEE;
    const [showModal, setShowModal] = useState(false);
    const [rules, setRules] = useState<IPersonalizationRule[]>(
      JSON.parse(props.fields?.Rules?.value || '[]')
    );
    const { isMobile } = useBreakpoints();
    const { query, asPath } = useRouter();
    const goalId = query.sc_trk?.toString() || '';
    const [matchedRule, setMatchedRule] = useState<IPersonalizationRule | null>(null);
    const [loading, setLoading] = useState(true); // Loading state
    // TODO: remove type 'any' once we have the generated type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [matchedRuleContent, setMatchedRuleContent] = useState<any | null>(null);
    const stringifiedContentItems = JSON.stringify(props.fields?.ContentItems || []);
    const [storeId, setStoreId] = useState(
      typeof localStorage !== 'undefined' ? localStorage.getItem('storeId') ?? '0044' : '0044'
    );
    const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);
    const user = useOcSelector((state) => state?.ocUser?.user);
    const isBopisEnabled = useMemo(() => selectedStore.BOPIS === true, [selectedStore?.BOPIS]);
    const isDfsEnabled = useMemo(() => selectedStore?.DFS === true, [selectedStore?.DFS]);
    const isFranchise = useMemo(() => selectedStore?.StoreType === 2, [selectedStore?.StoreType]);
    const isCorporate = useMemo(() => selectedStore?.StoreType === 1, [selectedStore?.StoreType]);
    const hasOnlineBooking = useMemo(
      () => selectedStore?.OnlineBooking === true,
      [selectedStore?.OnlineBooking]
    );
    const storeServices = useMemo(
      () => selectedStore?.StoreServices || [],
      [selectedStore?.StoreServices]
    );
    const userHasAcceptedLoyaltyTermsOfUse = useMemo(
      () => user?.xp?.LoyaltyAccepted === true,
      [user?.xp?.LoyaltyAccepted]
    );
    const userPetSpecies = useMemo(
      () => (user?.xp?.pets ? Object.keys(user.xp.pets) : []),
      [user?.xp?.pets]
    );

    // TODO: remove type 'any' once we have the generated type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [selectedDataSourceItemId, setSelectedDataSourceItemId] = useState<string>('');
    // TODO: remove type 'any' once we have the generated type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [selectedDataSourceFields, setSelectedDataSourceFields] = useState<null | any>(null);
    typeof window !== 'undefined' &&
      addEventListener('storeIdChanged', () => setStoreId(localStorage.getItem('storeId') ?? ''));

    useEffect(() => {
      if (!props?.fields?.Rules?.value || isEditor) {
        setLoading(false);
        return;
      }
      const match = evaluateRules(JSON.parse(props?.fields?.Rules?.value), {
        storeId,
        path: asPath,
        goalId,
        isMobile,
        isBopisEnabled,
        isDfsEnabled,
        isFranchise,
        isCorporate,
        storeServices,
        hasOnlineBooking,
        userHasAcceptedLoyaltyTermsOfUse,
        userPetSpecies,
      });
      if (match) {
        setMatchedRule(match);
      } else {
        setMatchedRule(null);
      }
    }, [
      storeId,
      asPath,
      goalId,
      isMobile,
      props?.fields?.Rules?.value,
      isEditor,
      isBopisEnabled,
      isDfsEnabled,
      isFranchise,
      isCorporate,
      storeServices,
      hasOnlineBooking,
      userHasAcceptedLoyaltyTermsOfUse,
      userPetSpecies,
    ]);

    useEffect(() => {
      if (!props?.fields?.Rules?.value || !stringifiedContentItems?.length || isEditor) {
        setLoading(false);
        return;
      }
      if (matchedRule) {
        const contentItem = JSON.parse(stringifiedContentItems).find(
          // TODO: remove type 'any' once we have the generated type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => {
            return item.id.replace(/-/g, '').toUpperCase() === matchedRule.contentItemId;
          }
        );
        if (contentItem) {
          setMatchedRuleContent(contentItem);
        } else {
          setMatchedRuleContent(null);
        }
      } else {
        setMatchedRuleContent(null);
      }
      setLoading(false);
    }, [matchedRule, stringifiedContentItems, props?.fields?.Rules?.value, isEditor]);

    const saveRules = async (
      updatedRules: IPersonalizationRule[],
      showDefaultContent: boolean,
      defaultCmsPath: string
    ) => {
      const updatedContentItemIds = updatedRules
        .map((rule) => rule.contentItemId?.toLowerCase())
        .sort();
      await axios.post(
        `${process.env.PUBLIC_URL}api/personalization/updateRules?dataSource=${props.rendering.dataSource}`,
        {
          rules: updatedRules,
          contentItems: updatedContentItemIds.join('|'),
          hideDefaultContent: !showDefaultContent,
          defaultCmsPath,
        }
      );
      setRules(updatedRules);
      setShowModal(false);
      // TODO: remove type 'any' once we have the generated type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const existingContentItemIds = props.fields?.ContentItems.map((item: any) =>
        item.id.toLowerCase().replace(/-/g, '')
      );
      const diff = difference(updatedContentItemIds, existingContentItemIds);
      if (diff.length > 0) {
        alert(
          `The content items defined in the personalization rules, don't match the content items currently available on the page. Please refresh your page if you need to preview the rules content`
        );
      }
    };

    if (loading) {
      // Render a fallback component or null while loading
      return (
        <div className="opacity-0">
          <WrappedComponent {...props} />
        </div>
      );
    }

    // // Display the component based on the configured rules
    // if (!isEditor) {
    //   if (matchedRule?.show && matchedRuleContent?.fields) {
    //     return <WrappedComponent {...props} fields={matchedRuleContent?.fields} />;
    //   } else if (matchedRule && !matchedRule?.show) {
    //     return <></>;
    //   } else {
    //     return <WrappedComponent {...props} />;
    //   }
    // }

    if (!isEditor) {
      if (matchedRule?.show) {
        if (matchedRuleContent?.fields) {
          return <WrappedComponent {...props} fields={matchedRuleContent.fields} />;
        }
      } else if (matchedRule && !matchedRule.show) {
        return <></>;
      }
      if (props?.fields?.HideDefaultContent?.value === true) {
        return <></>;
      } else {
        return <WrappedComponent {...props} />;
      }
    }

    return (
      <>
        <div className="relative flex flex-col-reverse gap-2 w-full border-dashed border-green-500/50 border-2 mb-[15px]">
          <div
            className={`${personalizeButtonGroup()} lg:max-w-[250px] xs:max-w-[250px] xs:bottom-[110%] lg:top-[-50px] xs:flex-row items-center w-full self-end`}
          >
            <button
              type="button"
              className={`${button({
                color: 'primary',
                style: 'personalize',
              })} hidden lg:block w-full`}
              onClick={() => setShowModal(true)}
            >
              Personalize
            </button>
            <button
              type="button"
              className={`${iconButton({
                color: 'primary',
                className: 'ml-auto',
              })} lg:hidden xs:flex bg-green-500 hover:bg-green-600 w-[45px] h-[45px] p-1 rounded-[4px]`}
              onClick={() => setShowModal(true)}
            >
              <FaEdit className="text-white w-full h-full p-1.5" />
            </button>

            {rules.length > 0 && (
              <SimpleDropdown
                className={'!max-w-none'}
                placeholder="Default"
                value={selectedDataSourceItemId}
                options={rules.map((r) => ({
                  value: r.contentItemId,
                  label: r.name,
                }))}
                onChange={(contentItemId) => {
                  setSelectedDataSourceItemId(contentItemId);
                  const contentItem = props.fields?.ContentItems.find(
                    // TODO: remove type 'any' once we have the generated type
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (c: any) =>
                      c.id.toLowerCase().replace(/-/g, '') ===
                      contentItemId?.toLowerCase().replace(/-/g, '')
                  );
                  if (contentItem) {
                    setSelectedDataSourceFields(contentItem.fields);
                  } else {
                    setSelectedDataSourceFields(null);
                  }
                }}
              />
            )}
          </div>
          {selectedDataSourceFields ? (
            <WrappedComponent {...props} fields={selectedDataSourceFields} />
          ) : (
            <WrappedComponent {...props} />
          )}
        </div>
        {showModal ? (
          <RulesModal
            showModal={showModal}
            onCloseClick={() => setShowModal(false)}
            onSaveClick={saveRules}
            existingRules={rules}
            existingHideDefaultContent={props.fields?.HideDefaultContent?.value || false}
            existingDefaultCmsPath={props.fields?.DefaultCmsPath?.value || ''}
            // TODO: remove type 'any' once we have the generated type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            contentItemIds={props.fields?.ContentItems.map((item: any) => item.id)}
          />
        ) : null}
      </>
    );
  };

  WithPersonalization.displayName = 'WithPersonalization';

  return WithPersonalization;
};

export default withPersonalization;

export const toOption = (item: IDropdownItem): OptionFieldProps => ({
  value: { value: item?.value?.value },
  text: { value: item.label?.value },
});
