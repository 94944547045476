import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { Me } from 'ordercloud-javascript-sdk';
import { createOcAsyncThunk } from '../ocReduxHelpers';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { GTM_EVENT } from 'components/helpers/Constants';
import { getProductPriceFromSearch } from 'src/helpers/ProductPriceUI';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductData } from 'components/ProductDetail/ProductDetail';
import { getSerializableObject } from '../utils';

export interface WishlistState {
  wishlist?: string[];
  wishlistCollectionId?: string;
  wishlistCollectionIdError?: SerializedError;
  wishlistCollectionIdLoading?: boolean;
  loading: boolean;
  error?: SerializedError;
}

const initialState: WishlistState = {
  loading: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createWishlistCollection = createOcAsyncThunk<any, string>(
  'Wishlist/create',
  async (wishlistId: string) => {
    return Me.CreateProductCollection({
      ID: wishlistId,
      Name: wishlistId,
    });
  }
);

export const createWishlistCollectionAndUpdate = async (data: {
  wishlistCollectionId: string;
  productId?: string;
}) => {
  return Me.CreateProductCollection({
    ID: data?.wishlistCollectionId,
    Name: data?.wishlistCollectionId,
  }).then(async () => {
    if (data?.productId) {
      return await addProductInWishList(data?.wishlistCollectionId, data?.productId);
    }
    return null;
  });
};
export const getWishlistWithAllProductDetails = async (wishlistCollectionId: string) => {
  return await Me.ListProductCollectionEntries(wishlistCollectionId);
};

export const getWishlistWithAllProductDetailsByStore = async (
  wishlist: string[],
  storeId: string
) => {
  return await Me?.ListProducts({
    search: wishlist?.join('|'),
    searchOn: ['ID'],
    sellerID: storeId,
    filters: { 'xp.WE': false },
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getWishlist = createOcAsyncThunk<any, string>(
  'Wishlist/get',
  async (wishlistCollectionIdValue: string) => {
    if (wishlistCollectionIdValue) {
      const wishlistData = await Me.ListProductCollectionEntries(wishlistCollectionIdValue);
      return wishlistData?.Items?.map((item) => item?.ID) || [];
    }
    return;
  }
);

export const addProductInWishList = async (wishlistCollectionId: string, productId: string) =>
  Me.CreateProductCollectionEntry(wishlistCollectionId, productId).then(() => {
    return productId;
  });
export const removeProductFromWishlist = async (wishlistCollectionId: string, productId: string) =>
  Me.DeleteProductCollectionEntry(wishlistCollectionId, productId).then(() => {
    return productId;
  });

export const updateProductInWishlist = createOcAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  {
    product?: ProductSearchResultModelWithVariants | ProductData;
    productId?: string;
    addOnly?: boolean;
    index?: number;
    storeId?: string;
    clickFrom?: string;
    isAnonymous?: boolean;
    currentPage?: string;
    pageItem?: string;
    wishlistData?: WishlistState;
  }
>(
  'Wishlist/update',
  async (
    data: {
      product?: ProductSearchResultModelWithVariants | ProductData;
      productId?: string;
      addOnly?: boolean;
      index?: number;
      storeId?: string;
      clickFrom?: string;
      isAnonymous?: boolean;
      currentPage?: string;
      pageItem?: string;
      wishlistData?: WishlistState;
    },
    thunkAPI
  ) => {
    // Sending GTM promotion data
    // const { currentPage, pageItem } = getGTMSessionStorage();
    const productId =
      data?.productId ||
      (data?.product as ProductSearchResultModelWithVariants)?.id ||
      ((data?.product as ProductData)?.ID as string);
    const productPrice = data?.product && getProductPriceFromSearch(data?.product);
    const wishlistState = data?.wishlistData ?? thunkAPI.getState().wishlist;
    data?.isAnonymous &&
      sendProductsPromotion({
        eventName: GTM_EVENT?.addToWishlist,
        data: data?.product as ProductSearchResultModelWithVariants,
        totalCount: productPrice?.listPrice,
        currency: true,
        currentPage: data?.currentPage,
        pageItem: data?.pageItem,
        storeId: data?.storeId,
        position: data?.index,
        click_from: data?.clickFrom,
        isRatingShow: true,
      });
    console.log('wishlistState?.wishlistCollectionId && wishlistState?.error', wishlistState);
    if (wishlistState?.wishlistCollectionId && !wishlistState?.error) {
      if (
        wishlistState?.wishlist &&
        isProductInWishlist(wishlistState?.wishlist, productId) &&
        !data?.addOnly
      ) {
        return removeProductFromWishlist(wishlistState?.wishlistCollectionId, productId).then(
          (productId) => {
            if (productId && wishlistState?.wishlist) {
              const wishData = [...wishlistState?.wishlist];
              data?.storeId &&
                sendProductsPromotion({
                  eventName: GTM_EVENT?.removeFromWishlist,
                  data: data?.product as ProductSearchResultModelWithVariants,
                  currency: true,
                  totalCount: productPrice?.listPrice,
                  currentPage: data?.currentPage,
                  pageItem: data?.pageItem,
                  position: data?.index,
                  storeId: data?.storeId,
                  click_from: data?.clickFrom,
                  isRatingShow: true,
                });
              return wishData.filter(
                (productIdFromWishlist) => productIdFromWishlist !== productId
              );
            }
            return [wishlistState?.wishlist];
          }
        );
      } else {
        data?.storeId &&
          sendProductsPromotion({
            eventName: GTM_EVENT?.addToWishlist,
            data: data?.product as ProductSearchResultModelWithVariants,
            totalCount: productPrice?.listPrice,
            currency: true,
            storeId: data?.storeId,
            position: data?.index,
            click_from: data?.clickFrom,
            isRatingShow: true,
          });
        return addProductInWishList(wishlistState?.wishlistCollectionId, productId).then(
          (productId) => {
            if (productId) {
              if (wishlistState?.wishlist) {
                return [...wishlistState?.wishlist, productId];
              }
            }
            return [wishlistState?.wishlist];
          }
        );
      }
    } else if (wishlistState?.wishlistCollectionId && wishlistState?.error) {
      return createWishlistCollectionAndUpdate({
        wishlistCollectionId: wishlistState?.wishlistCollectionId,
        productId: productId,
      }).then((productId) => {
        if (productId && !data?.isAnonymous) {
          if (wishlistState?.wishlist) {
            return [...wishlistState?.wishlist, productId];
          }
        }
        return [wishlistState?.wishlist];
      });
    }
    return;
  }
);

export const isProductInWishlist = (wishlist: string[], productId: string) => {
  if (productId && wishlist && Array.isArray(wishlist)) {
    return wishlist.includes(productId);
  }
  return false;
};

const WishlistSlice = createSlice({
  name: 'Wishlist',
  initialState,
  reducers: {
    resetWishlistData: () => {
      return {
        loading: false,
      };
    },
    setWishlistCollectionId: (state, action) => {
      state.wishlistCollectionId = action.payload;
      state.wishlistCollectionIdError = undefined;
      state.wishlistCollectionIdLoading = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getWishlist.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getWishlist.fulfilled, (state, action) => {
      state.wishlist = action.payload;
      state.loading = false;
      state.error = undefined;
    });
    builder.addCase(getWishlist.rejected, (state, action) => {
      state.wishlist = [];
      state.error = getSerializableObject(action.error);
      state.loading = false;
    });
    builder.addCase(updateProductInWishlist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProductInWishlist.fulfilled, (state, action) => {
      state.wishlist = action.payload;
      state.loading = false;
    });
    builder.addCase(updateProductInWishlist.rejected, (state, action) => {
      state.error = getSerializableObject(action.error);
      state.loading = false;
    });
    builder.addCase(createWishlistCollection.pending, (state) => {
      state.wishlistCollectionIdLoading = true;
      state.wishlistCollectionIdError = undefined;
    });
    builder.addCase(createWishlistCollection.fulfilled, (state, action) => {
      state.wishlistCollectionIdError = action?.payload?.ID;
      state.wishlistCollectionIdLoading = false;
    });
    builder.addCase(createWishlistCollection.rejected, (state, action) => {
      state.wishlistCollectionIdError = getSerializableObject(action.error);
      state.wishlistCollectionIdLoading = false;
    });
  },
});

export const { setWishlistCollectionId, resetWishlistData } = WishlistSlice.actions;

export default WishlistSlice.reducer;
