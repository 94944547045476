/* eslint-disable @typescript-eslint/no-explicit-any */
//global
import React, { useContext, useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
//local
import { ComponentProps } from 'lib/component-props';

import { PSP } from 'models/PetSuppliesPlus.Model';
import useOcCart from 'src/hooks/useOcCart';
import { LineItem, OrderPromotion } from 'ordercloud-javascript-sdk';
import { NextImage, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import LinkHelper from 'components/helpers/LinkHelper';
import clsx from 'clsx';
import ComponentContext from 'lib/context/ComponentContext';
import IconHelper from 'components/helpers/IconHelper';
import { removeLineItem, calculateOrder } from 'src/redux/ocCurrentOrder';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
} from 'components/helpers/Constants';
import useDictionary from 'src/hooks/useDictionary';
import { BuyerProductWithXp, LineItemWithXp } from 'src/redux/xp';
import { analyseAuthForLoginCheckout } from 'src/helpers/LoginCheckout';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { checkoutPreflightChecks } from 'components/Checkout/helper/preflight';
import Link from 'next/link';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import router from 'next/router';
import { currentPath } from '../helpers/Constants';
import Loader from 'components/Loader/Loader';
import { useLineItemPriceForUI } from 'src/hooks/useLineItemPriceForUI';
import { currencyUsdFormatter } from 'lib/utils/formatters';

import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';

export type MiniCartProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.MiniCart.Fields.MiniCart;

const MiniCartClass = tv({
  slots: {
    miniCartWrapper: ['minicart-wrapper', 'md:min-h-[396px]'],
    miniCartTitle: [
      'minicart-title',
      'text-heading-desk-xLarge-bold',
      'font-heading-desk-xLarge-bold',
      'text-color-text-dark',
      'leading-heading-desk-xLarge-bold',
      'my-[12px]',
    ],
    miniCartItemsInCart: [
      'text-body-large-regular',
      'font-body-large-regular',
      'leading-body-large-regular',
      'text-color-text-dark',
      'my-[12px]',
    ],
    merchandiseTotal: [
      'merchandise-total',
      'font-body-large-bold',
      'text-body-large-bold',
      'leading-body-large-bold',
      'text-color-text-dark',
      'mb-desk-margin-tight-bottom',
      'my-[12px]',
    ],
    miniCartLineItemDescription: [
      'font-body-large-regular',
      'text-body-large-regular',
      'leading-body-large-regular',
      'text-color-text-dark',
      'mb-desk-margin-base-bottom',
    ],
    miniCartItemImage: ['min-w-[69px]', 'min-h-[88px]', 'max-h-[88px]', 'object-contain'],
    buttonStyles: [
      'text-heading-desk-large-bold',
      'font-heading-desk-large-bold',
      'leading-heading-desk-large-bold',
      'p-4',
      'w-full',
      'hover:no-underline',
      'flex',
      'justify-center',
      'items-center',
      'text-center',
      'duration-300',
      'rounded',
      'mb-desk-margin-base-bottom',
      'my-[12px]',
    ],
    buttonPrimaryStyle: [
      'bg-color-brand-primary-1-base',
      'hover:bg-color-brand-primary-1-dark',
      'border-color-brand-primary-1-base',
      'text-white',
    ],
    miniCartLineItemsWrapper: [
      'minicart-lineitems-wrapper',
      'h-[200px]',
      'overflow-y-scroll',
      'mb-[12px]',
    ],
    miniCartLineItem: [
      'flex',
      'flex-row',
      'gap-desk-margin-tight-right',
      'mb-[12px]',
      'pb-[12px]',
      'border-b-2',
      'border-color-border-light',
      'last:border-0',
    ],
    miniCartLoaderWrapper: ['minicart-loader', 'flex', 'flex-row', 'items-start', 'my-[12px]'],
    miniCartLoaderText: [
      'text-heading-desk-large-bold',
      'font-heading-desk-large-bold',
      'text-color-text-dark',
      'leading-heading-desk-large-bold',
      'pl-[10px]',
    ],
    miniCartQuantityWrapper: [
      'quantity-wrapper',
      'qnt-box',
      'border',
      'border-1',
      'border-color-border-mid',
      'rounded-radius-base',
      'py-[17px]',
      'inline-block',
      'flex',
      'flex-row',
      'w-[40%]',
      'items-center',
    ],
    miniCartBottomWrapper: [
      'minicart-bottom-wrapper',
      'border-t-2',
      'border-color-border-light',
      'rounded-radius-button',
      'mt-margin-tight-right',
      'flex',
      'justify-center',
    ],
    miniCartBottomWrapperLink: [
      'pt-padding-micro-x',
      'w-fit group no-underline flex items-center hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base gap-mob-space-between-micro-horizontal lg:gap-desk-space-between-micro-horizontal text-color-text-brand-1',
      'pt-desk-padding-micro-x',
      'font-body-large-bold',
      'leading-body-large-bold',
      'text-body-large-bold',
    ],

    viewMoreIcon: [
      '[&_svg_*]:fill-color-text-brand-1 group-hover:[&_svg_*]:fill-color-brand-tertiary-3-base group-focus:[&_svg_*]:fill-color-brand-tertiary-3-base [&_svg]:w-4 [&_svg]:h-4',
    ],

    quantityBox: [
      'text-lg',
      'border-none',
      'flex',
      'flex-row',
      'border-[2px]',
      'pl-[10px]',
      'w-[20%]',
      'focus-visible:none',
    ],
    flexEnd: ['justify-end'],
    utlitiCloseIcon: ['top-4 right-4 w-6'],
    closeIcon: ['cursor-pointer [&>svg>*>*]:fill-color-scale-8-dark'],
    productQtyWrapper:
      'relative w-[130px] border-[1px] border-color-border-mid overflow-hidden rounded-[4px]',
    productQtyWrapperOuter: ['flex', 'flex-row', 'justify-between', 'mb-desk-margin-tight-bottom'],
    productQtyWrapperLeftInner: ['flex', 'items-center', 'gap-1'],
    productStrikedRate: ['line-through'],
    productListRate: [
      'font-heading-mob-large-bold',
      'text-heading-mob-large-bold',
      'leading-heading-mob-large-bold',
      'text-color-text-dark',
    ],
    productFinalRate: [
      'font-heading-mob-large-bold',
      'text-heading-mob-large-bold',
      'leading-heading-mob-large-bold',
      'text-color-accent-red',
    ],
    productPriceWrapper: ['productPrices flex flex-col gap-[8px]'],
    prodcutQtyWrapperRightInner: ['product-qty-right-inner', 'pr-[25px]', 'items-center flex'],
    removeLineItems: [''],
    removeLineItemText: [
      'font-body-large-regular-regular_underline',
      'text-body-large-regular-regular_underline',
      'leading-body-large-regular-regular_underline',
      'text-color-text-dark',
      'underline',
      'cursor-pointer',
    ],
    errorMessage:
      'w-full text-body-large-bold font-primary font-body-large-bold leading-body-large-bold text-color-accent-red',
    lineItemLoader: ['absolute', 'left-[5px]'],
    productQtyWrapperQtyLabel: [
      'pl-[8px] text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
    ],
    productQtyInput:
      'w-10 focus:outline-none font-primary text-color-text-black placeholder:text-color-text-black h-[62px] text-heading-desk-large-regular font-heading-desk-large-regular leading-heading-desk-large-regular ',
    productQtyButtonWrapper:
      'absolute bottom-[1px] w-[31px] h-[60px] bg-color-background-light right-[0px] flex flex-col',
    productQtyButton: 'w-full h-1/2 text-[17px] font-[700]',
    availabilityWrapper:
      'flex font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
  },
  variants: {
    isDisabled: {
      true: {
        miniCartBottomWrapperLink: ['cursor-not-allowed'],
        buttonPrimaryStyle: [
          'cursor-not-allowed disabled:bg-color-brand-primary-1-disabled hover:disabled:bg-color-brand-primary-1-disabled',
        ],
      },
      false: {
        miniCartBottomWrapperLink: ['pointer'],
        buttonPrimaryStyle: ['pointer'],
      },
    },
  },
});
const MiniCart = (props: MiniCartProps): JSX.Element => {
  const {
    title,
    itemsText,
    merchandiseSubtotal,
    loaderText,
    checkoutLink,
    viewOrEditLink,
    deliveryText,
  } = props?.fields ?? {};

  const subTotal = useOcSelector((state: any) => state?.ocCurrentOrder?.order);
  const ocCurrentOrderObject = useOcSelector((state) => state?.ocCurrentOrder);

  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const {
    numberOfItemsInCart,
    getProductLineItems,
    numberOfItemsInCartSoftLogin,
    getProductLineItemsSoftLogin,
  } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  const [localProductLineItems, setLocalProductLineItems] = useState<LineItemWithXp[]>([]);
  const [isDFS, setIsDFS] = useState<boolean>();
  // const { lineItems } = useOcCurrentOrder();

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [isSoftLoginEnabled, setIsSoftLoginEnabled] = useState<boolean>(false);
  const [cartSubTotal, setCartSubTotal] = useState<number>(0);
  const cartPricing = useCartPriceForUI(ocCurrentOrderObject);
  const [newSubTotalForRender, setNewSubTotalForRender] = useState<number>(0);

  useEffect(() => {
    const newSubTotal = isSoftLoginEnabled ? cartSubTotal : cartPricing?.subTotal;

    setNewSubTotalForRender(newSubTotal);
  }, [isSoftLoginEnabled, cartPricing?.subTotal, cartSubTotal]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Call preflight check asynchronously if it returns a Promise
      const callPreflightCheckAsync = async () => {
        await callPreflightCheck();
      };
      callPreflightCheckAsync();

      const isSoftLoginEnabled = sessionStorage.getItem('isSoftLoginEnabled');
      const isSoftLoginEnabledBool = isSoftLoginEnabled === 'true';

      setIsSoftLoginEnabled(isSoftLoginEnabledBool);

      if (isSoftLoginEnabledBool) {
        const softLoginDependentData = JSON.parse(
          sessionStorage.getItem('softlogin_dependencies') || '{}'
        );
        const localItems = sessionStorage.getItem('cartLineItems');

        if (localItems) {
          const getProductLineItemsSoftLoginAsync = async () => {
            const res = await getProductLineItemsSoftLogin();
            setLocalProductLineItems(res);
            setCartSubTotal(softLoginDependentData?.subtotal ?? cartPricing?.subTotal);
          };
          getProductLineItemsSoftLoginAsync();
        }
      } else {
        setCartSubTotal(cartPricing?.subTotal);
      }
    }
  }, [componentContextData?.openMiniCart, componentContextData?.showMinicartLoader]);

  const {
    miniCartWrapper,
    miniCartTitle,
    merchandiseTotal,
    miniCartItemsInCart,
    buttonStyles,
    buttonPrimaryStyle,
    miniCartLineItemsWrapper,
    miniCartLoaderWrapper,
    miniCartLoaderText,
    miniCartBottomWrapper,
    miniCartBottomWrapperLink,
    viewMoreIcon,
  } = MiniCartClass({});

  const [miniCartLoader, setminiCartLoader] = useState(false);
  const [passesPreflightCheck, setPassesPreflightCheck] = useState(true);

  useEffect(() => {
    setcomponentContextData({ ...componentContextData, showDeliveryThreashold: false });
  }, []);

  const callPreflightCheck = async () => {
    const cartItemsToPass = isSoftLoginEnabled
      ? await numberOfItemsInCartSoftLogin()
      : await numberOfItemsInCart();

    try {
      const preflightResponse = await checkoutPreflightChecks(
        cartItemsToPass,
        newSubTotalForRender,
        productlineitems
      );
      setPassesPreflightCheck(preflightResponse);
    } catch (e) {
      console.error('Error during preflight check:', e);
    }
  };

  useEffect(() => {
    if (newSubTotalForRender > 0) {
      callPreflightCheck();
    }
  }, [newSubTotalForRender]);

  useEffect(() => {
    componentContextData.showMinicartLoader == true
      ? setminiCartLoader(true)
      : setminiCartLoader(false);
    callPreflightCheck();
  }, [componentContextData.showMinicartLoader]);

  const handleShowWarningPopup = () => {
    if (passesPreflightCheck) {
      // if (!componentContextData.showDeliveryThreashold) {
      setcomponentContextData({ ...componentContextData, showDeliveryThreashold: true });
    }
    // }
  };

  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const handleUserCheck = (redirectLink: string) => {
    if (passesPreflightCheck) {
      const response = analyseAuthForLoginCheckout(isAnonymous, redirectLink);
      if (response?.redirectURL) {
        setcomponentContextData({
          ...componentContextData,
          openMiniCart: false,
          showMinicartLoader: false,
        });
        router.push(response?.redirectURL);
      }
    }
  };
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || cart?.xp?.Fulfillment;
    setIsDFS(newMethod === FulfillmentType.DFS);
  }, [cart?.xp?.Fulfillment, componentContextData?.isDFSMethodChanged]);

  // Renders the Item count based on varios conditions including SoftLogin:
  const RenderItemCount: React.FC = () => {
    const cartCount = isSoftLoginEnabled
      ? numberOfItemsInCartSoftLogin() !== undefined
        ? numberOfItemsInCartSoftLogin()
        : '0'
      : numberOfItemsInCart() !== undefined
      ? numberOfItemsInCart()
      : '0';
    return <>{cartCount} </>;
  };

  return (
    <div className={miniCartWrapper()}>
      <p className={miniCartTitle()}>{title?.value}</p>
      <Placeholder name="progress-bar" rendering={props?.rendering} />
      {miniCartLoader && (
        <div className={miniCartLoaderWrapper()}>
          <Loader />
          <p className={miniCartLoaderText()}>{loaderText?.value}</p>
        </div>
      )}
      {/* Renders cart count line Item */}
      <p className={miniCartItemsInCart()}>
        {!isSoftLoginEnabled && <RenderItemCount />}
        {isSoftLoginEnabled && <RenderItemCount />}
        {!isDFS ? itemsText?.value : deliveryText?.value}
      </p>
      <div>
        <Placeholder name="sxa-warning-delivery-threshold" rendering={props?.rendering} />
        <Placeholder name="switch-to-delivery" rendering={props?.rendering} />
      </div>
      {(productlineitems?.length > 0 || localProductLineItems?.length > 0) && (
        <>
          <p className={merchandiseTotal()}>
            {merchandiseSubtotal?.value}: {currencyUsdFormatter.format(newSubTotalForRender)}
          </p>

          <div className="checkoutCTAWrapper">
            {subTotal?.xp?.Fulfillment !== FulfillmentType.BOPIS &&
            myStoreData?.deliveryFee?.minimumAmount > newSubTotalForRender ? (
              <LinkHelper
                //field={checkoutLink as any} // This needs a fix
                field={{
                  value: {
                    title: checkoutLink?.value?.text,
                    text: checkoutLink?.value?.text,
                    href: '/',
                  },
                }}
                className={clsx(
                  buttonStyles(),
                  buttonPrimaryStyle({ isDisabled: !passesPreflightCheck })
                )}
                onClick={(e) => {
                  e.preventDefault();
                  handleShowWarningPopup();
                }}
              />
            ) : (
              <LinkHelper
                //field={checkoutLink as any} // This needs a fix
                field={{
                  value: {
                    title: checkoutLink?.value?.text,
                    text: checkoutLink?.value?.text,
                    href: '/',
                  },
                }}
                className={clsx(
                  buttonStyles(),
                  buttonPrimaryStyle({ isDisabled: !passesPreflightCheck })
                )}
                onClick={(e) => {
                  e.preventDefault();
                  handleUserCheck(checkoutLink?.value?.href ?? '');
                }}
              />
            )}
          </div>
          <div className={miniCartLineItemsWrapper()}>
            {/* Render from the OC SDK call */}
            {!isSoftLoginEnabled &&
              productlineitems?.map((line: LineItem, index) => {
                return (
                  <MiniCartLineItem
                    key={line.ID}
                    line={line}
                    fields={props.fields}
                    index={index}
                    isDFS={isDFS || false}
                    promotions={ocCurrentOrderObject?.orderPromotions ?? []}
                  />
                );
              })}
            {/* Render from the stored data from LocalStorage */}
            {isSoftLoginEnabled &&
              localProductLineItems?.map((line: LineItem, index) => {
                return (
                  <MiniCartLineItem
                    key={line.ID}
                    line={line}
                    fields={props.fields}
                    index={index}
                    isDFS={isDFS || false}
                    promotions={ocCurrentOrderObject?.orderPromotions ?? []}
                  />
                );
              })}
          </div>
          <div className={miniCartBottomWrapper()}>
            <LinkHelper
              // field={viewOrEditLink as any}
              field={{
                value: {
                  ...viewOrEditLink,
                  href: !passesPreflightCheck ? '#' : viewOrEditLink?.value?.href,
                },
              }}
              className={miniCartBottomWrapperLink({ isDisabled: !passesPreflightCheck })}
            >
              <>
                <span>{viewOrEditLink?.value?.text}</span>
                <IconHelper icon={'chevron-right'} className={viewMoreIcon()} />
              </>
            </LinkHelper>
          </div>
        </>
      )}
    </div>
  );
};

function MiniCartLineItem({
  line,
  fields,
  index,
  isDFS,
  promotions,
}: {
  line: LineItemWithXp;
  fields: MiniCartProps['fields'];
  index: number;
  isDFS: boolean;
  promotions?: OrderPromotion[];
}) {
  const {
    miniCartLineItemDescription,
    miniCartItemImage,
    miniCartLineItem,
    productQtyWrapper,
    productQtyWrapperOuter,
    productQtyWrapperLeftInner,
    productStrikedRate,
    productFinalRate,
    productPriceWrapper,
    prodcutQtyWrapperRightInner,
    productQtyWrapperQtyLabel,
    productQtyInput,
    productQtyButtonWrapper,
    productQtyButton,
    removeLineItems,
    removeLineItemText,
    lineItemLoader,
    errorMessage,
    productListRate,
  } = MiniCartClass({});
  const { currentPage, pageItem } = getGTMSessionStorage();
  const { quantity, remove } = fields ?? {};

  const { getDictionaryValue } = useDictionary();

  // const priceforMinicart = useProductPriceForUI(line?.ProductID);
  const priceforMinicart = useLineItemPriceForUI(line, promotions ?? []);
  const [loadingItems, setLoadingItems] = useState(new Set());
  const inventoryMapRef = useRef(new Map());
  const [quantityMap, setQuantityMap] = useState(new Map());
  const [errorMap, setErrorMap] = useState(new Map());
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);

  //const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous); Commenting out this for now because for price integration if member price is available than list price will always show as crossed off

  const dispatch = useOcDispatch();

  const { getProductAvailability, addToCart } = useOcCart();

  const [isSoftLoginEnabled, setIsSoftLoginEnabled] = useState<boolean>(false);

  const [qtyInputValue, setQtyInputValue] = useState(line?.Quantity || '');
  const delayRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hasSoftLoginToTrue = sessionStorage.getItem('isSoftLoginEnabled');
      hasSoftLoginToTrue == 'true' ? setIsSoftLoginEnabled(true) : setIsSoftLoginEnabled(false);
    }
  }, [priceforMinicart]);

  const removeLine = async (ID: any, productID: any) => {
    setLoadingItems((prevLoadingItems) => new Set([...prevLoadingItems, productID]));

    if (ID) {
      await dispatch(removeLineItem(ID)).then(() => {
        dispatch(calculateOrder());
      });
    }
    setLoadingItems((prevLoadingItems) => {
      const newLoadingItems = new Set(prevLoadingItems);
      newLoadingItems.delete(productID);
      return newLoadingItems;
    });
    /**
     * Filter Session Storage upon Removal of Item:
     */
    // 1. Filter out session storage obj, cartLineItems from the Redux storage -
    const storageLineItems = JSON.parse(sessionStorage.getItem('cartLineItems') as any);
    // 2. update it.
    storageLineItems.filter((x: any) => {
      if (x.ID !== ID) {
        return x;
      }
    });
    sessionStorage.setItem('cartLineItems', JSON.stringify(storageLineItems));
  };

  const debounceDelay = (callback: () => void, delay: number) => {
    if (delayRef.current) {
      clearTimeout(delayRef.current);
    }

    delayRef.current = setTimeout(() => {
      callback();
    }, delay);
  };

  const handleQtyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === '') {
      setQtyInputValue('1');
      const newErrorMap = new Map(errorMap);
      newErrorMap.set(line?.ProductID, false);
      setErrorMap(newErrorMap);
    }

    const isValid = /^\d*$/.test(value);
    const numberValue = Number(value);

    if (isValid && numberValue > 0) {
      setQtyInputValue(value);
      debounceDelay(() => {
        handleQuantity(line?.ProductID, value);
      }, 300);
    }
  };

  const handleQuantity = async (productID: any, quantity: any) => {
    setLoadingItems((prevLoadingItems) => new Set([...prevLoadingItems, productID]));

    if (!inventoryMapRef.current.has(productID)) {
      const productInventory = await getProductAvailability(productID, myStoreData?.storeId);
      inventoryMapRef.current.set(productID, productInventory);
    }
    const productInventory = inventoryMapRef.current.get(productID);
    const inventoryItemId = productInventory?.inventoryRecordId;
    const response = await addToCart({
      productId: productID,
      quantity: quantity,
      storeId: myStoreData?.storeId,
      inventoryItemId: inventoryItemId,
      isUpdateLineItem: true,
    });
    // TODO:
    if (response && typeof response === 'object' && 'error' in response) {
      const newErrorMap = new Map(errorMap);
      newErrorMap.set(productID, true);
      setErrorMap(newErrorMap);
    } else if (response === null && quantity !== 0) {
      const newErrorMap = new Map(errorMap);
      newErrorMap.set(productID, true);
      setErrorMap(newErrorMap);
    } else {
      const newErrorMap = new Map(errorMap);
      newErrorMap.set(productID, false);
      setErrorMap(newErrorMap);
      const newQuantityMap = new Map(quantityMap);

      newQuantityMap.set(productID, quantity);
      setQuantityMap(newQuantityMap);

      setQtyInputValue(quantity === 0 ? 1 : quantity);
    }
    setLoadingItems((prevLoadingItems) => {
      const newLoadingItems = new Set(prevLoadingItems);
      newLoadingItems.delete(productID);
      return newLoadingItems;
    });
  };

  const productUrlClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setcomponentContextData({
      ...componentContextData,
      openMiniCart: false,
      showMinicartLoader: false,
    });
    router.push(productURL);
  };
  const productURL = getProductDetailPath(line?.Product as BuyerProductWithXp);
  const imageData = getFirstProductImage(line?.Product?.xp?.Images);

  return (
    <>
      <div className={miniCartLineItem()}>
        <div>
          <Link onClick={productUrlClick} aria-label="product url" href={productURL}>
            <NextImage
              className={miniCartItemImage()}
              field={{
                value: {
                  src: imageData?.Url,
                },
              }}
              alt={imageData?.Name}
              width="69"
              height="88"
            />
          </Link>
        </div>
        <div>
          <Link aria-label="product url" onClick={productUrlClick} href={productURL}>
            <div className={miniCartLineItemDescription()}>{line?.Product?.Name}</div>
          </Link>
          {errorMap.get(line.ProductID) && (
            <p className={errorMessage()}>{getDictionaryValue('OutOfStockMessage')}</p>
          )}
          {line.Product?.xp?.WE == true && (
            <p className={errorMessage()}>{getDictionaryValue('minicartpriceexcludecheck')}</p>
          )}
          <div className={productQtyWrapperOuter()}>
            <div className={productQtyWrapper()}>
              <div className={productQtyWrapperLeftInner()}>
                <span className={productQtyWrapperQtyLabel()}>{quantity?.value}.</span>
                {loadingItems.has(line.ProductID) && <Loader className={lineItemLoader()} />}
                <>
                  <label htmlFor="qtyInput"></label>
                  <input
                    aria-label="qtyInput"
                    name="qtyInput"
                    className={productQtyInput()}
                    type="text"
                    inputMode="numeric"
                    value={qtyInputValue}
                    onChange={(e) => {
                      const quantity = e.target?.value;
                      const isError = errorMap.get(line.ProductID);

                      // Allow clearing the input by setting an empty string when cleared
                      if (isError) {
                        setQtyInputValue(quantity);
                      } else if (quantity === '') {
                        setQtyInputValue(''); // Keep it as a number, but display as empty
                      } else if (quantity > '0') {
                        setQtyInputValue(quantity);
                      } else if (qtyInputValue === 0) {
                        setQtyInputValue('1');
                      } else {
                        setQtyInputValue(Number(quantity)); // Convert to a number if not empty
                      }
                    }}
                    onBlur={handleQtyInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        // Reset to 1 if empty or 0 on Enter key press

                        if (qtyInputValue === 0 || qtyInputValue === '') {
                          setQtyInputValue('1');
                          handleQuantity(line?.ProductID, '1');
                        } else {
                          handleQuantity(line?.ProductID, qtyInputValue);
                        }
                      }
                    }}
                  />
                </>
              </div>

              <div className={productQtyButtonWrapper()}>
                <button
                  aria-label="add to cart"
                  className={productQtyButton()}
                  onClick={async (e) => {
                    e.preventDefault();
                    if (isSoftLoginEnabled) {
                      setcomponentContextData({
                        ...componentContextData,
                        openMiniCart: false,
                        showMinicartLoader: false,
                      });
                      router.push(currentPath?.isLogin);
                    } else {
                      const newQty = line?.Quantity && line?.Quantity + 1;
                      await handleQuantity(line?.ProductID, newQty);
                      sendProductsPromotion({
                        eventName: GTM_EVENT?.addToCart,
                        data: {
                          ...line?.Product,
                          BasePrice:
                            priceforMinicart?.lineItemMemberPrice ||
                            priceforMinicart?.lineItemListPrice,
                        } as ProductSearchResultModelWithVariants,
                        currentPage: currentPage,
                        pageItem: pageItem,
                        position: index ?? 0,
                        click_from: GTMLabels?.fromMiniCartPlusSign,
                        quantity: 1,
                        parentId: line?.Product?.ParentID,
                        listPrice: line?.UnitPrice,
                        isRatingShow: true,
                        fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                      });
                    }
                  }}
                >
                  +
                </button>
                <button
                  aria-label="remove from cart"
                  className={productQtyButton()}
                  onClick={async (e) => {
                    e.preventDefault();
                    if (isSoftLoginEnabled) {
                      setcomponentContextData({
                        ...componentContextData,
                        openMiniCart: false,
                        showMinicartLoader: false,
                      });
                      router.push(currentPath?.isLogin);
                    } else {
                      const newQty = line?.Quantity && line?.Quantity - 1;

                      await handleQuantity(line?.ProductID, newQty);
                      sendProductsPromotion({
                        eventName: GTM_EVENT?.removeFromCart,
                        data: {
                          ...line?.Product,
                          BasePrice:
                            priceforMinicart?.lineItemMemberPrice ||
                            priceforMinicart?.lineItemListPrice,
                        } as ProductSearchResultModelWithVariants,
                        currentPage: currentPage,
                        pageItem: pageItem,
                        position: index ?? 0,
                        click_from: GTMLabels?.fromMiniCartMinus,
                        quantity: 1,
                        isRatingShow: true,
                        parentId: line?.Product?.ParentID,
                        listPrice: line?.UnitPrice,
                        fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                      });
                    }
                  }}
                >
                  -
                </button>
              </div>
            </div>

            <div className={prodcutQtyWrapperRightInner()}>
              {priceforMinicart?.productPriceLoader ? (
                <div className={productPriceWrapper()}>
                  {/* List Price  */}

                  {priceforMinicart?.lineItemMemberPrice ? (
                    <>
                      <div className="list-price">
                        <span className={clsx(productListRate(), productStrikedRate())}>
                          {currencyUsdFormatter.format(priceforMinicart?.lineItemListPrice ?? 0)}
                        </span>
                      </div>
                      <div className="ppc-price">
                        <span className={clsx(productFinalRate())}>
                          {currencyUsdFormatter.format(priceforMinicart?.lineItemMemberPrice ?? 0)}
                        </span>
                      </div>
                    </>
                  ) : (
                    priceforMinicart?.lineItemListPrice && (
                      <div className="ppc-price">
                        <span className={clsx(productListRate())}>
                          {currencyUsdFormatter.format(priceforMinicart?.lineItemListPrice ?? 0)}
                        </span>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <div className={removeLineItems()}>
            <span
              className={removeLineItemText()}
              onClick={async (e) => {
                e.preventDefault();
                await removeLine(line?.ID, line?.ProductID);
                sendProductsPromotion({
                  eventName: GTM_EVENT?.removeFromCart,
                  data: {
                    ...line?.Product,
                    BasePrice:
                      priceforMinicart?.lineItemMemberPrice || priceforMinicart?.lineItemListPrice,
                  } as ProductSearchResultModelWithVariants,
                  currentPage: currentPage,
                  pageItem: pageItem,
                  position: index ?? 0,
                  click_from: GTMLabels?.fromMiniCartRemove,
                  quantity: line?.Quantity,
                  parentId: line?.Product?.ParentID,
                  listPrice: line?.UnitPrice,
                  fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                });
              }}
            >
              {remove?.value}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
export default MiniCart;
