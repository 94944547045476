//global
import React, { useEffect } from 'react';
import { RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  WarningUnsavedChangesProps,
  warningUnsavedChangesVariants,
} from 'components/WarningUnsavedChanges/WarningUnsavedChanges';
//local

//lib
import ModalWrapper from 'components/helpers/ModalWrapper';
import TextHelper from 'components/helpers/TextHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
//type
export type WarningDeliveryProfileUpdateProps = WarningUnsavedChangesProps;
//main component
const WarningDeliveryProfileUpdate: React.FC<WarningDeliveryProfileUpdateProps> = ({
  fields,
  params,
}) => {
  const { base, wrapper, title, btnWrapper, btnPrimary, btnSecondary } =
    warningUnsavedChangesVariants({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const { checkoutContextData, updateCheckoutContextData } = useCheckoutFormContext();

  useEffect(() => {
    updateCheckoutContextData({ setDeliverySubmitting: undefined });
  }, []);
  return (
    <ModalWrapper
      showModal={checkoutContextData?.warningDeliveryProfileUpdate}
      onCloseClick={() => {
        updateCheckoutContextData({ warningDeliveryProfileUpdate: false });
      }}
      additionalClassForPopUpModal={`${base({
        className: params?.Styles ?? '',
      })} !item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
      popupWidth="max-w-[995px]"
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick
      customPopup
    >
      <div className={wrapper()}>
        <RichText field={fields?.title} tag="p" className={title()} />
        {fields?.description && (
          <RichTextHelper field={fields?.description} tag="p" className="mt-8" />
        )}
        <div className={btnWrapper()}>
          {fields?.primaryLink && fields?.secondaryLink && (
            <>
              <button
                className={btnPrimary()}
                onClick={(e) => {
                  e.preventDefault();
                  checkoutContextData?.isDelivery &&
                    updateCheckoutContextData({
                      setDeliverySubmitting: true,
                      warningDeliveryProfileUpdate: false,
                    });
                }}
              >
                <TextHelper field={{ value: fields?.primaryLink?.value?.text || '' }} />
              </button>
              <button
                className={btnSecondary()}
                onClick={(e) => {
                  e.preventDefault();
                  checkoutContextData?.isDelivery &&
                    updateCheckoutContextData({
                      setDeliverySubmitting: false,
                      warningDeliveryProfileUpdate: false,
                    });
                }}
              >
                <TextHelper field={{ value: fields?.secondaryLink?.value?.text || '' }} />
              </button>
            </>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<WarningDeliveryProfileUpdateProps>(
  WarningDeliveryProfileUpdate
);
