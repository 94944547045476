//global
import React, { useContext, useEffect } from 'react';
import {
  Field as siteCoreField,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import { Formik, Field, Form } from 'formik';

//lib
import { ComponentProps } from 'lib/component-props';
import IconHelper from 'components/helpers/IconHelper';
import { apiRequest } from 'src/utils/apiWrapper';
import ComponentContext from 'lib/context/ComponentContext';
import { useOcSelector } from 'src/redux/ocStore';
import { loyaltyApi } from 'src/utils/nextApiConfig';
import { ErrorCodes } from 'components/helpers/Constants';
//type
const CustomFieldsVariant = tv(
  {
    slots: {
      customFieldWrapper: ['flex', 'items-start', 'flex-wrap', 'content-start'],
      customFields: [
        'w-[28px]',
        'h-[28px]',
        'outline-color-border-mid',
        'border-2',
        'appearance-none',
        'bg-color-background-white',
        'rounded-4',
        'peer',
      ],
      customLabels: [
        'flex',
        'w-fit',
        'cursor-pointer',
        'items-center',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'relative',
      ],
      customErrorStyle: ['text-system-red', 'w-full'],
      customCheckmarkIcon: [
        '[&>svg>*>*]:fill-color-background-white',
        'absolute',
        '[&>svg]:w-[28px]',
        '[&>svg]:h-[28px]',
        '[&>svg]:bg-color-brand-primary-1-base',
        'invisible',
        'peer-checked:visible',
      ],
    },
    variants: {
      device: {
        mobile: {
          customFields: [],
          customLabels: ['gap-mob-space-between-tight-horizontal'],
          customFieldWrapper: ['gap-mob-space-between-base-horizontal'],
        },
        desktop: {
          customFields: [],
          customLabels: ['gap-desk-space-between-tight-horizontal'],
          customFieldWrapper: ['gap-desk-space-between-base-horizontal'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
export type LoyaltyTermsOfUseProps = ComponentProps & {
  fields: {
    data?: {
      datasource?: {
        agreetext: siteCoreField<string>;
        buttontext: siteCoreField<string>;
        terms: siteCoreField<string>;
        title: siteCoreField<string>;
      };
    };
  };
};
//component variants
const loyaltyTermsOfUseVariants = tv(
  {
    slots: {
      base: 'flex flex-col',
      title: 'font-primary',
      buttonWrapper: 'flex',
      button:
        'flex justify-center items-center hover:no-underline font-heading-desk-medium-bold leading-heading-desk-medium-bold text-heading-desk-medium-bold text-color-text-white flex',
      agreeText:
        'text-body-large-bold font-body-large-bold font-primary leading-body-large-bold w-fit',
      description:
        '[&_a]:text-color-text-brand-1 [&_a]:underline hover:[&_a]:text-color-brand-tertiary-3-base focus:[&_a]:text-color-brand-tertiary-3-base overflow-y-auto font-primary border-[1px] border-color-border-dark text-body-large-regular font-body-large-regular leading-body-large-regular flex flex-col',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          base: 'gap-mob-padding-loose-bottom px-mob-global-grid-margin',
          title:
            'font-heading-mob-xLarge-bold text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
          description: 'max-h-[387px] px-mob-padding-micro-y py-mob-padding-micro-x',
          buttonWrapper: 'flex-col gap-mob-global-spacing-spacing-6',
          button:
            'mb-mob-padding-loose-bottom rounded-mob-global-radius-small px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y',
        },
        desktop: {
          base: 'gap-desk-padding-tight-bottom px-0',
          buttonWrapper: 'flex-row items-center gap-desk-global-spacing-spacing-10',
          title:
            'font-heading-desk-xLarge-bold text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
          description: 'max-h-[295px] px-desk-padding-micro-x py-desk-padding-micro-y ',

          button:
            'mb-0 w-fit rounded-desk-global-radius-small px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y',
        },
      },
      buttonDisabled: {
        true: {
          button: 'focus:outline-none bg-color-brand-primary-1-disabled cursor-not-allowed',
        },
        false: {
          button:
            'bg-color-brand-primary-1-base cursor-pointer hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const LoyaltyTermsOfUse: React.FC<LoyaltyTermsOfUseProps> = ({ fields }) => {
  const { base, title, description, buttonWrapper, agreeText, button } = loyaltyTermsOfUseVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const { customFields, customCheckmarkIcon, customLabels } = CustomFieldsVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);

  useEffect(() => {
    // to hide overlay form on this page as user would be athenticated.
    if (componentContextData.ToggleLoginOverlayForm) {
      setcomponentContextData({ ...componentContextData, ToggleLoginOverlayForm: false });
    }
  }, []);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;

  const onSubmitClick = async () => {
    try {
      const response = await apiRequest(loyaltyApi.acceptLoyaltyProgramAgreementEndpoint(), {
        method: 'POST',
      });
      if (response === ErrorCodes?.Success) {
        // will see rewards page content after concent is given
        window.location.reload();
      }
    } catch (e) {
      console.error('Error', e);
    }
  };

  if (userDetails && Object.keys(userDetails).length > 0 && isLoyaltyAccepted === false) {
    return (
      <div id={'loyaltyTermsOfUse'} className={base()}>
        <Text tag="p" field={fields?.data?.datasource?.title} className={title()} />
        <RichText tag="p" field={fields?.data?.datasource?.terms} className={description()} />
        <div>
          <Formik
            initialValues={{
              agreeInput: false,
            }}
            onSubmit={(value) => {
              if (value?.agreeInput) {
                onSubmitClick();
              }
            }}
          >
            {({ values }) => (
              <Form className={buttonWrapper()}>
                <label className={customLabels()}>
                  <Field
                    type="checkbox"
                    className={customFields()}
                    name="agreeInput"
                    aria-label="agreeInput"
                  />
                  <Text
                    tag="p"
                    className={agreeText()}
                    field={fields?.data?.datasource?.agreetext}
                  />
                  <IconHelper className={customCheckmarkIcon()} icon={'icon-checkmark'} />
                </label>

                <button
                  aria-label="submit"
                  className={button({
                    buttonDisabled: !values?.agreeInput,
                  })}
                  type="submit"
                >
                  {fields?.data?.datasource?.buttontext?.value}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
  return <></>;
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<LoyaltyTermsOfUseProps>(LoyaltyTermsOfUse);
