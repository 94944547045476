//global
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import RichTextHelper from 'components/helpers/RichTextHelper';
import TextHelper from 'components/helpers/TextHelper';
import IconHelper from 'components/helpers/IconHelper';
import { useRouter } from 'next/router';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import { GTM_EVENT } from 'components/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
//type
export type ShortenedContentBlockProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.ShortenedContentBlock.Fields.ShortenedContentBlock;
export type PersonlizedShortenedContentBlockProps = {
  title?: Field<string>;
  description?: Field<string>;
  detailedDescription?: Field<string>;
  readMoreText?: Field<string>;
  setShowDescription?: Dispatch<SetStateAction<boolean>>;
  showDescription?: boolean;
};
//component variants
const shortenedContentBlockVariants = tv(
  {
    slots: {
      base: ['shortenedContentBlock', 'container', 'mx-auto'],
      title: [
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
      linkWrapper: ['w-fit', 'flex', 'items-center'],
      link: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-color-text-brand-1',
      ],
      icon: [
        '[&>svg>*]:fill-color-text-brand-1',
        '[&>svg]:h-4',
        '[&>svg]:w-4',
        '[&>*>*]:fill-color-text-brand-1',
        'ml-mob-space-between-micro-horizontal',
      ],
      description: [
        'mt-3',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        '[&>h2]:mb-3',
        '[&>ul]:block',
        '[&>ul]:pl-10',
        '[&>ul]:pt-5',
        '[&>ul]:my-[10px]',
        '[&>p]:mb-[10px]',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          base: ['py-mob-padding-tight-y'],
          linkWrapper: ['mt-mob-margin-base-bottom'],
          description: ['mt-mob-margin-tight-bottom', '[&>h2]:mb-mob-margin-tight-bottom'],
        },
        desktop: {
          base: ['py-desk-padding-tight-y'],
          linkWrapper: [
            'cursor-pointer',
            'mt-desk-margin-base-bottom',
            '[&>*>*>*]:hover:fill-color-brand-tertiary-3-base',
            '[&>*]:hover:text-color-brand-tertiary-3-base',
            '[&>*]:hover:underline',
          ],
          description: ['mt-desk-margin-tight-bottom', '[&>h2]:mb-desk-margin-tight-bottom'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

/**
 *
 * Main component
 */
const ShortenedContentBlock: React.FC<ShortenedContentBlockProps> = ({ fields, params }) => {
  const { base, title, description, linkWrapper, link, icon } = shortenedContentBlockVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const [showDescription, setShowDescription] = useState(false);
  const route = useRouter();
  const path = route?.asPath?.split('/')[1];
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(fields?.componentName?.value),
      creative_slot: Number(fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(`${fields?.promotionDateFROM?.value} - ${fields?.promotionDateTO?.value}`)
      ),
      promotion_cta: notAvailableIfNullOrEmpty(fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(fields?.promotionURL?.value?.href),
    },
  ];

  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);
  //hide the long description when route change
  useEffect(() => {
    setShowDescription(false);
  }, [route?.asPath]);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      data-component={'components/ShortenedContentBlock/ShortenedContentBlock'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
      className={base({ className: params?.Styles ?? '' })}
    >
      {path?.toLocaleLowerCase() !== 'categories' &&
        fields?.title?.value &&
        fields?.title?.value?.length > 0 && (
          <RichTextHelper field={fields?.title} className={title()} />
        )}
      {!showDescription && <RichTextHelper field={fields?.description} className={description()} />}
      {showDescription && (
        <RichTextHelper field={fields?.detailedDescription} className={description()} />
      )}
      {!showDescription && (
        <button
          aria-label="read more"
          tabIndex={0}
          className={linkWrapper()}
          onClick={() => {
            setShowDescription(true);
            myStoreId &&
              fields?.componentName?.value !== '' &&
              trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
          }}
        >
          <TextHelper tag="a" field={fields?.readMoreText} className={link()} />
          {fields?.readMoreText?.value && fields?.readMoreText?.value?.length > 0 && (
            <IconHelper icon="chevron-right" className={icon()} />
          )}
        </button>
      )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<ShortenedContentBlockProps>(
  withPersonalization(ShortenedContentBlock)
);
