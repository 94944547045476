import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';
import { SubscriptionWithXp } from 'src/redux/xp';

const envEndPoint = process.env.NEXT_PUBLIC_API_ENDPOINT;
const publicURL = publicURLTrailingSlash(); // Add this for XMC Pages.
const storePageAPI = { yelpAPIEndpoint: '/GetYelpReviews?yelpbusinessid=' };
const bazarVoiceReviewAPI = {
  bazarVoiceReviewAPIEndpoint: envEndPoint + '/GetBVToken',
};
const acceptLoyaltyProgramAgreementAPI = {
  acceptLoyaltyProgramAgreementEndpoint: envEndPoint + '/acceptloyaltyprogramagreement',
};
const lockedUserRequestAPI = {
  lockedUserRequestEndpoint: '/lockeduserrequest',
  unlockUserEndpoint: '/unlockuser',
};
const authenticationAPI = {
  userExistAPI: envEndPoint + '/userexist',
  registerUserAPI: envEndPoint + '/userregister',
  forgotPasswordAPI: envEndPoint + '/forgotpassword',
  resetPasswordAPI: envEndPoint + '/resetpassword',
  activateUserAPI: envEndPoint + '/useractivate',
  ValidateResetPasswordLinkAPI: envEndPoint + '/validateresetpasswordlink',
};
const productAPI = {
  getProductAPIServerSide: envEndPoint + '/pdp-data?parentid={parentid}&upc={upc}',
  getProductAPI: `api/product/get-product-details?parentid={parentid}&productSku={productSku}&siteName={siteName}`,
  getProductAssociationCheck:
    envEndPoint + '/store-sells-product?productid={productId}&storeid={myStoreId}',
};
const storeAPI = {
  getStoreAPI: `${publicURL}api/store/get-store-details?storeId={storeId}&siteName={siteName}`,
};
const promotionalSignupAPI = {
  promotionalSignup: envEndPoint + '/promotionsignup',
};
const petsAPI = {
  allPets: envEndPoint + '/all-pets',
  getGender: envEndPoint + '/genders',
  getSpecies: envEndPoint + '/species',
  getBreed: envEndPoint + '/breeds',
  addPet: envEndPoint + '/add-pet',
  getPet: envEndPoint + '/get-pet',
  updatePet: envEndPoint + '/update-pet',
  deletePets: envEndPoint + '/delete-pet',
  addPetImage: envEndPoint + '/add-pet-image',
};
const groomingAPI = {
  addNewBreedAndAssignToPet: envEndPoint + '/add-new-breed-and-assign-to-pet',
  createUpdatePet: envEndPoint + '/create-update-pet',
  groomingServices: envEndPoint + '/grooming-services',
  nextAvailableTimeslots: envEndPoint + '/next-available-timeslots',
  timeslotsByDateRangeAndGroomer: envEndPoint + '/timeslots-by-date-range-and-groomer',
  getMoreAvailableTimeSlots: envEndPoint + '/get-more-available-time-slots',
  scheduleAppointment: envEndPoint + '/schedule-appointment',
  availableGroomers: envEndPoint + '/available-groomers',
  appointments: envEndPoint + '/appointments',
  cancelAppointment: envEndPoint + '/cancel-appointment',
};
const auth = {
  authToken: 'oauth/token',
};
const couponImage = {
  imageUrl: process.env.NEXT_PUBLIC_PSP_PROMOTION_COUPON_IMAGE,
  getCouponImage: '/GePromotionCoupon',
};
const orderPromotion = {
  addPromotion: envEndPoint + '/addPromotion',
};

const autoship = {
  skipAutoship: envEndPoint + '/skip-subscription',
  cancelAutoship: envEndPoint + '/cancel-subscription',
};

export interface SubscriptionResponse {
  Subscription: SubscriptionWithXp;
  Success: boolean;
  EmailSuccess: boolean;
}

const api = {
  envEndPoint,
  storePageAPI,
  acceptLoyaltyProgramAgreementAPI,
  lockedUserRequestAPI,
  authenticationAPI,
  promotionalSignupAPI,
  productAPI,
  storeAPI,
  bazarVoiceReviewAPI,
  petsAPI,
  groomingAPI,
  auth,
  couponImage,
  orderPromotion,
  autoship,
};
export default api;
