//global
import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import { Form as FormikForm, Formik, FormikValues, FormikHelpers, FormikProps } from 'formik';
import { Me, Tokens } from 'ordercloud-javascript-sdk';
//lib
import ComponentContext from 'lib/context/ComponentContext';
//local
import {
  MyAccountDetailsProps,
  allValuesAreEmpty,
  myAccountDetailsVariants,
} from 'components/MyAccountDetails/MyAccountDetails';
import useDictionary from 'src/hooks/useDictionary';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { listAddresses, saveAddress } from 'src/redux/ocAddressBook';
import TextHelper from 'components/helpers/TextHelper';
import { transformData } from 'src/utils/formUtils';
import TextField from 'components/helpers/Form/TextField';
import { Country, addressType, errorMessages } from 'components/helpers/Constants';
import DropdownField from 'components/helpers/Form/DropdownField';
import AutoCompleteWrapper from 'components/helpers/Form/AutoCompleteWrapper';
import _ from 'lodash';
import PoweredByGoogleImage from 'src/helpers/google/PoweredByGoogleImage/PoweredByGoogleImage';
import Loader from 'components/Loader/Loader';
import { isValidAddress } from 'src/utils/handlePlaceChanged';
//type
export type MyAccountDeliveryAddressProps = MyAccountDetailsProps;
interface FormValuesTypes {
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  State: string;
  Zip: string;
}

//main component
const MyAccountDeliveryAddress: React.FC<MyAccountDeliveryAddressProps> = ({ fields, params }) => {
  const {
    base,
    headigWrapper,
    textLabel,
    smallLabel,
    content,
    editingContent,
    inlineFields,
    informationContainer,
    fieldWrapper,
    userInfo,
    linkText,
    submitBtn,
    cancelBtn,
    loaderWrapper,
    textLabelBold,
    textLabelMedium,
    googleImageWrapper,
  } = myAccountDetailsVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  // transforming the form field data
  const transFormFields = transformData(fields.data?.data?.formFields);
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const dispatch = useOcDispatch();
  const [address, settAddress] = useState<FormValuesTypes>();
  const [addressId, setAddressId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [fieldValues, setFieldValues] = useState<FormValuesTypes>();
  const [addressError, setAddressError] = useState(false);
  const formRef = useRef<FormikHelpers<FormValuesTypes>>(null);
  const activeSection = fields?.data?.data?.title?.value;
  //fetching initial values.
  const setInitialValues = async () => {
    setLoading(true);
    const listAdd = await dispatch(
      listAddresses({ filters: { AddressName: addressType?.delivery } })
    );
    // await Me.DeleteAddress('NQZflCcUpkS7M-c1Xf9wRg');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredAddress = (listAdd as any)?.payload?.Items.filter((item: any) => {
      return item?.AddressName === addressType?.delivery;
    });

    settAddress({
      AddressLine1: filteredAddress && filteredAddress[0]?.Street1,
      AddressLine2: filteredAddress && filteredAddress[0]?.Street2,
      City: filteredAddress && filteredAddress[0]?.City,
      State: filteredAddress && filteredAddress[0]?.State,
      Zip: filteredAddress && filteredAddress[0]?.Zip,
    });
    setAddressId(filteredAddress && filteredAddress[0]?.ID);
    setLoading(false);
    filteredAddress && filteredAddress?.length > 0
      ? setcomponentContextData({
          ...componentContextData,
          collapse: null,
          isMyAccountDeliveryAddress: true,
        })
      : setcomponentContextData({
          ...componentContextData,
          collapse: null,
          isMyAccountDeliveryAddress: false,
        });
  };
  //load initial data
  useEffect(() => {
    setInitialValues();
  }, []);
  //initial values for the form
  const initialValues: FormValuesTypes = {
    AddressLine1: address?.AddressLine1 || '',
    AddressLine2: address?.AddressLine2 || '',
    City: address?.City || '',
    State: address?.State || '',
    Zip: address?.Zip || '',
  };
  /**chacking the initial values if all values are empty or not */
  const isValuesEmpty = allValuesAreEmpty(initialValues);
  //maping the auto complete address fields
  const autoCompleteFieldMap = {
    addressLine1: 'AddressLine1',
    addressLine2: 'AddressLine2',
    city: 'City',
    stateCode: 'State',
    zipcode: 'Zip',
  };

  //setting field values
  const setValues = (values: FormValuesTypes) => {
    setFieldValues({
      ...values,
    });
  };

  //handling collapse action
  const handleCollapse = (title: string) => {
    componentContextData?.isEqualFields
      ? setcomponentContextData({ ...componentContextData, collapse: title, isEqualFields: true })
      : setcomponentContextData({ ...componentContextData, collapse: title, isEqualFields: false });
  };

  //handling accordian
  useEffect(() => {
    const item = document?.querySelectorAll('.myAccountDetails');
    item.forEach((item) => {
      const content = item.querySelector<HTMLElement>('.deliveryContent');
      const form = item.querySelector<HTMLElement>('.deliveryEditContent');
      if (item.classList.contains('open')) {
        if (componentContextData?.collapse === fields?.data?.data?.title?.value) {
          form ? (form.style.maxHeight = '1000px') : null;
          content ? (content.style.maxHeight = '0px') : null;
        } else {
          form && (form.style.maxHeight = `0px`);
          content ? (content.style.maxHeight = `${200}px`) : null;
        }
      }
    });
  }, [componentContextData?.collapse]);

  //compering the form values
  useEffect(() => {
    setcomponentContextData({
      ...componentContextData,
      isEqualFields: true,
    });
    const isEqual = _.isEqual(initialValues, fieldValues);
    isEqual
      ? setcomponentContextData({ ...componentContextData, isEqualFields: true })
      : setcomponentContextData({ ...componentContextData, isEqualFields: false });
  }, [fieldValues]);
  //handling submit action
  const submitFormData = async (values: FormikValues) => {
    try {
      setSaveLoading(true);
      const deliveryAddress = {
        FirstName: userDetails?.FirstName,
        LastName: userDetails?.LastName,
        City: values?.City,
        Country: Country,
        Shipping: true,
        State: values?.State,
        Street1: values?.AddressLine1,
        Street2: values?.AddressLine2,
        Zip: values?.Zip,
        AddressName: addressType?.delivery,
      };
      const accessToken = Tokens?.GetAccessToken();
      const keyword =
        deliveryAddress?.Street1 +
        ', ' +
        deliveryAddress?.City +
        ', ' +
        deliveryAddress?.State +
        ', ' +
        deliveryAddress?.Zip +
        ', ' +
        deliveryAddress?.Country;

      const isValid = await isValidAddress(keyword);
      if (isValid) {
        if (isValuesEmpty) {
          const res = await Me.CreateAddress(deliveryAddress, {
            accessToken,
          });
          if (res) {
            setSaveLoading(false);
            setcomponentContextData({
              ...componentContextData,
              isEqualFields: true,
              collapse: null,
            });
          }
          setInitialValues();
          setAddressError(false);
        } else {
          const address = {
            ID: addressId,
            ...deliveryAddress,
          };
          const res = await dispatch(saveAddress(address));
          if (res) {
            setSaveLoading(false);
            setcomponentContextData({
              ...componentContextData,
              isEqualFields: true,
              collapse: null,
            });
          }
          setInitialValues();
          setAddressError(false);
        }
      } else {
        setAddressError(true);
      }
      setSaveLoading(false);
    } catch (error) {
      console.error('@@deliveryAddress: ', error);
    }
  };
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;

  return (
    <>
      <div className={base({ className: params?.Style + ' open' ?? '' })}>
        <div className={content({ className: 'deliveryContent' })}>
          <div className={headigWrapper()}>
            <TextHelper tag="p" field={fields?.data?.data?.title} className={textLabelMedium()} />
            {/**TODO: replace with dynamic text */}
            <button
              aria-label="add/edit"
              aria-controls="button"
              className={linkText()}
              onClick={() => {
                formRef?.current?.resetForm();
                handleCollapse(activeSection);
                !componentContextData?.isEqualFields &&
                  setcomponentContextData({
                    ...componentContextData,
                    isWarningPopUp: true,
                    passwordChangeSuccess: false,
                    currentSection: activeSection,
                  });
              }}
            >
              {isValuesEmpty ? getDictionaryValue('AddCTA') : getDictionaryValue('EditCTA')}
            </button>
          </div>
          {!isValuesEmpty && address && !loading && !saveLoading && (
            <div className={userInfo()}>
              <p className={textLabel()}>{address?.AddressLine1 ?? ''}&nbsp;</p>
              <p className={textLabel()}>{address?.AddressLine2 ?? ''}</p>
              <p className={textLabel()}>
                {address?.City},{address?.State + ' ' + address?.Zip ?? ''}
              </p>
            </div>
          )}
        </div>
        <div
          className={editingContent({
            className: `deliveryEditContent ${
              componentContextData?.collapse === fields?.data?.data?.title?.value ? '' : '!py-0'
            }`,
          })}
        >
          <TextHelper tag="p" field={fields?.data?.data?.title} className={textLabelBold()} />
          <TextHelper
            tag="p"
            field={fields?.data?.data?.shortDescription}
            className={smallLabel()}
          />
          {!loading && (
            <Formik
              initialValues={initialValues}
              innerRef={formRef as Ref<FormikProps<FormValuesTypes>>}
              onSubmit={(values: FormValuesTypes) => {
                submitFormData(values);
              }}
            >
              {({ resetForm }) => (
                <FormikForm className={informationContainer()}>
                  {addressError && (
                    <p className="text-system-red text-body-large-bold font-body-large-bold leading-body-large-bold">
                      {getDictionaryValue('AddressErrorMessage') || errorMessages?.invalidAddress}
                    </p>
                  )}
                  <div className={inlineFields()}>
                    <AutoCompleteWrapper fieldMap={autoCompleteFieldMap} setFieldValue={setValues}>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                      {({}: any) => {
                        return (
                          <>
                            <TextField {...transFormFields.AddressLine1} />
                          </>
                        );
                      }}
                    </AutoCompleteWrapper>
                    {transFormFields?.AddressLine2 && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields?.AddressLine2} setFieldValue={setValues} />
                      </div>
                    )}
                  </div>
                  <div className={inlineFields()}>
                    {transFormFields?.City && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields?.City} setFieldValue={setValues} />
                      </div>
                    )}
                    {transFormFields.State && (
                      <div className={fieldWrapper()}>
                        <DropdownField
                          {...transFormFields.State}
                          options={fields?.data?.city?.regions?.targetItems}
                          firstOptionData={getDictionaryValue('SelectStateOptionLabel') as string}
                        />
                      </div>
                    )}
                  </div>
                  <div className={inlineFields()}>
                    {transFormFields?.Zip && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields?.Zip} setFieldValue={setValues} />
                      </div>
                    )}
                    <div className={fieldWrapper({ className: 'hidden lg:block' })}></div>
                  </div>
                  <div className={inlineFields()}>
                    {saveLoading ? (
                      <div className={loaderWrapper()}>
                        <Loader />
                        {getDictionaryValue('Saving')}
                      </div>
                    ) : (
                      <>
                        {fields.data?.data?.submitButtonText?.value && (
                          <button aria-label="submit" className={submitBtn()} type="submit">
                            <TextHelper field={fields.data?.data?.submitButtonText} />
                          </button>
                        )}
                        {fields.data?.data?.cancelButtonText?.value && (
                          <button
                            aria-label="reset"
                            type="reset"
                            className={cancelBtn()}
                            onClick={() => {
                              resetForm();
                              setFieldValues(initialValues);
                              setcomponentContextData({
                                ...componentContextData,
                                collapse: null,
                                isEqualFields: true,
                              });
                              setAddressError(false);
                            }}
                          >
                            <TextHelper field={fields.data?.data?.cancelButtonText} />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </FormikForm>
              )}
            </Formik>
          )}
          <div className={googleImageWrapper()}>
            <PoweredByGoogleImage />
          </div>
        </div>
      </div>
      {/* <MyAccountDetails
        fields={fieldsData?.fields}
        params={{ FieldsName: '' }}
        rendering={{ componentName: 'MyAccountDetails' }}
      /> */}
    </>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
// export default withDatasourceCheck()<MyAccountDeliveryAddressProps>(MyAccountDeliveryAddress);
export default MyAccountDeliveryAddress;
